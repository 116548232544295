import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import i18n from './i18n'

import styled from 'styled-components'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import StyledTheme, { THEME } from './StyledTheme'
import Router from './Router'
import Toast from './containers/Toast'
import GlobalLoading from './containers/GlobalLoading'
import { getCompanyInfo } from './utils/common'
//import { selectMaster } from './redux/selectors'
//import { getCompanyInfoById } from './redux/actions/master'

const Div = styled.div`
  width: 100%;
  min-height: 100vh;
  height: 1px;
  background: var(--Base-White);
  color: var(--Base-Font-Color);
`

const App = () => {
  const dispatch = useDispatch()
  //const { companyInfo } = useSelector(selectMaster)
  const [theme, setTheme] = useState('A81E23')
  const [isI18nInitialized, setI18nInitialized] = useState(false)
  /*
  const fetchCompanyInfo = useCallback(
    (id) => {
      dispatch(getCompanyInfoById(id))
    },
    [dispatch]
  )
  */
  /*
  if (!localStorage.getItem('lang')) {
    localStorage.setItem('lang', 'th') //--- เพิ่ม set lang
  }
  */
  //---- FIX TH
  localStorage.setItem('lang', 'th')

  useEffect(() => {
    i18n.on('initialized', () => setI18nInitialized(true))
    /*
    const company = getCompanyInfo()
    if (company) setTheme(company?.theme)
    else fetchCompanyInfo(1)
    */

    return () => i18n.off('initialized')
  }, [])
/*
  useEffect(() => {
    const company = getCompanyInfo()
    if (company === undefined && Object.keys(companyInfo).length > 0 && companyInfo?.theme) setTheme(companyInfo?.theme)
  }, [companyInfo])
*/

  return (
    <Div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <StyledTheme name={THEME.light} themeValue={theme} />
        <Router />
        <Toast />
        <GlobalLoading />
      </LocalizationProvider>
    </Div>
  )
}

export default App
