import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate,useParams } from 'react-router-dom'
import XLSX from "xlsx";

import {  getAdminUser,DateDisplayDDMMBBB,DateDisplayDDMMBBBHHmmss,DisplayTableActivityDescription } from '../../utils/common'
import { ApiGetVWUserPointHistory,ApiCMSMemberPointActive,ApiUpdateUserActivityPoint } from '../../redux/actions/events'

import Button from '../../components/form/button/Button'
import ButtonSecondary from '../../components/form/button/ButtonSecondary'
import ButtonOutline from '../../components/form/button/ButtonOutline'
import TableV2 from '../../components/common/TableV2'
import SearchText from '../../components/form/SearchText'
import Dropdown from '../../components/form/Dropdown'
import Date from '../../components/form/Date'
import Field from '../../components/form/Field'
import DialogSuccess from '../../components/dialog/DialogSuccess'
import DialogFail from '../../components/dialog/DialogFail'
import DialogConfirmV2 from '../../components/dialog/DialogConfirmV2'
import ViewPointLog from '../../pages/activity_member/ViewPointLog'
import Modal from '../../components/common/Modal'
import SidePanel from '../../components/common/SidePanel'
import ViewEventSelf from '../../pages/activity_member/ViewEventSelf'
import ViewEvent from '../../pages/activity_member/ViewEvent'
import ViewElearning from '../../pages/activity_member/ViewElearning'

import iconPlus from '../../assets/images/icon-plus.svg'
import { FaBullseye } from 'react-icons/fa'
import { GrStatusGoodSmall } from 'react-icons/gr'
import warningIcon from '../../assets/images/warning-icon.png'
import successIcon from '../../assets/images/success-icon.png'
import failIcon from '../../assets/images/fail-icon.png'

const Div = styled.div`
.dd_sort{
  min-width: 250px;
}
  .box_header{
    display: flex;
    justify-content: space-between;
  }
  .content-title {
    font-size: 36px;
    font-weight: 600;
    color: var(--Base-Font-Title-Color);
  }
  .content-title-text {
    color: var(--Base-Font-Title-SUB-Color);
    font-size: 18px;
  }
  .css_button_add{
    display: inline-block;
    align-self: flex-end;
  }
  .border_header {
    border-bottom: 1px solid rgb(234, 236, 240);
  }
  .css_magin_top_16 {
    margin-top: 16px;
  }
  .status-badge {
    width: fit-content;
    padding: 2px 6px;
    text-align: center;
    font-weight: 500;
    border-radius: 16px;
    color: #344054;
    background: #f5f5f4;
    border: 1px #e7e5e4 solid;

    &.expire {
      color:#B42318;
      background: #FEF3F2;
      border: 1px #FECDCA solid;
    }
    &.active {
      color:#067647;
      background: #ECFDF3;
      border: 1px #ABEFC6 solid;
    }

    .status {
      font-size: 0.6rem;
      margin-right: 0.2rem;
      color: #344054;
      

      &.expire {
        color:#B42318;
      }
      &.active {
        color:#067647;
      }

    }
  }

  .table-wrapper {
    margin-top: 24px;
    border: 1px solid #eaecf0;
    box-shadow: var(--Shadow-xs);
    border-radius: 8px;
    background-color: #ffffff;

    .table-header {
      display: flex;
      justify-content: space-between;
      padding: 16px 14px;
      border-radius: 8px 8px 0 0;
      background: var(--Table-Bg-Header);

      .group {
        display: flex;
        align-items: center;
        gap: 10px;
      }

      .table-title {
        font-size: 18px;
        font-weight: 600;
        color: var(--Gray-900);
      }

      .table-total {
        margin-left: 0px;
        font-size: 12px;
        font-weight: 500;
        padding: 2px 8px;
        color: var(--BADGE-Table-Font);
        background: var(--BADGE-Table-Bg);
        border-radius: 16px;
        border: 1px var(--BADGE-Table-Border) solid;
      }
    }

    .create-bt {
      text-decoration: none;
    }
  }
  .css_magin_top_10 {
    margin-top: 10px;
  }
  .text-bold {
    font-weight: bold;
  }
  
  .link {
    color: var(--Td-Font-Color-Link);
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .button_cancle_data {
    color: #A91F23;
    background-color: #FFFFFF;
    border: 1px solid #FFFFFF;
  }
`

const DivBoxPoint = styled.div`
display: flex;
gap: 10px;
.box_point{
  width: 200px;
  padding: 24px;
  border-radius: 12px;
  border: 1px solid #EAECF0;
  background: #FFFFF;
}
.header_text_box{
  color: #475467;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.css_magin_top_8{
  margin-top: 8px;
}
.text_point{
  color: #101828;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
}
`

const DivFilter = styled.div`
min-height: 68px;
background-color: var(--Gray-50);
border-radius: 8px;
margin-bottom: 21px;
padding: 10px;
.content-fillter {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    .search-wrapper {
        margin-right: 12px;
    }

    .filter-list {
        flex-basis: 100%;
        margin-top: 24px;
    }

    .group_filter {
        display: flex;
        align-items: center;
    }
}
.css_filter_name {
    min-width: 380px;
    margin-right: 10px;
}
.mr-1-rem {
    margin-right: 1rem;
}
.css_button_filter{
    display: inline-block;
    align-self: flex-end;
}
.dd_filter{
    min-width: 380px;
}
.dd_status_name{
    min-width: 150px;
}
.dd_filterStatusExam{
  min-width: 115px;
}

`



const ActivityMemberInfo = () => {
  const dispatch = useDispatch()
  const user = getAdminUser()
  const navigate = useNavigate()
  const { user_no } = useParams()

  const [DataInfo, setDataInfo] = useState({
    member_no : '',
    full_name : '',
    total_point_active : 0,
    num_activity : 0,
  })

  useEffect(() => {
    const sent_data_to_api = {
      user_no : user_no,
    };
    dispatch(ApiCMSMemberPointActive(sent_data_to_api)).then(({ type,data_info }) => {
      if(type.endsWith('_SUCCESS')){
        if(data_info.code == 200){
          if(data_info.data_info.user_info){
            setDataInfo({
              member_no : data_info.data_info.user_info[0].user_no,
              full_name : data_info.data_info.user_info[0].full_name,
              total_point_active : data_info.data_info.total_point_active,
              num_activity : data_info.data_info.num_activity,
            })
          }
        }
      }
    })

}, [dispatch])

  const [RefActivityOption, setRefActivityOption] = useState([
    {
        text: 'E-learning',
        value: 'E-learning',
    },
    {
        text: 'กิจกรรมแม่ข่าย',
        value: 'กิจกรรมแม่ข่าย',
    },
    {
        text: 'Self-declaration',
        value: 'Self-declaration',
    },
  ]);
  const [status_nameOption, setstatus_name] = useState([
    {
        text: 'ปกติ',
        value: 'ปกติ',
    },
    {
      text: 'หมดอายุ',
      value: 'หมดอายุ',
    },
    {
      text: 'ยกเลิก',
      value: 'ยกเลิก',
    },
    {
      text: 'รอเข้าร่วมกิจกรรม',
      value: 'รอเข้าร่วมกิจกรรม',
    },
    {
        text: 'ยังไม่ได้รับคะแนน',
        value: 'ยังไม่ได้รับคะแนน',
    },
    
  ])

  const [SortOption, setSortOption] = useState([
    {
      text: 'วันที่เข้าร่วมกิจกรรม น้อย -> มาก',
      value: 'effective_date_asc',
      active_status : false,
    },
    {
      text: 'วันที่เข้าร่วมกิจกรรม มาก -> น้อย',
      value: 'effective_date_desc',
    },
    {
      text: 'วันที่ทำรายการ น้อย -> มาก',
      value: 'createdAt_asc',
      active_status : false,
    },
    {
      text: 'วันที่ทำรายการ มาก -> น้อย',
      value: 'createdAt_desc',
    },
    {
      text: 'วันที่คะแนนหมดอายุ น้อย -> มาก',
      value: 'expire_date_asc',
      active_status : false,
    },
    {
      text: 'วันที่คะแนนหมดอายุ มาก -> น้อย',
      value: 'expire_date_desc',
    },
  ])
  const [Filter, setFilter] = useState({
    activity_name : null,
    ref_activity : '',
    effective_date : null,
    status_name : '',
    sort_data : null,
  })

  const limit_data = 10;
  const [total, settotal] = useState(0)
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [TableCellData, setTableCellData] = useState([])
  const TableColumnData = [
    {
      Header: 'ลำดับ',
      accessor: 'no',
      disableSortBy: false,
    },
    {
        Header: 'รหัสกิจกรรม',
        accessor: 'event_code',
        disableSortBy: false,
    },
    {
        Header: 'ชื่อกิจกรรม',
        accessor: 'activity_name',
        disableSortBy: false,
    },
    {
        Header: 'แขนงความรู้',
        accessor: 'engineering_field_name',
        disableSortBy: false,
    },
    {
        Header: 'ประเภทกิจกรรม',
        accessor: 'ref_activity',
        disableSortBy: false,
    },
    {
        Header: 'วันที่เข้าร่วมกิจกรรม',
        accessor: 'effective_date',
        disableSortBy: false,
    },
    {
        Header: 'วันที่คะแนนหมดอายุ',
        accessor: 'expire_date',
        disableSortBy: false,
    },
    {
        Header: 'คะแนน CPD',
        accessor: 'user_point',
        disableSortBy: false,
    },
    {
        Header: 'สถานะ',
        accessor: 'status_name',
        disableSortBy: false,
    },
    {
      Header: 'วันที่ทำรายการ',
      accessor: 'createdAt',
      disableSortBy: false,
  },
    {
      accessor: 'activity_point_log',
      disableSortBy: false,
    },
    {
        accessor: 'cancle_data',
        disableSortBy: false,
    },
  ]

  const fetchData = useCallback(
    async (filter) => {

      dispatch(ApiGetVWUserPointHistory(filter)).then(({ type,data_info }) => {
        if(type.endsWith('_SUCCESS')){
          if (data_info.total > 0) {
            const totalPage = Math.ceil(data_info.total / limit_data)
            settotal(data_info.total)
            setTotalPages(totalPage)
            const ThisSkip = data_info.skip == 0 ? 1 : (data_info.skip / limit_data) + 1;

            const newTableCellData = data_info.data.map((v, index) => ({
                no: limit_data * ThisSkip - (9 - index),
                event_code: v.event_code ? (
                  <div className="text-bold link" onClick={() => OpenViewModal(v)}>
                    {v.event_code }
                  </div>
                ) : '-',
                activity_name: v.activity_name ? DisplayTableActivityDescription(v.activity_name) : '-',
                engineering_field_name: v.engineering_field_name ? v.engineering_field_name : '-',
                ref_activity: (
                    v.ref_activity ? 
                    <div
                      className="status-badge"
                      style={{
                        color: v.ref_activity_status_badge_color?.color,
                        borderColor: v.ref_activity_status_badge_color?.border,
                        backgroundColor: v.ref_activity_status_badge_color?.background,
                      }}
                    >
                      {v.ref_activity}
                    </div>
                    : 
                    <div >-</div>
                ),
                effective_date: v.effective_date_full ? DateDisplayDDMMBBBHHmmss(v.effective_date_full) : '-',
                expire_date: v.expire_date ? DateDisplayDDMMBBB(v.expire_date) : '-',
                user_point: v.user_point ? v.user_point : '-',
                
                status_name: (
                    v.status_name ? 
                    <div
                      className="status-badge"
                      style={{
                        borderColor: v.status_badge_color?.border,
                        backgroundColor: v.status_badge_color?.background,
                      }}
                    >
                      <GrStatusGoodSmall 
                        className="status"
                        style={{
                          color: v.status_badge_color?.color,
                        }}
                      />
                      {v.status_name}
                    </div>
                    : 
                    <div >-</div>
                ),
                createdAt: v.createdAt ? DateDisplayDDMMBBBHHmmss(v.createdAt) : '-',
                activity_point_log: (
                  <ButtonSecondary onClick={() => OpenPointLog(v)}>
                    ดูประวัติ
                  </ButtonSecondary>
                ),
                cancle_data: v.status_id != 2 ? (
                    <ButtonSecondary className="button_cancle_data"  onClick={() => CancleData(v)}>
                      ยกเลิกกิจกรรม
                    </ButtonSecondary>
                ) : (
                  <div></div>
                ),
                  

            }))
            setTableCellData(newTableCellData)
          }else{
            settotal(0);
            setPage(0);
            setTotalPages(0);
            setTableCellData([]);
          }
         
        }
      })

    },
    [dispatch]
  )

  useEffect(() => {
    const filter = {
      skip: 0,
      limit: limit_data,
      user_no : user_no,
    }
    fetchData(filter) //-- ปิด รอ API
  }, [fetchData, limit_data])


  const onChangePaginationAttendeeAttend = async (value) => {
    const filter = {
      skip: (value.page  - 1) * limit_data, 
      limit: limit_data,
      user_no : user_no,
      activity_name : Filter.activity_name,
      ref_activity : Filter.ref_activity,
      effective_date : Filter.effective_date,
      status_name : Filter.status_name,
    }
    fetchData(filter)
    setPage(value.page)
  }
  const onClearfilterName = () => {
    setFilter({ ...Filter, activity_name: '' })
  }

  const onSearch = () => {
    const filter = {
      skip: 0,
      limit: limit_data,
      user_no : user_no,
      activity_name : Filter.activity_name,
      ref_activity : Filter.ref_activity,
      effective_date : Filter.effective_date,
      status_name : Filter.status_name,
      sort_data : Filter.sort_data,
    }
    fetchData(filter)
    setPage(1)
  }
  const onSortSearch = (v) => {
    setFilter({ ...Filter, sort_data: v })
    const filter = {
      skip: 0,
      limit: limit_data,
      user_no : user_no,
      activity_name : Filter.activity_name,
      ref_activity : Filter.ref_activity,
      effective_date : Filter.effective_date,
      status_name : Filter.status_name,
      sort_data : v
    }
    fetchData(filter)
    setPage(1)
  }
  const onClearSearch = () => {
    setFilter({
        activity_name : '',
        ref_activity : '',
        effective_date : null,
        status_name : '',
        sort_data : null,
    })
    const filter = {
      skip: 0,
      limit: limit_data,
      user_no : user_no,
    }
    fetchData(filter)
    setPage(1)
  }

  const [ThisId, setThisId] = useState(null)
  const [WidthSidePanel, setWidthSidePanel] = useState(400);
  const [ModalViewElearning, setModalViewElearning] = useState(false);
  const [ModalViewEvent, setModalViewEvent] = useState(false);
  const [ModalViewEventSelf, setModalViewEventSelf] = useState(false);

  const OpenViewModal = (Obj) => {
    setThisId(Obj.id);
    if(Obj.ref_activity == 'E-learning'){
      //setThisId(Obj.ref_activity_id);
      setModalViewElearning(true)
    }else if(Obj.ref_activity == 'กิจกรรมแม่ข่าย'){
      //setThisId(Obj.id);
      setModalViewEvent(true)
    }else if(Obj.ref_activity == 'Self-declaration'){
      //setThisId(Obj.id);
      setModalViewEventSelf(true)
    }
  }
  const [UserActivityPointId, setUserActivityPointId] = useState(null)
  const initMessageModal = {
    headline: '',
    message: '',
  }
  const [ModalConfirmDialogDel, setModalConfirmDialogDel] = useState(false)
  const [ConfirmMessageModal, setConfirmMessageModal] = useState(initMessageModal)

  const [successModal, setSuccessModal] = useState(false)
  const [failModal, setFailModal] = useState(false)
  const [successMessageModal, setSuccessMessageModal] = useState(initMessageModal)
  const [failMessageModal, setFailMessageModal] = useState(initMessageModal)

  const CancleData = (data) => {
    setUserActivityPointId(data.id)
    setConfirmMessageModal({
        headline: 'กรุณายืนยันการยกเลิกกิจกรรม',
        message: '',
    })
    setModalConfirmDialogDel(true);
  }

  const [ObjPoint, setObjPoint] = useState({});
  const [ModalPointLog, setModalPointLog] = useState(false);
  const OpenPointLog = (Obj) => {
    //alert(JSON.stringify(Obj))
    setObjPoint(Obj);
    setModalPointLog(true);
  }

  const ApiProcess = () => {

    const request = {
      status_id: 2 ,
    }
    dispatch(ApiUpdateUserActivityPoint(UserActivityPointId, request)).then(({ type,data_info }) => { 
      if(type.endsWith('_SUCCESS')){
        setSuccessMessageModal({
          headline: 'ยกเลิกกิจกรรมสำเร็จ',
          message: '',
        })
        setSuccessModal(true)
      }else{
        setFailMessageModal({
          headline: 'internal server error',
          message: '',
        })
        setFailModal(true)
      }
      
    })
  }

  const loadExcel = () => {
    //alert('loadExcel')
    const filter = {
      skip: 0,
      limit: 10000,
      user_no : user_no,
    }
    dispatch(ApiGetVWUserPointHistory(filter)).then(({ type,data_info }) => {
      if(type.endsWith('_SUCCESS')){
        if (data_info.total > 0) {
          const resultToxlsx = data_info.data.map((v,index) => {
              return {
                  "ลำดับ" : index + 1,
                  "รหัสกิจกรรม" : v.event_code ? v.event_code : '-',
                  "ชื่อกิจกรรม" : v.activity_name ? v.activity_name : '-',
                  "แขนงความรู้" : v.engineering_field_name ? v.engineering_field_name : '-',
                  "ประเภทกิจกรรม" : v.ref_activity ? v.ref_activity : '-',
                  "วันที่เข้าร่วมกิจกรรม" : v.effective_date_full ? DateDisplayDDMMBBBHHmmss(v.effective_date_full) : '-',
                  "วันที่คะแนนหมดอายุ" : v.expire_date ? DateDisplayDDMMBBB(v.expire_date) : '-',
                  "คะแนน CPD" : v.user_point ? v.user_point : '-',
                  "สถานะ" : v.status_name ? v.status_name : '-',
                  "วันที่ทำรายการ" : v.createdAt ? DateDisplayDDMMBBBHHmmss(v.createdAt) : '-',
              };
          });
          const dataWS = XLSX.utils.json_to_sheet(resultToxlsx);
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, dataWS);
          XLSX.writeFile(wb, "ประวัติคะแนนของสมาชิก.xlsx");
        }
      }
    })
  }


  return (
    <Div>
        <div className="box_header">
            <div>
                <div className="content-title">
                    ประวัติคะแนนของสมาชิก
                </div>
                <div className="content-title-text">
                    {DataInfo.member_no + ' ' + DataInfo.full_name}
                </div>
            </div>
        </div>
        <div className="css_magin_top_16"></div>
        <div className="border_header"></div>
        <div className="css_magin_top_16"></div>
        <div>
          <DivBoxPoint>
              <div className="box_point">               
                <div className="header_text_box">
                    จำนวนคะแนนที่นำไปใช้ได้
                </div>
                <div className="css_magin_top_8"></div>
                <div className="text_point">
                  {DataInfo.total_point_active}
                </div>
              </div>
              <div className="box_point">
                <div className="header_text_box">
                    จำนวนกิจกรรมที่ Active
                </div>
                <div className="css_magin_top_8"></div>
                <div className="text_point">
                  {DataInfo.num_activity}
                </div>
              </div>
          </DivBoxPoint>
        </div>
        <div className="css_magin_top_16"></div>
        <DivFilter>
          <div className="content-fillter">
            <div className="group_filter">
              <div className="css_filter_name">
                <Field className="field" label="ค้นหา">
                  <SearchText
                    placeholder="Search ชื่อกิจกรรม"
                    value={Filter.activity_name}
                    onChange={(e) => setFilter({ ...Filter, activity_name: e.target.value })}
                    onClear={onClearfilterName}
                  />
                </Field>
              </div>
              <div className="mr-1-rem">
                <Field className="field" label="ประเภทที่มาของคะแนน">
                  <Dropdown
                      id={`dd_filter`}
                      className="dd_filter"
                      value={Filter.ref_activity}
                      optionList={RefActivityOption}
                      onChange={(v) => setFilter({ ...Filter, ref_activity: v })}
                      placeHolder={'เลือก'}
                  />
                </Field>
              </div>
              <div className="mr-1-rem">
                <Field className="field" label="สถานะ">
                  <Dropdown
                      id={`dd_status_name`}
                      className="dd_status_name"
                      value={Filter.status_name}
                      optionList={status_nameOption}
                      onChange={(v) => setFilter({ ...Filter, status_name: v })}
                      placeHolder={'เลือก'}
                  />
                </Field>
              </div>
              <div className="mr-1-rem">
                <Field className="field" label="วันที่เข้าร่วมกิจกรรม">
                    <Date
                        className={'input-calendar'}
                        value={Filter.effective_date}
                        onChange={(v) => setFilter({ ...Filter, effective_date: v })}
                    />
                </Field>
              </div>
              <div className="css_button_filter mr-1-rem">
                  <Button onClick={onSearch} >
                    ค้นหา
                  </Button>
              </div>
              <div className="css_button_filter mr-1-rem">
                <ButtonSecondary onClick={onClearSearch} >
                  ล้างการค้นหา
                </ButtonSecondary>
              </div>
            </div>
          </div>
        </DivFilter>
        <div className="css_magin_top_16"></div>
        <div className="table-wrapper">
          <div className="table-header">
            <div className="group">
              <div className="table-title">รายการกิจกรรม</div>
              <div className="table-total">
                {total} รายการ
              </div>
            </div>
            <div className="group">
              <div>
                <ButtonOutline onClick={() => loadExcel()}>Download</ButtonOutline>
              </div>
              <div>
                <Dropdown
                  id={`dd_sort`}
                  className="dd_sort"
                  value={Filter.sort_data}
                  optionList={SortOption}
                  onChange={(v) => onSortSearch(v)}
                  placeHolder={'เรียงตาม'}
                  ValueplaceHolderIsNull={true}
                />
              </div>
            </div>
          </div>
          <div className="css_magin_top_10">
            <TableV2
              columns={TableColumnData}
              data={TableCellData}
              onStateChange={onChangePaginationAttendeeAttend}
              pageCount={totalPages}
              page={page}
              loading={false}
            />
          </div>
        </div>
        <DialogConfirmV2
          open={Boolean(ModalConfirmDialogDel)}
          onClose={() => setModalConfirmDialogDel(false)}
          onNo={() => setModalConfirmDialogDel(false)}
          onSubmit={() => {
            setModalConfirmDialogDel(false)
            ApiProcess()
          }}
          icon={warningIcon}
          title={ConfirmMessageModal.headline}
          nameItem={ConfirmMessageModal.message}
          textYes='ยืนยัน'
          textNo='ยกเลิก'
        />

      {/* Dialog */}
      <DialogSuccess
        open={Boolean(successModal)}
        onClose={() => setSuccessModal(false)}
        onSubmit={() => {
          setSuccessModal(false)
          onClearSearch()
        }}
        icon={successIcon}
        title={successMessageModal.headline}
        description={successMessageModal.message}
        textYes='ตกลง'
      />
      <DialogFail
        open={Boolean(failModal)}
        onClose={() => setFailModal(false)}
        onSubmit={() => {
          setFailModal(false)
          onClearSearch()
        }}
        icon={failIcon}
        title={failMessageModal.headline}
        description={failMessageModal.message}
        textYes='ตกลง'
      />

      <Modal open={ModalPointLog} disableBackdropClick>
          <ViewPointLog
            onClose={() => setModalPointLog(false)} 
            onSubmit={() => {
              setModalPointLog(false)
            }}
            ObjPoint={ObjPoint}
          />
      </Modal>

        <SidePanel isOpen={ModalViewElearning} setIsOpen={setModalViewElearning} width={WidthSidePanel}>
            <ViewElearning
              width={WidthSidePanel} 
              onClose={() => setModalViewElearning(false)} 
              onSubmit={() => {
                setModalViewElearning(false)
              }}
              ThisId={ThisId}
            />
        </SidePanel>

        <SidePanel isOpen={ModalViewEvent} setIsOpen={setModalViewEvent} width={WidthSidePanel}>
            <ViewEvent
              width={WidthSidePanel} 
              onClose={() => setModalViewEvent(false)} 
              onSubmit={() => {
                setModalViewEvent(false)
              }}
              ThisId={ThisId}
            />
        </SidePanel>

        <SidePanel isOpen={ModalViewEventSelf} setIsOpen={setModalViewEventSelf} width={WidthSidePanel}>
            <ViewEventSelf
              width={WidthSidePanel} 
              onClose={() => setModalViewEventSelf(false)} 
              onSubmit={() => {
                setModalViewEventSelf(false)
              }}
              ThisId={ThisId}
            />
        </SidePanel>
    </Div>
  )
}

export default ActivityMemberInfo
