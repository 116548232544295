import { postAPI } from './api'

export const validateEmail = (email) =>
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  )

export const validateInteger = (v) => /^[1-9]\d*$/.test(v)
export const validateNumber = (v) => /^[1-9]\d*\.?\d*$/.test(v)

export const checkUnique = async (uid, field, value) => {
  try {
    const { isAvailable } = await postAPI({
      url: '/content-manager/uid/check-availability',
      data: {
        contentTypeUID: uid,
        field,
        value,
      },
    })
    return isAvailable
  } catch (error) {
    return error.response?.data?.error?.message || false
  }
}


export const validateIDCARD = (IDCARD) => {
  if(IDCARD.length == 13){
    return true;
  }else{
    return false;
  }
}

