import { getAPI, postAPI,patchAPI } from '../../utils/api'
import { 
    GET_TITLE,
    GET_province,
    GET_district,
    GET_sub_district,
    GET_OrgType,
    GET_EngineeringCourse,
    GET_elearning_prototype_img,
    GET_TYPE_ACTIVITY_CPD,
    GET_BRANCH_ACTIVITY_CPD,
    GET_CRITERIA_CPD,
    GET_BRANCH_ENGINEER,
    GET_MEMBER_COE,
    GET_vw_cpd_activity_criteria_and_conditions,
    GET_vw_activity_type,
    GET_vw_service_condition_cpd,
    GET_SERVICE_TYPE,
    GET_MASTER,
    POST_MASTER,
    UPDATE_MASTER,
    GET_STATUS,
    GET_knowledge,
    GET_field_study,
    GET_vw_field_study,
    POST_field_study
} from '../actionTypes'
import { showLoading, hideLoading } from './loading'

export const getTitle = () => async (dispatch) => {
    try {
      
      dispatch(showLoading())
      dispatch({ type: GET_TITLE.REQUEST })

      const headers = {
        'Content-Type': 'application/json',
        Authorization: null,
      }
  
      const data_result = await getAPI({
        url: '/api/mas/getTitle',
        headers: headers,
      })

      return { type: 'GET_TITLE_SUCCESS', data_info: data_result }

    } catch (err) {
      return { type: 'GET_TITLE_ERROR' }

    } finally {
      dispatch(hideLoading())
    }
}

export const ApiGetProvince = (filter = { limit: 200, skip: 0 }) => async (dispatch) => {
  try {
    
    dispatch(showLoading())
    dispatch({ type: GET_province.REQUEST })

    const headers = {
      Authorization: null,
      'Content-Type': 'application/json',
    }
    let params = {
      '$limit': filter.limit,
      '$skip': filter.skip,
    }

    const data_result = await getAPI({
      url: '/api/mas/get_province?$sort[id]=1',
      headers: headers,
      params,
    })

    return { type: 'ApiGetProvince_SUCCESS', data_info: data_result }

  } catch (err) {
    return { type: 'ApiGetProvince_ERROR' }

  } finally {
    dispatch(hideLoading())
  }
}

export const ApiGetDistrict = (filter = { limit: 200, skip: 0 }) => async (dispatch) => {
  try {
    
    dispatch(showLoading())
    dispatch({ type: GET_district.REQUEST })

    const headers = {
      Authorization: null,
      'Content-Type': 'application/json',
    }
    let params = {
      '$limit': filter.limit,
      '$skip': filter.skip,
      'province_id': filter.province_id,
    }

    const data_result = await getAPI({
      url: '/api/mas/get_district?$sort[id]=1',
      headers: headers,
      params,
    })

    return { type: 'ApiGetDistrict_SUCCESS', data_info: data_result }

  } catch (err) {
    return { type: 'ApiGetDistrict_ERROR' }

  } finally {
    dispatch(hideLoading())
  }
}

export const ApiGetSub_district = (filter = { limit: 200, skip: 0 }) => async (dispatch) => {
  try {
    
    dispatch(showLoading())
    dispatch({ type: GET_sub_district.REQUEST })

    const headers = {
      Authorization: null,
      'Content-Type': 'application/json',
    }
    let params = {
      '$limit': filter.limit,
      '$skip': filter.skip,
      'district_id': filter.district_id,
    }

    const data_result = await getAPI({
      url: '/api/mas/get_subdistrict?$sort[id]=1',
      headers: headers,
      params,
    })

    return { type: 'ApiGetSub_district_SUCCESS', data_info: data_result }

  } catch (err) {
    return { type: 'ApiGetSub_district_ERROR' }

  } finally {
    dispatch(hideLoading())
  }
}

export const ApiGetOrgType = (filter = { limit: 200, skip: 0 }) => async (dispatch) => {
  try {
    
    dispatch(showLoading())
    dispatch({ type: GET_OrgType.REQUEST })

    const headers = {
      Authorization: null,
      'Content-Type': 'application/json',
    }
    let params = {
      '$limit': filter.limit,
      '$skip': filter.skip,
    }

    const data_result = await getAPI({
      url: '/api/mas/getOrgType?$sort[id]=1',
      headers: headers,
      params,
    })

    return { type: 'ApiGetOrgType_SUCCESS', data_info: data_result }

  } catch (err) {
    return { type: 'ApiGetOrgType_ERROR' }

  } finally {
    dispatch(hideLoading())
  }
}

export const ApiGetEngineeringCourse = (filter = { limit: 200, skip: 0 }) => async (dispatch) => {
  try {
    
    dispatch(showLoading())
    dispatch({ type: GET_EngineeringCourse.REQUEST })

    const headers = {
      Authorization: null,
      'Content-Type': 'application/json',
    }
    let params = {
      '$limit': filter.limit,
      '$skip': filter.skip,
    }

    const data_result = await getAPI({
      url: '/api/mas/engineering_course?$sort[id]=1',
      headers: headers,
      params,
    })

    return { type: 'ApiGetEngineeringCourse_SUCCESS', data_info: data_result }

  } catch (err) {
    return { type: 'ApiGetEngineeringCourse_ERROR' }

  } finally {
    dispatch(hideLoading())
  }
}

export const ApiGetElearningPrototypeImg = (filter = { limit: 200, skip: 0 }) => async (dispatch) => {
  try {
    
    dispatch(showLoading())
    dispatch({ type: GET_elearning_prototype_img.REQUEST })

    const headers = {
      Authorization: null,
      'Content-Type': 'application/json',
    }
    let params = {
      '$limit': filter.limit,
      '$skip': filter.skip,
    }

    const data_result = await getAPI({
      url: '/api/mas/elearning_prototype_img?$sort[id]=1',
      headers: headers,
      params,
    })

    return { type: 'ApiGetElearningPrototypeImg_SUCCESS', data_info: data_result }

  } catch (err) {
    return { type: 'ApiGetElearningPrototypeImg_ERROR' }

  } finally {
    dispatch(hideLoading())
  }
}

export const getTypeActivityCpd = () => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_TYPE_ACTIVITY_CPD.REQUEST })

    const accessToken = localStorage.getItem('accessToken')
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }

    const params = {
      show_org: 1,
    }
    const { data } = await getAPI({
      url: `/api/mas/vw_activity_type`,
      params,
      headers: headers,
    })

    return dispatch({ type: GET_TYPE_ACTIVITY_CPD.SUCCESS, data })
  } catch (err) {
    return dispatch({
      type: GET_TYPE_ACTIVITY_CPD.FAILURE,
      error: err,
      showAlert: true,
      alertMessage: 'Get Type Activity CPD Failure',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const getBranchActivityCpd = (activity_type_no) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_BRANCH_ACTIVITY_CPD.REQUEST })

    const accessToken = localStorage.getItem('accessToken')
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }
    const params = {
      show_org: 1,
      activity_type_no,
    }
    const { data } = await getAPI({
      url: `/api/mas/vw_field_activity`,
      params,
      headers: headers,
    })

    return dispatch({ type: GET_BRANCH_ACTIVITY_CPD.SUCCESS, data })
  } catch (err) {
    return dispatch({
      type: GET_BRANCH_ACTIVITY_CPD.FAILURE,
      error: err,
      showAlert: true,
      alertMessage: 'Get Branch Activity CPD Failure',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const getCriteriaCpd = (activity_number) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_CRITERIA_CPD.REQUEST })

    const accessToken = localStorage.getItem('accessToken')
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }
    const params = {
      show_org: 1,
      activity_number,
    }
    const { data } = await getAPI({
      url: `/api/mas/vw_criteria_activity`,
      params,
      headers: headers,
    })

    return dispatch({ type: GET_CRITERIA_CPD.SUCCESS, data })
  } catch (err) {
    return dispatch({
      type: GET_CRITERIA_CPD.FAILURE,
      error: err,
      showAlert: true,
      alertMessage: 'Get Criteria CPD Failure',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const getBranchEngineer = () => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_BRANCH_ENGINEER.REQUEST })

    const accessToken = localStorage.getItem('accessToken')
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }

    const { data } = await getAPI({
      url: `/api/mas/event_engineering`,
      headers: headers,
    })

    return dispatch({ type: GET_BRANCH_ENGINEER.SUCCESS, data })
  } catch (err) {
    return dispatch({
      type: GET_BRANCH_ENGINEER.FAILURE,
      error: err,
      showAlert: true,
      alertMessage: 'Get Branch Engineer Failure',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const getMemberCOE = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_MEMBER_COE.REQUEST })
    const accessToken = localStorage.getItem('accessToken')
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }

    const response = await postAPI({
      url: `/api/thirdparty/ApiGetMemberCOE`,
      data,
      headers: headers,
    })

    if (response.code === 200) {
      const { data_member } = response
      dispatch({
        type: GET_MEMBER_COE.SUCCESS,
      })
      return data_member
    }

    return null
  } catch (error) {
    dispatch({
      type: GET_MEMBER_COE.FAILURE,
      error: error,
      showAlert: true,
      alertMessage: "Can't find this member",
    })
    return null
  } finally {
    dispatch(hideLoading())
  }
}


export const ApiGet_vw_cpd_activity_criteria_and_conditions =
  (filter = { limit: 200, skip: 0 }) =>
  async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: GET_vw_cpd_activity_criteria_and_conditions.REQUEST })
      const accessToken = localStorage.getItem('accessToken');
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }
      let params = {
        $limit: filter.limit,
        $skip: filter.skip,
      }

      if (filter.activity_type) {
        params = { ...params, activity_type_no: filter.activity_type }
      }

      if (filter.status_id) {
        params = { ...params, status_id: filter.status_id }
      }

      const data_result = await getAPI({
        url: '/api/mas/org_vw_cpd_activity_criteria_and_conditions?$sort[id]=1',
        headers: headers,
        params,
      })

      return { type: 'ApiGet_vw_cpd_activity_criteria_and_conditions_SUCCESS', data_info: data_result }
    } catch (err) {
      return { type: 'ApiGet_vw_cpd_activity_criteria_and_conditions_ERROR' }
    } finally {
      dispatch(hideLoading())
    }
  }


  export const ApiGet_vw_activity_type =
  (filter = { limit: 200, skip: 0 }) =>
  async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: GET_vw_activity_type.REQUEST })
      const accessToken = localStorage.getItem('accessToken');
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }
      let params = {
        $limit: filter.limit,
        $skip: filter.skip,
      }

      const data_result = await getAPI({
        url: '/api/mas/org_vw_activity_type?$sort[activity_type_no]=1',
        headers: headers,
        params,
      })

      return { type: 'ApiGet_vw_activity_type_SUCCESS', data_info: data_result }
    } catch (err) {
      return { type: 'ApiGet_vw_activity_type_ERROR' }
    } finally {
      dispatch(hideLoading())
    }
  }


  export const Api_Update_ActivityCriteria = (id, data) => async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: UPDATE_MASTER.REQUEST })
      const accessToken = localStorage.getItem('accessToken')
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }
      const data_result = await patchAPI({
        url: `/api/mas/org_cpd_activity_criteria_and_conditions/${id}`,
        data,
        headers: headers,
      })
  
      return { type: 'Api_Update_ActivityCriteria_SUCCESS', data_info: data_result }
    } catch (err) {
      return { type: 'Api_Update_ActivityCriteria_ERROR' }
    } finally {
      dispatch(hideLoading())
    }
  }


  export const Api_Post_ActivityCriteria = (data) => async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: POST_MASTER.REQUEST })
      const accessToken = localStorage.getItem('accessToken')
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }
  
      const data_result = await postAPI({
        url: `/api/mas/org_cpd_activity_criteria_and_conditions`,
        data,
        headers: headers,
      })
  
      return { type: 'Api_Post_ActivityCriteria_SUCCESS', data_info: data_result }
    } catch (err) {
      return { type: 'Api_Post_ActivityCriteria_ERROR' }
    } finally {
      dispatch(hideLoading())
    }
  }

  export const Api_GET_ActivityCriteria = (id) => async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: GET_MASTER.REQUEST })
      const accessToken = localStorage.getItem('accessToken')
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }
  
      const data_result = await getAPI({
        url: `/api/mas/org_cpd_activity_criteria_and_conditions/${id}`,
        headers: headers,
      })
  
      return { type: 'Api_GET_ActivityCriteria_SUCCESS', data_info: data_result }
    } catch (err) {
      return { type: 'Api_GET_ActivityCriteria_ERROR' }
    } finally {
      dispatch(hideLoading())
    }
  }


  export const ApiGet_vw_service_condition_cpd =
  (filter = { limit: 200, skip: 0 }) =>
  async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: GET_vw_service_condition_cpd.REQUEST })
      const accessToken = localStorage.getItem('accessToken');
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }
      let params = {
        $limit: filter.limit,
        $skip: filter.skip,
      }

      if (filter.service_type_id) {
        params = { ...params, service_type_id: filter.service_type_id }
      }

      if (filter.status_id) {
        params = { ...params, status_id: filter.status_id }
      }

      const data_result = await getAPI({
        url: '/api/mas/org_vw_service_condition_cpd?$sort[id]=1',
        headers: headers,
        params,
      })

      return { type: 'ApiGet_vw_service_condition_cpd_SUCCESS', data_info: data_result }
    } catch (err) {
      return { type: 'ApiGet_vw_service_condition_cpd_ERROR' }
    } finally {
      dispatch(hideLoading())
    }
  }

  export const ApiGET_SERVICE_TYPE =
  (filter = { limit: 200, skip: 0 }) =>
  async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: GET_SERVICE_TYPE.REQUEST })

      const accessToken = localStorage.getItem('accessToken');
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }
      let params = {
        $limit: filter.limit,
        $skip: filter.skip,
        status_id: 1,
      }

      const data_result = await getAPI({
        url: '/api/mas/org_service_type?$sort[id]=1',
        headers: headers,
        params,
      })

      return { type: 'ApiGET_SERVICE_TYPE_SUCCESS', data_info: data_result }
    } catch (err) {
      return { type: 'ApiGET_SERVICE_TYPE_ERROR' }
    } finally {
      dispatch(hideLoading())
    }
  }


  export const Api_Update_service_condition_cpd = (id, data) => async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: UPDATE_MASTER.REQUEST })
      const accessToken = localStorage.getItem('accessToken')
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }
  
      const data_result = await patchAPI({
        url: `/api/mas/org_service_condition_cpd/${id}`,
        data,
        headers: headers,
      })
  
      return { type: 'Api_Update_service_condition_cpd_SUCCESS', data_info: data_result }
    } catch (err) {
      return { type: 'Api_Update_service_condition_cpd_ERROR' }
    } finally {
      dispatch(hideLoading())
    }
  }

  export const Api_Post_service_condition_cpd = (data) => async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: POST_MASTER.REQUEST })
      const accessToken = localStorage.getItem('accessToken')
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }
  
      const data_result = await postAPI({
        url: `/api/mas/org_service_condition_cpd`,
        data,
        headers: headers,
      })
  
      return { type: 'Api_Post_service_condition_cpd_SUCCESS', data_info: data_result }
    } catch (err) {
      return { type: 'Api_Post_service_condition_cpd_ERROR' }
    } finally {
      dispatch(hideLoading())
    }
  }


  export const Api_GET_service_condition_cpd = (id) => async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: GET_MASTER.REQUEST })
      const accessToken = localStorage.getItem('accessToken')
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }
  
      const data_result = await getAPI({
        url: `/api/mas/org_service_condition_cpd/${id}`,
        headers: headers,
      })
  
      return { type: 'Api_GET_service_condition_cpd_SUCCESS', data_info: data_result }
    } catch (err) {
      return { type: 'Api_GET_service_condition_cpd_ERROR' }
    } finally {
      dispatch(hideLoading())
    }
  }


  export const Api_GETCpdConsent = (filter = { limit: 1, skip: 0 }) => async (dispatch) => {
    try {
      
      dispatch(showLoading())
      dispatch({ type: GET_MASTER.REQUEST })
      const accessToken = localStorage.getItem('accessToken')
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }
      

      let params = {
        $limit: filter.limit,
        $skip: filter.skip
      }
      
      const data_result = await getAPI({
        url: '/api/mas/org_cpd_consent?$sort[id]=1',
        headers: headers,
        params,
      })
  
      return { type: 'Api_GETCpdConsent_SUCCESS', data_info: data_result }
    } catch (err) {
      return { type: 'Api_GETCpdConsent_ERROR' }
    } finally {
      dispatch(hideLoading())
    }
  }


  export const Api_UpdateCpdConsent = (id, data) => async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: UPDATE_MASTER.REQUEST })
      
      const accessToken = localStorage.getItem('accessToken')
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }
      
      const data_result = await patchAPI({
        url: `/api/mas/org_cpd_consent/${id}`,
        data,
        headers: headers,
      })

      return { type: 'Api_UpdateCpdConsent_SUCCESS', data_info: data_result }
    } catch (err) {
      return { type: 'Api_UpdateCpdConsent_ERROR' }
    } finally {
      dispatch(hideLoading())
    }
  }

  export const Api_PostCpdConsent = (data) => async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: POST_MASTER.REQUEST })
      const accessToken = localStorage.getItem('accessToken')
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }
  
      const data_result = await postAPI({
        url: `/api/mas/org_cpd_consent`,
        data,
        headers: headers,
      })
  
      return { type: 'Api_PostCpdConsent_SUCCESS', data_info: data_result }
    } catch (err) {
      return { type: 'Api_PostCpdConsent_ERROR' }
    } finally {
      dispatch(hideLoading())
    }
  }

  export const ApiGetStatus =
  (filter = { limit: 200, skip: 0 }) =>
  async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: GET_STATUS.REQUEST })

      const headers = {
        Authorization: null,
        'Content-Type': 'application/json',
      }
      let params = {
        $limit: filter.limit,
        $skip: filter.skip,
      }

      if (filter.category_name) {
        params = { ...params, category_name: filter.category_name }
      }

      const data_result = await getAPI({
        url: '/api/mas/mas_status?$sort[id]=1',
        headers: headers,
        params,
      })

      return { type: 'ApiGetStatus_SUCCESS', data_info: data_result }
    } catch (err) {
      return { type: 'ApiGetStatus_ERROR' }
    } finally {
      dispatch(hideLoading())
    }
  }

  export const ApiGetknowledge = (filter = { limit: 200, skip: 0 }) => async (dispatch) => {
    try {
      
      dispatch(showLoading())
      dispatch({ type: GET_knowledge.REQUEST })
      //const user_info_data = JSON.parse(localStorage.getItem('user_info'))
      const headers = {
        Authorization: null,
        'Content-Type': 'application/json',
      }
      let params = {
        '$limit': filter.limit,
        '$skip': filter.skip,
      }
      /*
      if (user_info_data.organizations_id) {
        params = { ...params, category_name: user_info_data.organizations_id }
      }
      */
      const data_result = await getAPI({
        url: '/api/mas/knowledge?$sort[id]=1',
        headers: headers,
        params,
      })
  
      return { type: 'ApiGetknowledge_SUCCESS', data_info: data_result }
  
    } catch (err) {
      return { type: 'ApiGetknowledge_ERROR' }
  
    } finally {
      dispatch(hideLoading())
    }
  }

  export const ApiGetvw_field_study = (filter = { limit: 200, skip: 0 }) => async (dispatch) => {
    try {
      
      dispatch(showLoading())
      dispatch({ type: GET_vw_field_study.REQUEST })
      const user_info_data = JSON.parse(localStorage.getItem('user_info'))
      const headers = {
        Authorization: null,
        'Content-Type': 'application/json',
      }
      let params = {
        '$limit': filter.limit,
        '$skip': filter.skip
      }
      
      if (user_info_data.organizations_id) {
        params = { ...params, '$or[0][organizations_id]': 0, '$or[1][organizations_id]': user_info_data.organizations_id}
      }else{
        params = { ...params, '$or[0][organizations_id]': 0}
      }

      if (filter.knowledge_id) {
        if(filter.knowledge_id > 0){
          params = { ...params, 'knowledge_id': filter.knowledge_id}
        }
      }

      if (filter.name) {
        params = { ...params, 'field_study_th[$like]': `%${filter.name}%`}
      }
      
      const data_result = await getAPI({
        url: '/api/mas/vw_field_study?$sort[id]=1',
        headers: headers,
        params,
      })
  
      return { type: 'ApiGetvw_field_study_SUCCESS', data_info: data_result }
  
    } catch (err) {
      return { type: 'ApiGetvw_field_study_ERROR' }
  
    } finally {
      dispatch(hideLoading())
    }
  }

  export const Api_Post_field_study = (data) => async (dispatch) => {
    try {
      
      dispatch(showLoading())
      dispatch({ type: POST_field_study.REQUEST })
      const headers = {
        Authorization: null,
        'Content-Type': 'application/json',
      }
      
      const data_result = await  postAPI({
        url: `/api/mas/field_study`,
        data,
        headers: headers,
      })
      
      return { type: 'Api_Post_field_study_SUCCESS', data_info: data_result }
  
    } catch (err) {
      return { type: 'Api_Post_field_study_ERROR' }
  
    } finally {
      dispatch(hideLoading())
    }
  }