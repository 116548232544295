import React, { useState, useRef } from 'react'
import styled from 'styled-components'

// Styled input for individual OTP digits
const OtpInput = styled.input`
  width: 40px;
  height: 50px;
  margin: 0 5px 12px 5px;
  font-size: 48px;
  text-align: center;
  border: 2px solid var(--OTP-INPUT-BORDER);
  border-radius: 8px;
  color: var(--OTP-INPUT-FONT);
  font-weight: 500;

  &:focus {
    box-shadow: 0px 0px 0px 4px rgba(158, 119, 237, 0.24), 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }
`

// Styled container for OTP inputs
const OtpContainer = styled.div`
  display: flex;
  justify-content: center;
`

const OtpInputComponent = ({ setFullOtp }) => {
  const inputsRef = useRef([])
  const [otp, setOtp] = useState(new Array(6).fill(''))

  const focusNextInput = (index, event) => {
    const value = event.target.value
    if (/^\d$/.test(value)) {
      if (index < 5) {
        // Focus next input
        inputsRef.current[index + 1].focus()
      }
    }
  }

  const focusPrevInput = (index, event) => {
    if (event.key === 'Backspace' && event.target.value === '' && index > 0) {
      // Focus previous input
      inputsRef.current[index - 1].focus()
    }
  }

  const handleChange = (index, event) => {
    const value = event.target.value
    // If the value is not a digit, prevent the change
    if (!/^\d$/.test(value) && value !== '') return

    const newOtp = [...otp]
    newOtp[index] = value
    setOtp(newOtp)
    setFullOtp(newOtp)
    focusNextInput(index, event)
  }

  const handleKeyDown = (index, event) => {
    focusPrevInput(index, event)
  }

  const handlePaste = (event) => {
    event.preventDefault()
    const pastedData = event.clipboardData.getData('text/plain')

    // Ensure the pasted data consists of only digits and has a length <= 6
    if (/^\d+$/.test(pastedData) && pastedData.length <= 6) {
      const newOtp = [...otp]

      // Fill OTP digits with pasted data, starting from the first index
      for (let i = 0; i < pastedData.length; i++) {
        if (i < 6) {
          newOtp[i] = pastedData[i]
        }
      }

      setOtp(newOtp)
      setFullOtp(newOtp)

      // Focus on the last input
      if (inputsRef.current[5]) {
        inputsRef.current[5].focus()
      }
    }
  }

  return (
    <OtpContainer>
      {otp.map((digit, index) => (
        <OtpInput
          key={index}
          value={digit}
          onChange={(e) => handleChange(index, e)}
          onKeyDown={(e) => handleKeyDown(index, e)}
          onPaste={handlePaste}
          maxLength={1}
          ref={(ref) => (inputsRef.current[index] = ref)}
          // Additional props like onFocus, onBlur, etc.
        />
      ))}
    </OtpContainer>
  )
}

export default OtpInputComponent
