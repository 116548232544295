import styled from 'styled-components'
import { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate,useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'


//---- API
import { ApiGetRequestORG,ApiAddRequestORGActivity,ApiApproveRegisterORG } from '../redux/actions/organizations'
import { OrgUserAuthen } from '../redux/actions/admin'


//---- COMPONENT
import Field from '../components/form/Field'
import TextField from '../components/form/TextField'
import Button from '../components/form/button/Button'
import DialogSuccess from '../components/dialog/DialogSuccess'
import DialogFail from '../components/dialog/DialogFail'
import Dropdown from '../components/form/Dropdown'
import ButtonSecondary from '../components/form/button/ButtonSecondary'
import RadioButton from '../components/form/RadioButton'
import ButtonOutline from '../components/form/button/ButtonOutline'
import InputTextArea from '../components/widget/element/InputTextArea'
import Date from '../components/form/Date'
import DialogConfirmV2 from '../components/dialog/DialogConfirmV2'

//---- images
import logoIcon from '../assets/images/logo-icon.png'
import successIcon from '../assets/images/success-icon.png'
import failIcon from '../assets/images/fail-icon.png'
import iconPlus from '../assets/images/icon-plus.svg'
import warningIcon from '../assets/images/warning-icon.png'

const Div = styled.div`
width: 100%;
/*max-width: 400px;*/
margin: 0 auto;
.header{
  height:80px;
  width: 88%;
  margin: 0px auto;
}
.content{
  width: 55%;
  margin: 0 auto;
  padding-bottom: 96px;
}
.box_header{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}
.css_alink{
  cursor: pointer;
  color: var(--Base-Font-Title-Color);
  font-weight: 600;
}

.img-container {
  /*padding-top: 30px;*/
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    /*width: 48px;*/
    height: 48px;
  }
}

  .title {
    font-size: 30px;
    font-weight: 600;
    text-align: center;
    font-style: normal;
    color: var(--Gray-900);
  }

  .desc {
    text-align: center;
    margin-top: 12px;
  }


  .login-form {
    margin-top: 32px;

    .field {
      margin-top: 20px;
    }

    .link-wrapper {
      display: flex;
      justify-content: flex-end;
      margin-top: 24px;

      color: var(--Primary-700);
      font-size: 14px;
      font-style: normal;
      font-weight: 600;

      :hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }

    .button-login {
      display: block;
      width: 100%;
      margin-top: 24px;
    }
  }

  .hightlight {
    color: var(--Primary-700);
    font-weight: 800;
  }

  .copyright {
    color: var(--Gray-500);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    margin-top: 180px;
  }

  @media screen and (max-width: 440px) {
    max-width: 300px;
    .title {
      font-size: 20px;
    }

    .desc {
      font-size: 14px;
    }
  }

  .topic_title{
    font-size: 18px;
    font-weight: 600;
    font-style: normal;
    color: var(--Gray-900);
  }
  .topic_desc{
    font-size: 14px;
  }
  .border_buttom {
    border-bottom: 1px solid rgb(234, 236, 240);
  }
  .box_fill_data {
    /*margin: 16px 24px;*/
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    gap: 20px;
    
  }
  .margin-top-space{
    margin-top : 24px;
  }

  .dialog-button {
    margin-top: 24px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;

    button {
      justify-content: center;
    }
  }

  .header_activity {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
  .header_activity_title{
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    color: var(--Base-Font-Title-Color);
  }
  .InputTextArea_css {
    width: 100%;
    font-size: 14px;
  }
  .box_fill_data_date {
    /*
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    gap: 20px;
    */
    display: grid;
    grid-template-columns: repeat(2, 22% [col-start] 78% [col-end]);
    gap: 20px;
  }
  .text_noti{
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
  .button_create {
    color: #ffffff;
    background-color: #17b26a;
    border: 1px solid #17b26a;
  }
`

const OrganizationsActivity = () => {
  // external hook
  const { t } = useTranslation()
  const { request_org_id } = useParams()
  const loglogin_id = localStorage.getItem('loglogin_id')
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [isOpenData, setisOpenData] = useState(false)
  const [DataInfo, setDataInfo] = useState(null)
  const [OldDataInfo, setOldDataInfo] = useState(null)
  const [ActivityData, setActivityData] = useState([
    {
        request_organizations_profile_id: request_org_id,
        activity_date: null,
        activity_name: null,
        activity_detail: null,
        activity_hours: null,
        activity_attendee_count: null,
    }
  ])

  const RemoveActivityData = (index_item) => {
    const currentListCopy = [...ActivityData]
    currentListCopy.splice(index_item, 1)
    setActivityData(currentListCopy)
  }

  function handleAddActivityData(currentData) {
    let newDataElement = {
        request_organizations_profile_id: request_org_id,
        activity_date: null,
        activity_name: null,
        activity_detail: null,
        activity_hours: null,
        activity_attendee_count: null,
    }

    const newStateData = [...currentData, newDataElement]

    setActivityData(newStateData)
  }

  const onChangeActivityData = (index_item, e, type_data) => {
    const newState = ActivityData.map((obj, index) => {
      if (index === index_item) {
        if (type_data == 'activity_hours') {
            return { ...obj, activity_hours: e.target.value  }
        } else if (type_data == 'activity_attendee_count') {
            return { ...obj, activity_attendee_count: e.target.value }
        } else if (type_data == 'activity_detail') {
            return { ...obj, activity_detail: e.target.value }
        } else if (type_data == 'activity_name') {
            return { ...obj, activity_name: e.target.value }
        } else if (type_data == 'activity_date') {
            return { ...obj, activity_date: e }
        }
      }
      return obj
    })
    setActivityData(newState)
  }


  useEffect(() => {
    dispatch(ApiGetRequestORG(request_org_id)).then(({ type,data_info }) => {
      if(type.endsWith('_SUCCESS')){
        if(data_info.tin){
          setDataInfo(data_info);
          setOldDataInfo(data_info);
          setisOpenData(true)
        }else{
          navigate('/OrganizationsRequest')
        }
        
      }else{
        navigate('/OrganizationsRequest')
      }
    })
  }, [dispatch])

  // success,fail modal
  const initMessageModal = {
    headline: '',
    message: '',
  }
  const [successModal, setSuccessModal] = useState(false)
  const [failModal, setFailModal] = useState(false)
  const [failModal2, setFailModal2] = useState(false)
  const [successMessageModal, setSuccessMessageModal] = useState(initMessageModal)
  const [failMessageModal, setFailMessageModal] = useState(initMessageModal)
  const [failMessageModal2, setFailMessageModal2] = useState(initMessageModal)
  const [ModalConfirmDialog, setModalConfirmDialog] = useState(false)
  const [ConfirmMessageModal, setConfirmMessageModal] = useState(initMessageModal)

  const [organizations_user_account_id, setorganizations_user_account_id] = useState(null);


  const confirmFailModal = async () => {
    setFailModal(false)
  }

  const onCancle = () => {
    navigate('/OrganizationsRegister/' + request_org_id)
  }
  const onConfirm = () => {
    if(DataInfo.data_egov != 'null'){
        setConfirmMessageModal({
            headline: 'ยืนยันการทำรายการ ขอสมัครเป็นองค์กรแม่ข่าย',
            message: DataInfo.name_th,
        })
        setModalConfirmDialog(true);
    }else{  //--- กรณีไม่ผ่าน กรมพัด
        ProcessAddRequestORGActivity();
    }
  }

  const ProcessAddRequestORGActivity = async () => {
    dispatch(ApiAddRequestORGActivity(ActivityData)).then(({ type,data_info }) => {
      if (type.endsWith('_SUCCESS')) {  
        if(DataInfo.data_egov != 'null'){
            ProcessApproveRegisterORG();
        }else{ //--- กรณีไม่ผ่าน กรมพัด
            navigate('/OrganizationsAttachFile/' + request_org_id)
        }
      }else{
        setFailMessageModal({
          headline: 'ไม่สามารถบันทึกข้อมูล',
          message: 'กรุณาลองใหม่อีกครั้ง',
        })
        setFailModal(true)
      }
    })
    
  }


  const ProcessApproveRegisterORG = async () => {
    const request = {
        request_organizations_profile_id : request_org_id,
        loglogin_id : loglogin_id  
    }
    dispatch(ApiApproveRegisterORG(request)).then(({ type,data_info }) => {
        if (type.endsWith('_SUCCESS')) {  
            if(data_info.code == 200){
                setorganizations_user_account_id(data_info.organizations_user_account_id);
                setSuccessMessageModal({
                    headline: 'ส่งคำขอสมัครเป็นองค์กรแม่ข่ายสำเร็จ',
                    subtitle: 'เลขที่รับเรื่อง ' + data_info.request_no,
                    description: 'รอตรวจเอกสารภายใน 3-5 วันทำการ',
                })
                setSuccessModal(true)
            }else{
                setFailMessageModal({
                    headline: data_info.message,
                    message: 'กรุณาลองใหม่อีกครั้ง',
                })
                setFailModal(true)
            }

        }else{
          setFailMessageModal({
            headline: 'ไม่สามารถยืนยันการทำรายการสมัครได้',
            message: 'กรุณาลองใหม่อีกครั้ง',
          })
          setFailModal(true)
        }
    })
  }
  const confirmSuccess = async () => {
    setSuccessModal(false)
    
    const request = {
        strategy: "org_user-local",
        organizations_user_account_id:organizations_user_account_id,
        loglogin_id : loglogin_id   
    }
    dispatch(OrgUserAuthen(request)).then(({ type,data_info }) => {
                
        if(type.endsWith('_SUCCESS')){
          if(data_info.accessToken){
              //alert(JSON.stringify(data_info.organizations_user_authen))
              localStorage.setItem('accessToken', data_info.accessToken);
              localStorage.setItem('user_info', JSON.stringify(data_info.organizations_user_authen ));
              window.location.href = '/'
          }else{
            
              navigate('/login')
            
          }
        }else{
          navigate('/login')
        }
        
    })
    
  }

  return (
    <Div>
      <div className="header">
        <div className="box_header">
          <div>
            <div className="img-container">
              <img src={logoIcon} alt="loco icon" />
            </div>
          </div>
        </div>
      </div>

      <div className="content">

        <form>
          <div className="title">ขอสมัครเป็นองค์กรแม่ข่าย</div>
          <div className="desc">
            กรุณากรอกข้อมูลให้ครบถ้วน
          </div>
          {isOpenData == true && (
            <div className="login-form">
              <div>
                <div className="topic_title">หัวข้อกิจกรรมการพัฒนาวิชาชีพวิศวกรรมอย่างต่อเนื่อง</div>
                <div className="topic_desc">สำหรับหน่วยงานที่ก่อตั้งเกิน 3 ปี กรุณากรอกข้อมูลผลงานย้อนหลัง 3 ปี และแผนการอบรมสัมนาประจำปีที่ยื่น</div>
                <div className="topic_desc">สำหรับหน่วยงานที่ก่อตั้งไม่เกิน 1 ปี กรุณากรอกแผนการอบรมสัมนาประจำปีที่ยื่น</div>
                <div className="topic_desc">ต้องมีผลงาน 1 ผลงานเป็นอย่างน้อย</div>               
                {ActivityData?.map((item, index) => (
                    <div>
                        <div className="border_buttom margin-top-space"></div>
                        <div className="header_activity margin-top-space">
                            <div className="header_activity_title">
                                ลำดับที่ {index + 1}
                            </div>
                            {index > 0 && (
                                <div>
                                    <ButtonOutline onClick={() => RemoveActivityData(index)}>ลบกิจกรรม</ButtonOutline>
                                </div>
                            )}
                        </div>
                        <div className="box_fill_data_date">
                            <div>
                                <Field className="field" label="วันที่จัดกิจกรรม*">
                                    <Date
                                        className={'input-calendar'}
                                        value={item.activity_date}
                                        onChange={(v) => onChangeActivityData(index, v, 'activity_date')}
                                    />
                                </Field>
                            </div>
                            <div>
                                <Field className="field" label="ชื่อกิจกรรม*">
                                    <TextField
                                        placeholder="ระบุ"
                                        value={item.activity_name}
                                        onChange={(v) => onChangeActivityData(index, v, 'activity_name')}
                                    />
                                </Field>
                                <div className="text_noti" >เฉพาะกิจกรรมที่เกี่ยวข้องทางด้านวิศวกรรม</div>
                            </div>
                        </div>
                        <div className="box_fill_data">
                            <div>
                                <Field className="field" label="รายละเอียดการบรรยาย">
                                    <InputTextArea
                                        className="InputTextArea_css"
                                        initValue={item.activity_detail}
                                        onChange={(v) => onChangeActivityData(index, v, 'activity_detail')}
                                        placeHolder="กรุณาระบุ รายละเอียดเกี่ยวกับกิจกรรม / การบรรยาย รายละเอียดของวิชา (Course Description) เนื้อหาที่บรรยายให้ความรู้กับผู้เข้าร่วมกิจกรรมตามประเภทหลักสูตรอบรม"
                                        borderColor={'var(--Gray-300)'}
                                        borderWidth="1px"
                                    />
                                </Field>
                            </div>
                        </div>
                        <div className="box_fill_data">
                            <div>
                                <Field className="field" label="จำนวนชั่วโมงอบรม*">
                                    <TextField
                                        placeholder="ระบุ"
                                        value={item.activity_hours}
                                        onChange={(v) => onChangeActivityData(index, v, 'activity_hours')}
                                        onKeyPress={(event) => {
                                          if (!/[0-9]/.test(event.key)) {
                                              event.preventDefault();
                                          }
                                        }}
                                    />
                                </Field>
                            </div>
                            <div>
                                <Field className="field" label="จำนวนผู้เข้าร่วมกิจกรรม (คน)*">
                                    <TextField
                                        placeholder="ระบุ"
                                        value={item.activity_attendee_count}
                                        onChange={(v) => onChangeActivityData(index, v, 'activity_attendee_count')}
                                        onKeyPress={(event) => {
                                          if (!/[0-9]/.test(event.key)) {
                                              event.preventDefault();
                                          }
                                        }}
                                    />
                                </Field>
                            </div>
                        </div>
                    </div>
                ))}
                <div className="margin-top-space">
                    <Button append={<img src={iconPlus} />} onClick={() => handleAddActivityData(ActivityData)} className="button_create">
                        เพิ่มกิจกรรม
                    </Button>
                </div>
              </div>

              <div className="dialog-button">
                <ButtonSecondary onClick={onCancle}>กลับ</ButtonSecondary>
                <Button onClick={onConfirm}>ถัดไป</Button>
              </div>

            </div>
          )}
        </form>

      </div>

      {/* Dialog */}
      <DialogSuccess
        open={Boolean(successModal)}
        onClose={() => setSuccessModal(false)}
        onSubmit={confirmSuccess}
        icon={successIcon}
        title={successMessageModal.headline}
        subtitle={successMessageModal.subtitle}
        description={successMessageModal.description}
        textYes={'ตกลง'}
      />
      <DialogFail
        open={Boolean(failModal)}
        onClose={() => setFailModal(false)}
        onSubmit={confirmFailModal}
        icon={failIcon}
        title={failMessageModal.headline}
        description={failMessageModal.message}
        textYes={t('ok')}
      />

     <DialogConfirmV2
        open={Boolean(ModalConfirmDialog)}
        onClose={() => setModalConfirmDialog(false)}
        onNo={() => setModalConfirmDialog(false)}
        onSubmit={() => {
          setModalConfirmDialog(false)
          ProcessAddRequestORGActivity()
        }}
        icon={warningIcon}
        title={ConfirmMessageModal.headline}
        nameItem={ConfirmMessageModal.message}
        textYes='ยืนยัน'
        textNo='ยกเลิก'
      />
    </Div>
  )
}

export default OrganizationsActivity
