import InputUnstyled from '@mui/base/Input'
import styled from 'styled-components'

import { IoMdClose } from 'react-icons/io'

const StyledTextField = styled(InputUnstyled)`
  display: inline-flex;
  background: var(--Base-White);
  border: 1px solid var(--Gray-300);
  box-shadow: var(--Shadow-xs);
  border-radius: 8px;
  height: 40px;

  input {
    border-radius: 8px;
    padding: 10px;
    width: 100%;
    min-width: 0;
    border: none;
    /*font-family: 'Inter';*/
    font-family: inherit;
    font-size: 14px;
    line-height: 1.5;
    color: var(--fill-color);
  }

  .prefix {
    margin-right: 10px;
  }

  .postfix {
    /*margin-left: 10px;*/
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 10px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }
  .postfix_border_left {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 10px;
    border-left: 1px solid rgb(234, 236, 240);
    width: 100px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }


  &.Mui-error {
    border-color: red;
  }
`

const DivBadge= styled.div`
/*
width: 100%;
display: flex;
flex-wrap: wrap;
gap : 10px;
margin: 5px;
*/
display: flex;
gap : 10px;
margin: 5px;
.status-badge {
    width: fit-content;
    padding: 4px 12px;
    
    display: flex;
    align-items: center;

    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;

    border-radius: 16px;
    color: #344054;
    background: #F8F9FC;
    border: 1px #D5D9EB solid;

    cursor: pointer;

    .status {
      font-size: 14px;
      margin-left: 2px;
      color: #344054;
      cursor: pointer;
    }
    .status-badge-text{
        white-space: nowrap;
    }


    &.active {
        color: #FFFFFF;
        background: #3E4784;
        border: 1px #3E4784 solid;
    }
  }
`

const TextFieldBadge = ({ value,list_badge = [], onSubmit = () => {} , onRemoveBadge = () => {}, ...props }) => {
    const handleSubmit = (e) => {
        if (e.keyCode === 13) {
            onSubmit(value)
        }
    }

    const handleRv = (inp_value) => {
        onRemoveBadge(inp_value)
      }
  return (
    <StyledTextField
        startAdornment={list_badge.length > 0 && (
        <DivBadge>
            {list_badge?.map((item, index) => (                  
                <div className={'status-badge'} >
                    <span className="status-badge-text">{item.text}</span>
                    <IoMdClose  className="status" onClick={() => handleRv(item.value)} />
                </div>
            ))} 
        </DivBadge>
        )}
        value={value}
        onKeyDown={handleSubmit}
        {...props}
    />
  )
}

export default TextFieldBadge
