import { Navigate, RouterProvider, createBrowserRouter, redirect } from 'react-router-dom'

import { getAdminToken } from './utils/common'

import {
  headerAccountManage,
  headerActivity,
} from './configs/headerMenu.js'

import {
  subMenuHome,
  subMenuAccountManage,
  subMenuActivity
} from './configs/subMenu'


import AdminLayout from './pages/AdminLayout'
import Login from './pages/Login'
import Register from './pages/Register'
import OrganizationsAuthen from './pages/OrganizationsAuthen'
import OrganizationsRequest from './pages/OrganizationsRequest'
import OrganizationsRegister from './pages/OrganizationsRegister'
import OrganizationsActivity from './pages/OrganizationsActivity'
import OrganizationsAttachFile from './pages/OrganizationsAttachFile'
import Home from './pages/Home'
import ExtendOrgProfile from './pages/ExtendOrgProfile'
import ExtendOrgProfileActivity from './pages/ExtendOrgProfileActivity'
import ExtendOrgProfileAttachFile from './pages/ExtendOrgProfileAttachFile'

import OrgProfile from './pages/organization/OrgProfile'
import OrgUserAccount from './pages/organization/OrgUserAccount'
import ApplicationProfile from './pages/organization/ApplicationProfile'

import Elearning from './pages/elearning/Elearning'
import ElearningInfo from './pages/elearning/ElearningInfo'

import OrgActivity from './pages/activity/OrgActivity'
import CreateOrgActivity from './pages/activity/CreateOrgActivity'
import EditOrgActivity from './pages/activity/EditOrgActivity'
import EventCompareUser from './pages/activity/EventCompareUser.js'

import OrgCondition from './pages/CpdManage/OrgCondition'

import ActivityMember from './pages/activity_member/ActivityMember'
import ActivityMemberInfo from './pages/activity_member/ActivityMemberInfo'

import PageNotFound from './pages/PageNotFound'
import Maintenance from './pages/Maintenance'

import LoginToken from './pages/LoginToken'

const checkAuthenticated = () => {
  if (!getAdminToken()) {
    return redirect('/LoginToken/123')
  }
  return null
}

const router = createBrowserRouter([
  {
    path: '/',
    loader: checkAuthenticated,
    children: [
      {
        path: '/',
        element: <AdminLayout subMenu={subMenuHome} />,
        children: [
          {
            path: '/',
            element: <Home />,
          },
          {
            path: 'ApplicationProfile/:ApplicationsId',
            children: [
              {
                index: true,
                element: <ApplicationProfile />,
              },
            ],
          },
        ],
      },
      {
        path: '/AccountManage',
        element: <AdminLayout headerMenu={headerAccountManage} subMenu={subMenuAccountManage} />,
        children: [
          {
            index: true,
            element: <Navigate to="/AccountManage" />,
          },
          {
            path: 'OrgUserAccount',
            children: [
              {
                index: true,
                element: <OrgUserAccount />,
              },
            ],
          },
          {
            path: 'OrgProfile',
            children: [
              {
                index: true,
                element: <OrgProfile />,
              },
            ],
          },
        ],
      },
      {
        path: '/Activity',
        element: <AdminLayout headerMenu={headerActivity} subMenu={subMenuActivity} />,
        children: [
          {
            index: true,
            element: <Navigate to="/Activity" />,
          },
          {
            path: 'Elearning',
            children: [
              {
                index: true,
                element: <Elearning />,
              },
            ],
          },
          {
            path: 'ElearningInfo/:id',
            children: [
              {
                index: true,
                element: <ElearningInfo />,
              },
            ],
          },
          {
            path: 'OrgActivity',
            children: [
              {
                index: true,
                element: <OrgActivity />,
              },
              {
                path: 'create',
                element: <CreateOrgActivity />,
              },
              {
                path: ':id',
                element: <EditOrgActivity />,
              },
            ],
          }, 
          {
            path: 'EventCompareUser/:id',
            children: [
              {
                index: true,
                element: <EventCompareUser />,
              },
            ],
          },
          {
            path: 'ActivityMember',
            children: [
              {
                index: true,
                element: <ActivityMember />,
              },
            ],
          },
          {
            path: 'ActivityMemberInfo/:user_no',
            children: [
              {
                index: true,
                element: <ActivityMemberInfo />,
              },
            ],
          },         
        ],
      },
      {
        path: '/ExtendOrgProfile/:ApplicationsId',
        element: <ExtendOrgProfile />,
      },
      {
        path: '/ExtendOrgProfileActivity/:ApplicationsId',
        element: <ExtendOrgProfileActivity />,
      },
      {
        path: '/ExtendOrgProfileAttachFile/:ApplicationsId',
        element: <ExtendOrgProfileAttachFile />,
      },
      {
        path: '/CpdManage',
        element: <AdminLayout headerMenu={headerActivity} subMenu={subMenuActivity} />,
        children: [          
          {
            path: 'OrgCondition',
            children: [
              {
                index: true,
                element: <OrgCondition />,
              },
            ],
          },
        ]
      }
    ],
  },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/Register',
    element: <Register />,
  },
  {
    path: '/OrganizationsAuthen',
    element: <OrganizationsAuthen />,
  },
  {
    path: '/OrganizationsRequest',
    element: <OrganizationsRequest />,
  },
  {
    path: '/OrganizationsRegister/:request_org_id',
    element: <OrganizationsRegister />,
  },
  {
    path: '/OrganizationsActivity/:request_org_id',
    element: <OrganizationsActivity />,
  },
  {
    path: '/OrganizationsAttachFile/:request_org_id',
    element: <OrganizationsAttachFile />,
  },
  {
    path: '/LoginToken/:token',
    element: <LoginToken />,
  },
  {
    path: '/Maintenance',
    element: <Maintenance />,
    //loader: async () => redirect('https://cpdorgv2.coe.or.th') //---- แบบ URL
  },  
  {
    path: '/*',
    element: <PageNotFound />,
  },
])

const Router = () => {
  return <RouterProvider router={router} />
}

export default Router
