import styled from 'styled-components'
import { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate,useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'


//---- API
import { ApiGetRequestORG,ApiUpdateRequestORG } from '../redux/actions/organizations'
import { ApiGetProvince,ApiGetDistrict,ApiGetSub_district,ApiGetOrgType,getTitle } from '../redux/actions/master'

//---- COMPONENT
import Field from '../components/form/Field'
import TextField from '../components/form/TextField'
import Button from '../components/form/button/Button'
import DialogSuccess from '../components/dialog/DialogSuccess'
import DialogFail from '../components/dialog/DialogFail'
import Dropdown from '../components/form/Dropdown'
import ButtonSecondary from '../components/form/button/ButtonSecondary'
import RadioButton from '../components/form/RadioButton'
import AutoComplete from '../components/form/AutoComplete'

//---- images
import logoIcon from '../assets/images/logo-icon.png'
import successIcon from '../assets/images/success-icon.png'
import failIcon from '../assets/images/fail-icon.png'

const Div = styled.div`
width: 100%;
/*max-width: 400px;*/
margin: 0 auto;
.header{
  height:80px;
  width: 88%;
  margin: 0px auto;
}
.content{
  width: 55%;
  margin: 0 auto;
  padding-bottom: 96px;
}
.box_header{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}
.css_alink{
  cursor: pointer;
  color: var(--Base-Font-Title-Color);
  font-weight: 600;
}

.img-container {
  /*padding-top: 30px;*/
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    /*width: 48px;*/
    height: 48px;
  }
}

  .title {
    font-size: 30px;
    font-weight: 600;
    text-align: center;
    font-style: normal;
    color: var(--Gray-900);
  }

  .desc {
    text-align: center;
    margin-top: 12px;
  }


  .login-form {
    margin-top: 32px;

    .field {
      margin-top: 20px;
    }

    .link-wrapper {
      display: flex;
      justify-content: flex-end;
      margin-top: 24px;

      color: var(--Primary-700);
      font-size: 14px;
      font-style: normal;
      font-weight: 600;

      :hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }

    .button-login {
      display: block;
      width: 100%;
      margin-top: 24px;
    }
  }

  .hightlight {
    color: var(--Primary-700);
    font-weight: 800;
  }

  .copyright {
    color: var(--Gray-500);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    margin-top: 180px;
  }

  @media screen and (max-width: 440px) {
    max-width: 300px;
    .title {
      font-size: 20px;
    }

    .desc {
      font-size: 14px;
    }
  }

  .topic_title{
    font-size: 18px;
    font-weight: 600;
    font-style: normal;
    color: var(--Gray-900);
  }
  .border_buttom {
    border-bottom: 1px solid rgb(234, 236, 240);
  }
  .box_fill_data {
    /*margin: 16px 24px;*/
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    gap: 20px;
    
  }
  .margin-top-space{
    margin-top : 24px;
  }

  .dialog-button {
    margin-top: 24px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;

    button {
      justify-content: center;
    }
  }
`
const DivErrorMessage = styled.div`
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
`

const OrganizationsRegister = () => {
  // external hook
  const { t } = useTranslation()
  const { request_org_id } = useParams()
  const loglogin_id = localStorage.getItem('loglogin_id')
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [isOpenData, setisOpenData] = useState(false)
  const [DataInfo, setDataInfo] = useState(null)
  const [OldDataInfo, setOldDataInfo] = useState(null)
  const [org_type_option, setorg_type_option] = useState([])
  const [province_option, setprovince_option] = useState([])
  const [district_option, setdistrict_option] = useState([])
  const [sub_district_option, setsub_district_option] = useState([])
  const [title_option, settitle_option] = useState([])
  const initRadioContactTypeList = [
    {
      text: 'ผู้ที่ทำรายการเป็นผู้ประสานงานหลัก',
      value: 1,
    },
    {
      text: 'บุคคลอื่น',
      value: 2,
    },
  ]

  useEffect(() => {
    dispatch(getTitle()).then(({ type,data_info }) => {
        if(type.endsWith('_SUCCESS')){
            const newtitle_option = data_info.data.map((v) => ({
                text: v?.name_th_th,
                value: v?.id,
            }))
            //console.log(newtitle_option)
            settitle_option([...newtitle_option])
        }
    })
  }, [dispatch])

  useEffect(() => {
    dispatch(ApiGetOrgType()).then(({ type,data_info }) => {
        if(type.endsWith('_SUCCESS')){
            const neworg_type_option= data_info?.data?.map((v) => ({
                text: v?.name_th_th,
                value: v?.id,
            }))
            setorg_type_option([...neworg_type_option])
        }
    })
  }, [dispatch])

  useEffect(() => {
    dispatch(ApiGetProvince()).then(({ type,data_info }) => {
        if(type.endsWith('_SUCCESS')){
            const newsetprovince_option= data_info?.data?.map((v) => ({
                text: v?.name_th_th,
                value: v?.id,
            }))
            setprovince_option([...newsetprovince_option])
        }
    })
  }, [dispatch])

  const fetchDataDistrict = useCallback(
    async (filter) => {
      dispatch(ApiGetDistrict(filter)).then(({ type,data_info }) => {
        if(type.endsWith('_SUCCESS')){
            const newsetdistrict_option= data_info?.data?.map((v) => ({
                text: v?.name_th_th,
                value: v?.id,
            }))
            setdistrict_option([...newsetdistrict_option]);    
        }
      })
    },
    [dispatch]
  )

  const fetchDataSubDistrict = useCallback(
    async (filter) => {
      dispatch(ApiGetSub_district(filter)).then(({ type,data_info }) => {
        if(type.endsWith('_SUCCESS')){
            const newsetsub_district_option= data_info?.data?.map((v) => ({
                text: v?.name_th_th,
                value: v?.id,
                zip_code : v?.zipcode
            }))
            setsub_district_option([...newsetsub_district_option]);    
        }
      })
    },
    [dispatch]
  )

  useEffect(() => {
    dispatch(ApiGetRequestORG(request_org_id)).then(({ type,data_info }) => {
      if(type.endsWith('_SUCCESS')){
        if(data_info.tin){
          if(data_info?.province_id){
            const filter = {
              skip: 0,
              limit: 100,
              province_id :data_info?.province_id
            }
            fetchDataDistrict(filter)
            if(data_info?.district_id){
              const filter_district = {
                skip: 0,
                limit: 100,
                district_id : data_info?.district_id
              }
              fetchDataSubDistrict(filter_district);
            }
          }
          setDataInfo(data_info);
          setOldDataInfo(data_info);
          setisOpenData(true)
        }else{
          navigate('/OrganizationsRequest')
        }
        
      }else{
        navigate('/OrganizationsRequest')
      }
    })
  }, [dispatch])

  const onProvince = (v) => {
    if(v){
      if(v != OldDataInfo.province_id){
        const filter = {
          skip: 0,
          limit: 100,
          province_id : v
        }
        fetchDataDistrict(filter)
        setDataInfo({ ...DataInfo, province_id: v ,district_id : null, sub_district_id : null })
        setsub_district_option([])
      }  
    }
  }

  const onDistrict = (v) => {
    if(v){
      if(v != OldDataInfo.district_id){
        const filter = {
          skip: 0,
          limit: 100,
          district_id : v
        }
        fetchDataSubDistrict(filter)
        setDataInfo({ ...DataInfo, district_id : v, sub_district_id : null })
      }  
    }
  }

  const onSubDistrict = (v) => {
    if(v){
      if(v != OldDataInfo.sub_district_id){
        //alert(v)
        //alert(JSON.stringify(sub_district_option.filter((v_inp) => v_inp.value == v)))
        const arr_sub_district = sub_district_option.filter((v_inp) => v_inp.value == v);
        if(arr_sub_district.length > 0){
          setDataInfo({ ...DataInfo, sub_district_id : v , postal_code : arr_sub_district[0].zip_code })
        }else{
          setDataInfo({ ...DataInfo, sub_district_id : v })
        }
        
      }  
    }
  }

  const onTitlecontact_person_prefix_id = (v) => {
    if(v){
      setDataInfo({ ...DataInfo, contact_person_prefix_id: v })
    }
  }

  const onChangeContactType = (event) => {
    const { value } = event.target;
    //alert(value);
    if(value == 1){
      setDataInfo({ 
        ...DataInfo, 
        contact_type: value , 
        contact_person_prefix_id : OldDataInfo.contact_person_prefix_id,
        contact_person_first_name : OldDataInfo.contact_person_first_name,
        contact_person_last_name : OldDataInfo.contact_person_last_name,
        contact_person_position : OldDataInfo.contact_person_position,
        contact_person_idcard : OldDataInfo.contact_person_idcard,
        contact_person_phone_no : OldDataInfo.contact_person_phone_no,
        contact_person_email : OldDataInfo.contact_person_email,
      });
    }else{
      setDataInfo({ 
        ...DataInfo, 
        contact_type: value , 
        contact_person_prefix_id : null,
        contact_person_first_name : '',
        contact_person_last_name : '',
        contact_person_position : '',
        contact_person_idcard : '',
        contact_person_phone_no : '',
        contact_person_email : '',
      });
    }
    
  }

  const [DataInfoError, setDataInfoError] = useState({
    contact_person_prefix_id: {
      show_errorMessage: false,
      errorMessage: 'กรุณาระบุคำนำหน้า',
    },
    contact_person_first_name: {
      show_errorMessage: false,
      errorMessage: 'กรุณาระบุชื่อ',
    },
    contact_person_last_name: {
      show_errorMessage: false,
      errorMessage: 'กรุณาระบุนามสกุล',
    },
    contact_person_position: {
      show_errorMessage: false,
      errorMessage: 'กรุณาระบุตำแหน่ง',
    },
    contact_person_idcard: {
      show_errorMessage: false,
      errorMessage: 'กรุณาระบุเลขบัตรประจำตัวประชาชน',
    },
    contact_person_phone_no: {
      show_errorMessage: false,
      errorMessage: 'กรุณาระบุเบอร์โทรศัพท์',
    },
    contact_person_email: {
      show_errorMessage: false,
      errorMessage: 'กรุณาระบุEmail',
    },
  })


  // success,fail modal
  const initMessageModal = {
    headline: '',
    message: '',
  }
  const [successModal, setSuccessModal] = useState(false)
  const [failModal, setFailModal] = useState(false)
  const [failModal2, setFailModal2] = useState(false)
  const [successMessageModal, setSuccessMessageModal] = useState(initMessageModal)
  const [failMessageModal, setFailMessageModal] = useState(initMessageModal)
  const [failMessageModal2, setFailMessageModal2] = useState(initMessageModal)

  const confirmSuccess = async () => {
    setSuccessModal(false)
  }
  const confirmFailModal = async () => {
    setFailModal(false)
  }
  const confirmFailModal2 = async () => {
    setTargetTime(null)
    setFailModal2(false)
  }

  const onCancle = () => {
    navigate('/OrganizationsRequest')
  }

  const onSubmitNext = async () => {
    if (isValid().length == 0) {
      dispatch(ApiUpdateRequestORG(DataInfo.id, DataInfo)).then(({ type,data_info }) => {
        if (type.endsWith('_SUCCESS')) {    
          if(data_info.tin){
            navigate('/OrganizationsActivity/' + request_org_id)
          }else{
            setFailMessageModal({
              headline: 'ไม่สามารถบันทึกข้อมูล',
              message: 'กรุณาลองใหม่อีกครั้ง',
            })
            setFailModal(true)
          }    
        }else{
          setFailMessageModal({
            headline: 'ไม่สามารถบันทึกข้อมูล',
            message: 'กรุณาลองใหม่อีกครั้ง',
          })
          setFailModal(true)
        }
      })
    }
  }

  const isValid = () => {
    let data_valid = DataInfo;
    let data_check_valid = DataInfoError;
    let errors = []
    Object.keys(DataInfoError).forEach((k) => {
      let data = data_valid[k]
      if (data_check_valid[k].errorMessage != '') {
        if (!data || data < 0) {
          errors.push(data_check_valid[k].errorMessage)
          data_check_valid[k].show_errorMessage = true
        } else {
          data_check_valid[k].show_errorMessage = false
        }
      }
    })
    setDataInfoError({ ...DataInfoError })
    return errors
  }

  return (
    <Div>
      <div className="header">
        <div className="box_header">
          <div>
            <div className="img-container">
              <img src={logoIcon} alt="loco icon" />
            </div>
          </div>
        </div>
      </div>

      <div className="content">

        <form>
          <div className="title">ขอสมัครเป็นองค์กรแม่ข่าย</div>
          <div className="desc">
            กรุณากรอกข้อมูลให้ครบถ้วน
          </div>
          {isOpenData == true && (
            <div className="login-form">
              <div>
                <div className="topic_title">ข้อมูลเกี่ยวกับองค์กร / หน่วยงาน</div>
                <div className="border_buttom margin-top-space"></div>
                <div className="box_fill_data">
                  <div>
                    <Field className="field" label="เลขประจำตัวผู้เสียภาษี 13 หลัก*">
                      <TextField
                        placeholder="ระบุเลขประจำตัวผู้เสียภาษี 13 หลัก"
                        value={DataInfo.tin}
                        onChange={(e) => setDataInfo( {...DataInfo, tin: e.target.value })}
                        disabled={true}
                      />
                    </Field>
                  </div>
                  <div>
                    <Field className="field" label="ประเภทองค์กร*">
                      <Dropdown
                          id={`dd_org_type`}
                          className="dd_type_of_activity"
                          value={DataInfo.org_type}
                          optionList={org_type_option}
                          onChange={(v) => setDataInfo({ ...DataInfo, org_type: v })}
                          placeHolder={'เลือกประเภทองค์กร'}
                          disabled={true}
                          ValueplaceHolderIsNull={true}
                      />
                    </Field>
                  </div>
                </div>
                <div className="box_fill_data">
                  <div>
                    <Field className="field" label="ชื่อองค์แม่ข่าย*">
                      <TextField
                        placeholder="ระบุชื่อองค์แม่ข่าย"
                        value={DataInfo.name_th}
                        onChange={(e) => setDataInfo( {...DataInfo, name_th: e.target.value })}
                        disabled={true}
                      />
                    </Field>
                  </div>
                </div>
                <div className="box_fill_data">
                  <div>
                    <Field className="field" label="ชื่อย่อองค์กร/หน่วยงาน*">
                      <TextField
                        placeholder="ระบุ"
                        value={DataInfo.name_abbr}
                        onChange={(e) => setDataInfo( {...DataInfo, name_abbr: e.target.value })}
                      />
                    </Field>
                  </div>
                  <div>
                   <Field className="field" label="เบอร์โทรศัพท์องค์กร">
                      <TextField
                        placeholder="ระบุ"
                        value={DataInfo.phone_no}
                        onChange={(e) => setDataInfo( {...DataInfo, phone_no: e.target.value })}
                      />
                    </Field>
                  </div>
                  <div>
                   <Field className="field" label="เว็บไซต์หน่วยงาน">
                      <TextField
                        placeholder="ระบุ"
                        value={DataInfo.website}
                        onChange={(e) => setDataInfo( {...DataInfo, website: e.target.value })}
                      />
                    </Field>
                  </div>
                </div>
              </div>
              <div className="margin-top-space">
                <div className="topic_title">ที่อยู่องค์กร/หน่วยงาน</div>
                <div className="border_buttom margin-top-space"></div>
                <div className="box_fill_data">
                  <div>
                    <Field className="field" label="เลขที่*">
                      <TextField
                        placeholder="ระบุ"
                        value={DataInfo.house_no}
                        onChange={(e) => setDataInfo( {...DataInfo, house_no: e.target.value })}
                      />
                    </Field>
                  </div>
                  <div>
                   <Field className="field" label="อาคาร/หมู่บ้าน">
                      <TextField
                        placeholder="ระบุ"
                        value={DataInfo.building}
                        onChange={(e) => setDataInfo( {...DataInfo, building: e.target.value })}
                      />
                    </Field>
                  </div>
                  <div>
                   <Field className="field" label="หมู่">
                      <TextField
                        placeholder="ระบุ"
                        value={DataInfo.village_no}
                        onChange={(e) => setDataInfo( {...DataInfo, village_no: e.target.value })}
                      />
                    </Field>
                  </div>
                </div>
                <div className="box_fill_data">
                  <div>
                    <Field className="field" label="ซอย">
                      <TextField
                        placeholder="ระบุ"
                        value={DataInfo.alley}
                        onChange={(e) => setDataInfo( {...DataInfo, alley: e.target.value })}
                      />
                    </Field>
                  </div>
                  <div>
                   <Field className="field" label="ถนน">
                      <TextField
                        placeholder="ระบุ"
                        value={DataInfo.street}
                        onChange={(e) => setDataInfo( {...DataInfo, street: e.target.value })}
                      />
                    </Field>
                  </div>
                  <div>
                   <Field className="field" label="จังหวัด *">
                        {
                          /*
                          <Dropdown
                            id={`dd_province_id`}
                            value={DataInfo.province_id}
                            optionList={province_option}
                            onChange={onProvince}
                            placeHolder={'เลือก'}
                            ValueplaceHolderIsNull={true}
                          />
                          */
                        }
                        <AutoComplete
                          placeHolder={'เลือก'}
                          optionList={province_option}
                          value={DataInfo.province_id}
                          onChange={onProvince}
                        />
                    </Field>
                  </div>
                </div>
                <div className="box_fill_data">
                  <div>
                    <Field className="field" label="อำเภอ / เขต *">
                      {
                        /*
                        <Dropdown
                          id={`dd_district_id`}
                          value={DataInfo.district_id}
                          optionList={district_option}
                          onChange={onDistrict}
                          placeHolder={'เลือก'}
                          ValueplaceHolderIsNull={true}
                        />
                        */
                      }

                        <AutoComplete
                          placeHolder={'เลือก'}
                          optionList={district_option}
                          value={DataInfo.district_id}
                          onChange={onDistrict}
                        />
                    </Field>
                  </div>
                  <div>
                   <Field className="field" label="ตำบล / แขวง *">
                      {
                        /*
                        <Dropdown
                          id={`dd_sub_district_id`}
                          value={DataInfo.sub_district_id}
                          optionList={sub_district_option}
                          onChange={onSubDistrict}
                          placeHolder={'เลือก'}
                          ValueplaceHolderIsNull={true}
                        />
                        */
                      }


                        <AutoComplete
                          placeHolder={'เลือก'}
                          optionList={sub_district_option}
                          value={DataInfo.sub_district_id}
                          onChange={onSubDistrict}
                        />
                    </Field>
                  </div>
                  <div>
                   <Field className="field" label="รหัสไปรษณีย์">
                      <TextField
                        placeholder="ระบุ"
                        value={DataInfo.postal_code}
                        onChange={(e) => setDataInfo( {...DataInfo, postal_code: e.target.value })}
                      />
                    </Field>
                  </div>
                </div>
              </div>
              <div className="margin-top-space">
                <div className="topic_title">รายละเอียดผู้ประสานงานหลัก</div>
                <div className="border_buttom margin-top-space"></div>
                <div className="box_fill_data">
                  <div className="margin-top-space">
                    <RadioButton optionList={initRadioContactTypeList} value={DataInfo.contact_type} handleChange={onChangeContactType} />
                  </div>
                </div>
                <div className="box_fill_data">
                  <div>
                    <Field className="field" label="คำนำหน้า">
                        <Dropdown
                            id={`dd_contact_person_prefix_id`}
                            value={DataInfo.contact_person_prefix_id}
                            optionList={title_option}
                            onChange={onTitlecontact_person_prefix_id}
                            placeHolder={'เลือก'}
                            ValueplaceHolderIsNull={true}
                            disabled={DataInfo.contact_type == 1 ? true : false}
                            error={Boolean(DataInfoError.contact_person_prefix_id.show_errorMessage)}
                          />
                          {DataInfoError.contact_person_prefix_id.show_errorMessage == true && (
                              <DivErrorMessage>{DataInfoError.contact_person_prefix_id.errorMessage}</DivErrorMessage>
                          )}
                    </Field>
                  </div>
                  <div>
                   <Field className="field" label="ชื่อ*">
                      <TextField
                        placeholder="ระบุ"
                        value={DataInfo.contact_person_first_name}
                        onChange={(e) => setDataInfo( {...DataInfo, contact_person_first_name: e.target.value })}
                        disabled={DataInfo.contact_type == 1 ? true : false}
                        error={Boolean(DataInfoError.contact_person_first_name.show_errorMessage)}
                      />
                      {DataInfoError.contact_person_first_name.show_errorMessage == true && (
                          <DivErrorMessage>{DataInfoError.contact_person_first_name.errorMessage}</DivErrorMessage>
                      )}
                    </Field>
                  </div>
                  <div>
                   <Field className="field" label="นามสกุล*">
                      <TextField
                        placeholder="ระบุ"
                        value={DataInfo.contact_person_last_name}
                        onChange={(e) => setDataInfo( {...DataInfo, contact_person_last_name: e.target.value })}
                        disabled={DataInfo.contact_type == 1 ? true : false}
                        error={Boolean(DataInfoError.contact_person_last_name.show_errorMessage)}
                      />
                      {DataInfoError.contact_person_last_name.show_errorMessage == true && (
                          <DivErrorMessage>{DataInfoError.contact_person_last_name.errorMessage}</DivErrorMessage>
                      )}
                    </Field>
                  </div>
                </div>
                <div className="box_fill_data">
                  <div>
                   <Field className="field" label="ตำแหน่ง *">
                      <TextField
                        placeholder="ระบุ"
                        value={DataInfo.contact_person_position}
                        onChange={(e) => setDataInfo( {...DataInfo, contact_person_position: e.target.value })}
                        error={Boolean(DataInfoError.contact_person_position.show_errorMessage)}
                      />
                      {DataInfoError.contact_person_position.show_errorMessage == true && (
                          <DivErrorMessage>{DataInfoError.contact_person_position.errorMessage}</DivErrorMessage>
                      )}
                    </Field>
                  </div>
                  <div>
                   <Field className="field" label="เลขบัตรประจำตัวประชาชน *">
                      <TextField
                        placeholder="ระบุ"
                        value={DataInfo.contact_person_idcard}
                        onChange={(e) => setDataInfo( {...DataInfo, contact_person_idcard: e.target.value })}
                        disabled={DataInfo.contact_type == 1 ? true : false}
                        error={Boolean(DataInfoError.contact_person_idcard.show_errorMessage)}
                      />
                      {DataInfoError.contact_person_idcard.show_errorMessage == true && (
                          <DivErrorMessage>{DataInfoError.contact_person_idcard.errorMessage}</DivErrorMessage>
                      )}
                    </Field>
                  </div>
                </div>
                <div className="box_fill_data">
                  <div>
                   <Field className="field" label="เบอร์โทรศัพท์ (เพื่อติดต่อและรับ OTP) *">
                      <TextField
                        placeholder="ระบุ"
                        value={DataInfo.contact_person_phone_no}
                        onChange={(e) => setDataInfo( {...DataInfo, contact_person_phone_no: e.target.value })}
                        disabled={DataInfo.contact_type == 1 ? true : false}
                        error={Boolean(DataInfoError.contact_person_phone_no.show_errorMessage)}
                      />
                      {DataInfoError.contact_person_phone_no.show_errorMessage == true && (
                          <DivErrorMessage>{DataInfoError.contact_person_phone_no.errorMessage}</DivErrorMessage>
                      )}
                    </Field>
                  </div>
                  <div>
                   <Field className="field" label="Email (เพื่อติดต่อและรับ OTP) *">
                      <TextField
                        placeholder="ระบุ"
                        value={DataInfo.contact_person_email}
                        onChange={(e) => setDataInfo( {...DataInfo, contact_person_email: e.target.value })}
                        disabled={DataInfo.contact_type == 1 ? true : false}
                        error={Boolean(DataInfoError.contact_person_email.show_errorMessage)}
                      />
                      {DataInfoError.contact_person_email.show_errorMessage == true && (
                          <DivErrorMessage>{DataInfoError.contact_person_email.errorMessage}</DivErrorMessage>
                      )}
                    </Field>
                  </div>
                </div>
              </div>


              <div className="dialog-button">
                <ButtonSecondary onClick={onCancle}>ยกเลิก</ButtonSecondary>
                <Button onClick={onSubmitNext}>ถัดไป</Button>
              </div>

            </div>
          )}
        </form>

      </div>

      {/* Dialog */}
      <DialogSuccess
        open={Boolean(successModal)}
        onClose={() => setSuccessModal(false)}
        onSubmit={confirmSuccess}
        icon={successIcon}
        title={successMessageModal.headline}
        description={successMessageModal.message}
        textYes={'ตกลง'}
      />
      <DialogFail
        open={Boolean(failModal)}
        onClose={() => setFailModal(false)}
        onSubmit={confirmFailModal}
        icon={failIcon}
        title={failMessageModal.headline}
        description={failMessageModal.message}
        textYes={t('ok')}
      />
    </Div>
  )
}

export default OrganizationsRegister
