import React, { useState, useEffect, useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { TimeDisplay2, getAdminUser,DateTimeHHmmssDisplay } from 'utils/common'

import { useDispatch, useSelector } from 'react-redux'
import { selectActivityCPD, selectMaster } from 'redux/selectors'
import {
  getBranchActivityCpd,
  getBranchEngineer,
  getCriteriaCpd,
  getMemberCOE,
  getTypeActivityCpd,
} from 'redux/actions/master'
import { editActivityCpd, getActivityCpdBYId,cancelActivityCpd } from 'redux/actions/activityCpd'

import styled from 'styled-components'
import { Box, Divider, Stack } from '@mui/material'
import Card from 'components/common/Card'
import TextField from 'components/form/TextField'
import Dropdown from 'components/form/Dropdown'
import TextArea from 'components/form/TextArea'
import RadioButton from 'components/form/RadioButton'
import TextFieldWithIcon from 'components/form/TextFieldWithIcon'
import UploadFileArray from 'components/upload/UploadFileArray'
import ButtonOutline from 'components/form/button/ButtonOutline'
import Button from 'components/form/button/Button'
import Date from 'components/form/Date'
import Time from 'components/form/Time'
import Modal from 'components/common/Modal'
import ConfirmEditActivityModalContent from 'components/feature/CpdManage/ConfirmEditActivityModalContent'
import SuccessModal from 'components/dialog/SuccessModal'
import FailureModal from 'components/dialog/FailureModal'
import ConfirmReopenActivityModalContent from './ConfirmReopenActivityModalContent'
import HistorySidePanelContent from './HistorySidePanelContent'
import SidePanel from 'components/common/SidePanel'
import SwitchButton from 'components/form/SwitchButton'
import DialogFail from 'components/dialog/DialogFail'
import failIcon from 'assets/images/fail-icon.png'
import ModalKnowledge from 'components/feature/knowledge/ModalKnowledge'

import { FiLink, FiPlus, FiTrash2 } from 'react-icons/fi'
import { HiOutlineDuplicate } from 'react-icons/hi'
import { PiWarningCircleBold } from 'react-icons/pi'
import { TbArrowBackUp } from 'react-icons/tb'
import { GrHistory } from 'react-icons/gr'
import { FaRegCircleCheck } from 'react-icons/fa6'
import { IoMdClose } from 'react-icons/io'
import { GoPlus } from "react-icons/go";

const Div = styled.div`
  .left-content {
    width: 350px;
    margin-right: 2rem;

    .heading-info {
      color: var(--Gray-700);
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 600;
    }
    .sub-heading-info {
      color: var(--Gray-600);
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }
  .right-content {
    flex-grow: 1;
  }

  .input-heading {
    color: var(--Gray-700);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;

    margin-bottom: 6px;
  }
  .input-sub-heading {
    color: var(--Gray-700);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;

    margin-bottom: 6px;
  }
  .card-styled {
    width: 100%;
  }
  .w-100 {
    width: 100%;
  }

  .add-btn {
    color: var(--COE-Main-CI);
    border-color: var(--COE-Main-CI);
  }
  .warning-icon-color {
    color: #dc6803;
  }
  .check-icon-color {
    color: #079455;
  }
  .warning-heading {
    color: var(--Gray-700);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
  }
  .warning-desc {
    color: var(--Gray-600);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 12px;
  }
  .content_box_tag{
      display: flex;
      align-items: center;
      gap: 10px;
  }
  .button_add_tag {
    color: var(--Primary-700);
    border-color: #ffffff;
  }
`
const IconButtonStyled = styled.div`
  color: var(--Gray-700);
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
`
const TableDataStyled = styled.table`
  width: 100%;
  border-collapse: collapse;
  thead {
    background-color: var(--Gray-50);

    border-bottom: 1px solid var(--Gray-200);
    th {
      padding: 13px 24px;
    }
  }

  tbody {
    td {
      padding: 16px 24px;
      border-bottom: 1px solid var(--Gray-200);
    }
  }
`

const DivKnowledge = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap : 10px;
  margin-top : 0px;

.status-badge {
    width: fit-content;
    padding: 4px 12px;
    
    display: flex;
    align-items: center;

    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;

    border-radius: 16px;
    color: #344054;
    background: #F8F9FC;
    border: 1px #D5D9EB solid;

    cursor: pointer;

    .status {
      font-size: 14px;
      margin-left: 2px;
      color: #344054;
      cursor: pointer;
    }

    &.active {
        color: #FFFFFF;
        background: #3E4784;
        border: 1px #3E4784 solid;
    }
  }
  .text-bold{
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    color: #344054;
  }
`

function EditActivityPart() {
  // external hook
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { id } = useParams()

  const [field_studyList, setfield_study] = useState([])

  const [ModalAddtag, setModalAddtag] = useState(false)
  const onAddtag = () => {
    setModalAddtag(true)
  }

  const handleAddedTags = (tagList) => {
    setfield_study([...tagList])
    setModalAddtag(false)
  }

  const handleRvTaglist = (inp_value) => {
    const this_TagList = field_studyList.filter((v) => v.value !== inp_value)
    setfield_study(this_TagList)
  }

  const initMessageModal = {
    headline: '',
    message: '',
  }
  const [failMessageModal, setFailMessageModal] = useState(initMessageModal)
  const [failModal, setFailModal] = useState(false)

  const fetchActivityTypeCpd = useCallback(() => {
    dispatch(getTypeActivityCpd())
  }, [dispatch])
  const fetchBranchActivityCpd = useCallback(
    (activity_type_no) => {
      dispatch(getBranchActivityCpd(activity_type_no))
    },
    [dispatch]
  )
  const fetchCriteriaCpd = useCallback(
    (activity_number) => {
      dispatch(getCriteriaCpd(activity_number))
    },
    [dispatch]
  )
  const fetchBranchEngineer = useCallback(() => {
    dispatch(getBranchEngineer())
  }, [dispatch])
  const fetchActivityById = useCallback(() => {
    dispatch(getActivityCpdBYId(id))
  }, [id])

  useEffect(() => {
    fetchActivityTypeCpd()
    fetchBranchEngineer()
    fetchActivityById()
  }, [])

  // initial type activity options
  const { typeActivityCPDOptions } = useSelector(selectMaster)
  const [typeActivityOptions, setTypeActivityOptions] = useState([])

  useEffect(() => {
    const newTypeActivityOptions = typeActivityCPDOptions?.map((v) => ({
      text: v.activity_type,
      value: v.activity_type_no,
    }))
    setTypeActivityOptions(newTypeActivityOptions)
  }, [typeActivityCPDOptions])

  // intiail branch activity options
  const { branchActivityCPDOptions } = useSelector(selectMaster)
  const [branchActivityOptions, setBranchActivityOptions] = useState([])
  useEffect(() => {
    const newBranchActivityOptions = branchActivityCPDOptions?.map((v) => ({
      text: v.activity_details,
      value: v.activity_number,
    }))
    setBranchActivityOptions(newBranchActivityOptions)
  }, [branchActivityCPDOptions])

  // initial criteria options
  const { criteriaCPDOptions } = useSelector(selectMaster)
  const [criteriaOptions, setCriteriaOptions] = useState([])
  useEffect(() => {
    const newCriteriaOptions = criteriaCPDOptions?.map((v) => ({
      text: v.activity_criteria,
      value: v.id,
    }))
    setCriteriaOptions(newCriteriaOptions)
  }, [criteriaCPDOptions])

  // initial branch engineer options
  const { branchEngineerCPDOptions } = useSelector(selectMaster)
  const [branchEngineerOptions, setBranchEngineerOptions] = useState([])
  useEffect(() => {
    const newBranchEngineerOptions = branchEngineerCPDOptions?.map((v) => ({
      text: v.name_th_th,
      value: v.id,
    }))
    setBranchEngineerOptions(newBranchEngineerOptions)
  }, [branchEngineerCPDOptions])

  // radio options
  const initialRadioOptionsForTypeCost = [
    {
      text: 'ไม่เสียค่าใช้จ่าย',
      value: true,
    },
    {
      text: 'มีค่าใช้จ่าย',
      value: false,
    },
  ]
  const initialRadioOptionsForTypeDateActivity = [
    {
      text: 'จัดงานวันเดียว',
      value: 'จัดงานวันเดียว',
    },
    {
      text: 'จัดงานหลายวัน',
      value: 'จัดงานหลายวัน',
    },
  ]
  const initialRadioOptionsForTypeActivity = [
    {
      text: 'Online',
      value: 'ออนไลน์',
    },
    {
      text: 'Offline',
      value: 'ออฟไลน์',
    },
    {
      text: 'Online & Offline',
      value: 'ออนไลน์และออฟไลน์',
    },
  ]
  const initialRadioOptionsForTypeContact = [
    {
      text: 'ผู้ทำรายการเป็นผู้ติดต่อ',
      value: 'ผู้ทำรายการเป็นผู้ติดต่อ',
    },
    {
      text: 'บุคคลอื่น',
      value: 'บุคคลอื่น',
    },
  ]

  const user = getAdminUser()
  const initialForm = {
    name: '',
    engineering_field: '',
    cpd_event_type: '',
    event_field: '',
    event_field_lv4: '',
    weight: '',
    duration_in_hours: '',
    brief_description: '',
    description: '',
    coe_member_attending_fee: '',
    individual_attending_fee: '',
    student_attending_fee: '',
    contact_person_name: user.full_name ?? '',
    contact_person_email: user.email ?? '',
    contact_person_phone_no: user.phone_no ?? '',
    registration_open_at: '',
    registration_close_at: '',
    address_extra_info: '',
    status: '1',
    open_attendee_count: '',
    location_type: 'ออนไลน์',
    conference_link: '',
    conference_id: '',
    conference_password: '',
    organization_link: '',
    fee_is_fee: 'true',
    date_event_oneday: '',
    timestart_event_oneday: '',
    timeend_event_oneday: '',
    list_file: [],
    instructors: [],
    event_date_list: [],

    // optional
    type_date_activity: 'จัดงานวันเดียว',
    type_contact: 'ผู้ทำรายการเป็นผู้ติดต่อ',

    is_email_confirm : false,
    is_open_email_varification : false,

    event_created_at : null,
    create_by_fullname : null,
  }
  const [form, setForm] = useState(initialForm)

  const onChangeForm = (name, value) => {
    if (name === 'fee_is_fee') {
      setForm((prev) => ({
        ...prev,
        [name]: value,
        coe_member_attending_fee: value === 'true' ? '' : prev.coe_member_attending_fee,
        individual_attending_fee: value === 'true' ? '' : prev.individual_attending_fee,
        student_attending_fee: value === 'true' ? '' : prev.student_attending_fee,
      }))
    } else if (name === 'is_email_confirm') {
      setForm((prev) => ({
        ...prev,
        [name]: !value,
      }))
    } else if (name === 'location_type') {
      setForm((prev) => ({
        ...prev,
        [name]: value,
        conference_link: value === 'ออฟไลน์' ? '' : prev.conference_link,
        conference_id: value === 'ออฟไลน์' ? '' : prev.conference_id,
        conference_password: value === 'ออฟไลน์' ? '' : prev.conference_password,
        address_extra_info: value === 'ออนไลน์' ? '' : prev.address_extra_info,
      }))
    } else if (name === 'type_contact') {
      setForm((prev) => ({
        ...prev,
        [name]: value,
        contact_person_name: value === 'บุคคลอื่น' ? '' : user.full_name ?? '',
        contact_person_phone_no: value === 'บุคคลอื่น' ? '' : user.phone_no ?? '',
        contact_person_email: value === 'บุคคลอื่น' ? '' : user.email ?? '',
      }))
    } else if (name === 'type_date_activity') {
      if (value === 'จัดงานวันเดียว') {
        setForm((prev) => ({
          ...prev,
          [name]: value,
          event_date_list: [],
        }))
      } else {
        setForm((prev) => ({
          ...prev,
          [name]: value,
          date_event_oneday: '',
          timestart_event_oneday: '',
          timeend_event_oneday: '',
        }))
      }
    } else {
      setForm((prev) => ({
        ...prev,
        [name]: value,
      }))
    }
  }
  const onChangeDropdown = (name, value) => {
    setForm((prev) => ({
      ...prev,
      [name]: value,
    }))

    if (name === 'cpd_event_type') {
      fetchBranchActivityCpd(value)
      setCriteriaOptions([])
    }
    if (name === 'event_field') {
      fetchCriteriaCpd(value)
    }
  }
  const onChangeEventDateList = (key, name, value) => {
    setForm((prev) => ({
      ...prev,
      event_date_list: prev?.event_date_list?.map((v) => {
        if (v.key === key) return { ...v, [name]: value }
        else return v
      }),
    }))
  }
  const addNewEventDate = () => {
    if (isEdit) {
      const newEventDate = {
        key: uuidv4(),
        date_event: '',
        timestart_event: '',
        timeend_event: '',
      }
      setForm((prev) => ({
        ...prev,
        event_date_list: [...prev.event_date_list, newEventDate],
      }))
    }
  }
  const duplicateEventDate = (key) => {
    if (isEdit) {
      const matchedEventDate = form.event_date_list.find((v) => v.key === key)
      const { date_event, timestart_event, timeend_event } = matchedEventDate
      const duplicatedEventDate = {
        key: uuidv4(),
        date_event,
        timestart_event,
        timeend_event,
      }
      setForm((prev) => ({
        ...prev,
        event_date_list: [...prev.event_date_list, duplicatedEventDate],
      }))
    }
  }
  const deleteEventDate = (key) => {
    if (isEdit) {
      setForm((prev) => ({
        ...prev,
        event_date_list: prev.event_date_list.filter((v) => v.key !== key),
      }))
    }
  }
  const onChangeInstructor = (key, name, value) => {
    setForm((prev) => ({
      ...prev,
      instructors: prev?.instructors?.map((v) => {
        if (v.key === key) return { ...v, [name]: value }
        else return v
      }),
    }))
  }
  const handleSearchInstructor = async (event, key, name) => {
    if (event.key === 'Enter') {
      const value = event.target.value
      const user_info_data = JSON.parse(localStorage.getItem('user_info'))
      const request = {
        id_card_or_fullname: value,
        organization_id : user_info_data.organizations_id
      }
      const data = await dispatch(getMemberCOE(request))
      if (data) {
        const { member_no,user_id } = data

        setForm((prev) => ({
          ...prev,
          instructors: prev?.instructors?.map((v) => {
            if (v.key === key) return { ...v, member_id: member_no , user_id : user_id }
            else return v
          }),
        }))
      } else {
        setForm((prev) => ({
          ...prev,
          instructors: prev?.instructors?.map((v) => {
            if (v.key === key) return { ...v, member_id: null , user_id: null }
            else return v
          }),
        }))
      }
    }
  }
  const addNewInstructor = () => {
    if (isEdit) {
      const newInstructor = {
        key: uuidv4(),
        full_name: '',
        event_hours_receiving: '',
        member_id: null,
        user_id : null,
      }
      setForm((prev) => ({
        ...prev,
        instructors: [...prev.instructors, newInstructor],
      }))
    }
  }
  const deleteInstructor = (key) => {
    if (isEdit) {
      setForm((prev) => ({
        ...prev,
        instructors: prev.instructors.filter((v) => v.key !== key),
      }))
    }
  }

  // isEdit
  const [isEdit, setIsEdit] = useState(false)

  const toggleEditMode = () => {
    setIsEdit(!isEdit)
  }
  const openEditMode = () => {
    toggleEditMode()
  }
  const cancelEdit = () => {
    toggleEditMode()
  }

  // set default form
  const { activityInfo } = useSelector(selectActivityCPD)

  useEffect(() => {
    if (activityInfo.id) {
      const {
        name,
        engineering_field,
        cpd_event_type,
        event_field,
        event_field_lv4,
        weight,
        duration_in_hours,
        brief_description,
        description,
        coe_member_attending_fee,
        individual_attending_fee,
        student_attending_fee,
        contact_person_name,
        contact_person_email,
        contact_person_phone_no,
        registration_open_at,
        registration_close_at,
        address_extra_info,
        status,
        open_attendee_count,
        location_type,
        conference_link,
        conference_id,
        conference_password,
        organization_link,
        fee_is_fee,
        date_event_oneday,
        timestart_event_oneday,
        timeend_event_oneday,
        list_file,
        instructors,
        event_date_list,
        is_email_confirm,
        is_open_email_varification,
        event_created_at,
        create_by_fullname,
        field_study_data
      } = activityInfo
      setForm((prev) => ({
        ...prev,
        name,
        engineering_field,
        cpd_event_type: String(cpd_event_type),
        event_field: String(event_field),
        event_field_lv4,
        weight,
        duration_in_hours,
        brief_description,
        description,
        coe_member_attending_fee,
        individual_attending_fee,
        student_attending_fee,
        contact_person_name,
        contact_person_email,
        contact_person_phone_no,
        registration_open_at,
        registration_close_at,
        address_extra_info,
        status,
        open_attendee_count,
        conference_link,
        conference_id,
        conference_password,
        organization_link,
        is_email_confirm,
        is_open_email_varification,
        date_event_oneday,
        timestart_event_oneday,
        timeend_event_oneday,
        list_file: list_file?.map((v) => ({ ...v, key: uuidv4() })),
        instructors: instructors?.map((v) => ({ ...v, key: uuidv4() })),
        event_date_list: event_date_list?.map((v) => ({ ...v, key: uuidv4() })),

        // radio
        fee_is_fee: String(fee_is_fee),
        location_type,
        type_date_activity:
          date_event_oneday || timestart_event_oneday || timeend_event_oneday ? 'จัดงานวันเดียว' : 'จัดงานหลายวัน',

        type_contact:
          user.full_name === contact_person_name && user.email === contact_person_email
            ? 'ผู้ทำรายการเป็นผู้ติดต่อ'
            : 'บุคคลอื่น',
        event_created_at,
        create_by_fullname,
      }))

      if(cpd_event_type){
        fetchBranchActivityCpd(cpd_event_type);
      }
      if(event_field){
        fetchCriteriaCpd(event_field)
      }

      if(field_study_data){
        const newTagList= field_study_data.map((v) => ({
          value: v?.field_study_id,
          knowledge_th: v?.knowledge_th,
          field_study_th: v?.field_study_th,
        }))
        setfield_study(newTagList)
      }

      const newFile = list_file?.map((v) => ({
        file_size: v.file_size,
        size: String(v.file_size / 100),
        url: v.file_url,
        name: v.file_name,
        progress_display: 'OK',
        progress_is_error: false,
        progress_percent: 100,
      }))

      setFile(newFile ?? [])
    }
  }, [activityInfo])

  // file
  const acceptList = {
    images: {
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/png': ['.png'],
    },
    files: {
      'text/csv': [],
      'application/pdf': [],
      'application/msword': [],
      'application/vnd.ms-excel': [],
      'application/zip': [],
    },
    videos: {
      'video/x-msvideo': [],
      'video/mp4': [],
      'video/mpeg': [],
      'video/webm': [],
    },
    audios: {
      'audio/mpeg': [],
      'audio/wav': [],
      'audio/webm': [],
    },
  }
  const multiple = false
  const error = false
  const limitFileSize = 100 //--- 100MB
  const [file, setFile] = useState([])
  const [acceptTypes, setAcceptTypes] = useState([])

  const initFileType = () => {
    const acceptType = [].reduce((pre, cur) => {
      return {
        ...pre,
        ...acceptList[cur],
      }
    }, {})
    setAcceptTypes(acceptType)
  }
  const handleAddFile = async (data, index) => {
    setFile((prev) => [...prev, data].filter((v) => v.progress_display == 'OK'))
  }
  const onDeleteFile = (key_file_id) => {
    setFile((prev) => prev.filter((v) => v.key_file_id !== key_file_id))
  }

  useEffect(() => {
    initFileType()
  }, [])

  // confirm modal
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false)

  const openConfirmModal = () => {
    if(field_studyList.length > 0){
      if(form.type_date_activity === 'จัดงานวันเดียว'){
        
        if(form.registration_close_at > form.date_event_oneday){
          setFailMessageModal({
              headline : 'ไม่สามารถทำรายการได้',
              message : 'วันที่ปิดรับสมัครจะต้องไม่มากกว่าวันที่จัดกิจกรรม',
          })
          setFailModal(true)
        }else{
          setIsOpenConfirmModal(true)
        }
      }else{
        if(form.event_date_list.length > 0){
          if(form.registration_close_at > form.event_date_list[0].date_event){
            setFailMessageModal({
                headline : 'ไม่สามารถทำรายการได้',
                message : 'วันที่ปิดรับสมัครจะต้องไม่มากกว่าวันที่จัดกิจกรรม',
            })
            setFailModal(true)
          }else{
            setIsOpenConfirmModal(true)
          }
        }
      }
    }else{
      setFailMessageModal({
        headline: 'กรุณาระบุแขนงความรู้',
        description: '',
      })
      setFailModal(true)
    }
  }
  const closeConfirmModal = () => {
    setIsOpenConfirmModal(false)
  }

  // success modal
  const [isOpenSuccessModal, setIsOpenSuccessModal] = useState(false)

  const openSuccessModal = () => {
    setIsOpenSuccessModal(true)
  }
  const closeSuccessModal = () => {
    fetchActivityById();
    setIsOpenSuccessModal(false);
  }

  // fail modal
  const [isOpenFailureModal, setIsOpenFailureModal] = useState(false)

  const openFailureModal = () => {
    setIsOpenFailureModal(true)
  }
  const closeFailureModal = () => {
    setIsOpenFailureModal(false)
  }

  // confirm reopen
  const [isOpenConfirmReopenActivity, setIsOpenConfirmReopenActivity] = useState(false)

  const openConfirmReopenActivity = () => {
    setIsOpenConfirmReopenActivity(true)
  }
  const closeConfirmReopenActivity = () => {
    setIsOpenConfirmReopenActivity(false)
  }

  // history side panel
  const [isOpenHistorySidePanel, setIsOpenHistorySidePanel] = useState(false)

  const openHistorySidePanel = () => {
    setIsOpenHistorySidePanel(true)
  }
  const closeHistorySidePanel = () => {
    setIsOpenHistorySidePanel(false)
  }

  // isLoading,state
  const { isLoading, state } = useSelector(selectActivityCPD)
  /*
  useEffect(() => {
    if (state === 'EDIT_ACTIVITY_CPD.SUCCESS' && isLoading === false) {
      openSuccessModal()
    }
    if (state === 'EDIT_ACTIVITY_CPD.FAILURE' && isLoading === false) {
      openFailureModal()
    }
  }, [isLoading, state])
  */

  // submit
  const onSubmitEdit = () => {
    
    const file_list = file.map((v) => ({
      file_name: v.name,
      file_url: v.url,
      file_size: v.file_size,
    }))
    
    const instructors = form.instructors ? form.instructors.map((v) => ({
      full_name: v.full_name,
      event_hours_receiving: Number(v.event_hours_receiving),
      member_id: v.member_id ? v.member_id : null,
      user_id: v.user_id ? v.user_id : null,
    })) : [];
    
    const event_date_list = form.event_date_list ? form.event_date_list.map((v) => ({
      date_event: v.date_event,
      timestart_event: TimeDisplay2(v.timestart_event),
      timeend_event: TimeDisplay2(v.timeend_event),
    })) : [];

    const request = {
      ...form,
      date_event_oneday: form.date_event_oneday === '' ? null : form.date_event_oneday,
      timestart_event_oneday: form.timestart_event_oneday === '' ? null : TimeDisplay2(form.timestart_event_oneday),
      timeend_event_oneday: form.timeend_event_oneday === '' ? null : TimeDisplay2(form.timeend_event_oneday),

      event_date_list: event_date_list.length > 0 ? event_date_list : null,
      list_file: file_list.length > 0 ? file_list : null,
      //instructors: instructors.length > 0 ? instructors : null,
      // number
      coe_member_attending_fee: form.coe_member_attending_fee === '' ? null : Number(form.coe_member_attending_fee),
      individual_attending_fee: form.individual_attending_fee === '' ? null : Number(form.individual_attending_fee),
      student_attending_fee: form.student_attending_fee === '' ? null : Number(form.student_attending_fee),
      cpd_event_type: form.cpd_event_type === '' ? null : Number(form.cpd_event_type),
      duration_in_hours: form.duration_in_hours === '' ? null : Number(form.duration_in_hours),
      engineering_field: form.engineering_field === '' ? null : Number(form.engineering_field),
      event_field: form.event_field === '' ? null : Number(form.event_field),
      open_attendee_count: form.open_attendee_count === '' ? null : Number(form.open_attendee_count),
      field_study_list : field_studyList ? field_studyList : []
    }
    closeConfirmModal();
    toggleEditMode();
    //dispatch(editActivityCpd(id, request))
    dispatch(editActivityCpd(id, request)).then(({ type }) => {
      if(type.endsWith('_SUCCESS')){
        openSuccessModal()
      }else{
        openFailureModal()
      }
    })

  }
  const reOpenActivity = (value) => {
    const request = {
      status: 4,
      staff_comment: value,
    }

    dispatch(cancelActivityCpd(id, request))
    closeConfirmReopenActivity()
    openSuccessModal()
  }

  return (
    <Div>
      <Box>
        {activityInfo.status_name === 'ยกเลิก' && (
          <Box sx={{ mb: '28px' }}>
            <Card className="card-styled">
              <Stack direction="row" spacing={2}>
                <PiWarningCircleBold className="warning-icon-color" size={25} />
                <Box>
                  <div className="warning-heading">กิจกรรมนี้ถูกยกเลิกแล้ว</div>
                  <div className="warning-desc">
                    หมายเหตุ : {activityInfo.staff_comment ? activityInfo.staff_comment : '-'}
                  </div>
                  <ButtonOutline append={<TbArrowBackUp size={20} />} onClick={openConfirmReopenActivity}>
                    เปิดกิจกรรมอีกครั้ง
                  </ButtonOutline>
                </Box>
              </Stack>
            </Card>
          </Box>
        )}

        {activityInfo.status_name === 'เปิดอีกรอบ' && (
          <Box sx={{ mb: '28px' }}>
            <Card className="card-styled">
              <Stack direction="row" spacing={2}>
                <FaRegCircleCheck className="check-icon-color" size={22} />
                <Box>
                  <div className="warning-heading">กิจกรรมนี้ถูกเปิดกลับเป็นปกติแล้ว</div>
                  <div className="warning-desc">
                    หมายเหตุ :ประธานที่ประชุมต้องการให้จัดตามกำหนดการเดิม และทำการเปลี่ยนคณะที่ประชุมแทน โดย นางสาว
                    ขวัญฤทัย ปินใจ เมื่อวันที่ 14 มีนาคม 2567 09:13:22 น.
                  </div>
                  <ButtonOutline append={<GrHistory size={18} />} onClick={openHistorySidePanel}>
                    ดูประวัติ
                  </ButtonOutline>
                </Box>
              </Stack>
            </Card>
          </Box>
        )}

        <Stack sx={{ mb: '28px' }} direction="row">
          <Box className="left-content">
            <div className="heading-info">ข้อมูลเกี่ยวกับกิจกรรม</div>
            <div className="sub-heading-info">ข้อมูลทั่วไปขององค์กรแม่ข่าย</div>
          </Box>
          <Box className="right-content">
            <Card className="card-styled">
              <Box sx={{ width: '100%', mb: 3 }}>
                <div className="input-heading">ชื่อกิจกรรม*</div>
                <TextField
                  className="w-100"
                  disabled={!isEdit}
                  value={form.name}
                  onChange={(event) => onChangeForm('name', event.target.value)}
                />
              </Box>
              <Box sx={{ width: '100%', mb: 3 }}>
                <div className="input-heading">ประเภทกิจกรรม CPD*</div>
                <Dropdown
                  className="w-100"
                  disabled={!isEdit}
                  value={form.cpd_event_type}
                  onChange={(value) => onChangeDropdown('cpd_event_type', value)}
                  optionList={typeActivityOptions}
                />
              </Box>
              <Box sx={{ width: '100%', mb: 3 }}>
                <div className="input-heading">สาขากิจกรรม CPD*</div>
                <Dropdown
                  className="w-100"
                  disabled={!isEdit}
                  value={form.event_field}
                  onChange={(value) => onChangeDropdown('event_field', value)}
                  optionList={branchActivityOptions}
                />
              </Box>
              <Box sx={{ width: '100%', mb: 3 }}>
                <div className="input-heading">หลักเกณฑ์การนับคะแนน*</div>
                <Dropdown
                  className="w-100"
                  disabled={!isEdit}
                  value={form.event_field_lv4}
                  onChange={(value) => onChangeDropdown('event_field_lv4', value)}
                  optionList={criteriaOptions}
                />
              </Box>
              <Box sx={{ width: '100%', mb: 3 }}>
                <div className="input-heading">แขนงความรู้*</div>
                {
                  /*
                                  <Dropdown
                  className="w-100"
                  disabled={!isEdit}
                  value={form.engineering_field}
                  onChange={(value) => onChangeDropdown('engineering_field', value)}
                  optionList={branchEngineerOptions}
                />
                  */
                }
                <div className="content_box_tag">
                  {field_studyList?.length > 0  && ( 
                    <div>
                      <DivKnowledge>
                          {field_studyList?.map((item, index) => (                  
                              <div className={'status-badge'} >
                                  <span className="text-bold">{item.knowledge_th}</span>-<span>{item.field_study_th}</span>
                                  <IoMdClose size={18}  className="status" onClick={() => handleRvTaglist(item.value)} />
                              </div>
                          ))} 
                      </DivKnowledge>
                    </div>
                  )}
                  {isEdit && (
                    <ButtonOutline append={<GoPlus size={18} />} className="button_add_tag" onClick={() => onAddtag()}>
                      {'เลือกแขนงความรู้'}
                    </ButtonOutline>  
                  )}



                </div>

              </Box>
              <Box sx={{ width: '100%', mb: 3 }}>
                <div className="input-heading">รายละเอียดกิจกรรม*</div>
                <TextArea
                  disabled={!isEdit}
                  value={form.description}
                  onChange={(event) => onChangeForm('description', event.target.value)}
                  placeholder="โปรดระบุ"
                />
              </Box>
              <Box sx={{ width: '100%', mb: 3 }}>
                <RadioButton
                  disabled={!isEdit}
                  value={form.fee_is_fee}
                  handleChange={(event) => onChangeForm('fee_is_fee', event.target.value)}
                  optionList={initialRadioOptionsForTypeCost}
                />
              </Box>
              <Box sx={{ width: '100%', mb: 3 }}>
                <Stack direction="row" alignItems="flex-end" spacing={1}>
                  <Box sx={{ width: '32%' }}>
                    <div className="input-heading">ค่าธรรมเนียมสำหรับสมาชิกองค์กรแม่ข่าย</div>
                    <TextFieldWithIcon
                      disabled={form.fee_is_fee == 'true' || !isEdit}
                      value={form.coe_member_attending_fee}
                      onChange={(event) => onChangeForm('coe_member_attending_fee', event.target.value)}
                      placeholder="กรุณาระบุ"
                      iconButton={'บาท'}
                    />
                  </Box>
                  <Box sx={{ width: '32%' }}>
                    <div className="input-heading">ค่าธรรมเนียมสำหรับบุคคลทั่วไป</div>
                    <TextFieldWithIcon
                      disabled={form.fee_is_fee == 'true' || !isEdit}
                      value={form.individual_attending_fee}
                      onChange={(event) => onChangeForm('individual_attending_fee', event.target.value)}
                      placeholder="กรุณาระบุ"
                      iconButton={'บาท'}
                    />
                  </Box>
                  <Box sx={{ width: '32%' }}>
                    <div className="input-heading">ค่าธรรมเนียมสำหรับนักเรียน/นักศึกษา</div>
                    <TextFieldWithIcon
                      disabled={form.fee_is_fee == 'true' || !isEdit}
                      value={form.student_attending_fee}
                      onChange={(event) => onChangeForm('student_attending_fee', event.target.value)}
                      placeholder="กรุณาระบุ"
                      iconButton={'บาท'}
                    />
                  </Box>
                </Stack>
              </Box>
              <Box sx={{ width: '100%', mb: 3 }}>
                <div className="input-heading">แนบเอกสารประกอบกิจกรรม</div>
                <div className="input-sub-heading">
                  เช่น ตารางการจัดกิจกรรม หรือ brochure กิจกรรมกรุณาแนบเอกสารเป็นไฟล์นามสกุล .pdf หรือ .jpg หรือ .jpeg
                  หรือ .png (ไม่เกิน 10MB)
                </div>

                <UploadFileArray
                  multiple={multiple}
                  accept={acceptTypes}
                  file={file?.map((v) => ({ ...v, url: `${v.url}` }))}
                  onAddFile={handleAddFile}
                  onDeleteFile={onDeleteFile}
                  error={error}
                  disabled={!isEdit}
                  FileListData={file}
                  isOneFile={false}
                  limitFileSize={limitFileSize}
                  // indexdata={index}
                  subtext={'SVG, PNG, JPG or GIF (max. 800x400px)'}
                />
              </Box>
              <Box sx={{ width: '100%', mb: 3 }}>
                <div className="input-heading">URL Link รายละเอียดกิจกรรมของแม่ข่าย</div>
                <TextFieldWithIcon
                  disabled={!isEdit}
                  value={form.organization_link}
                  onChange={(event) => onChangeForm('organization_link', event.target.value)}
                  placeholder="www.example.com"
                  iconButton={
                    <IconButtonStyled>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <FiLink /> <span>URL</span>
                      </Stack>
                    </IconButtonStyled>
                  }
                />
              </Box>
              {form.is_open_email_varification && (    
                <Box sx={{ width: '100%', mb: 3 }}>
                  <Stack direction="row" alignItems="flex-end" spacing={1}>
                    <div className="input-heading">การตั้งค่าพิเศษ : เปิดให้สมาชิก Confirm Email ผ่านระบบ Email Verification</div>
                    <div>
                      <SwitchButton
                        value={true}
                        defaultChecked={form.is_email_confirm}
                        handleChange={(event) => onChangeForm('is_email_confirm', form.is_email_confirm)}
                        disabled={!isEdit}
                      />
                    </div>
                  </Stack>
                </Box>
              )}
            </Card>
          </Box>
        </Stack>
        <Divider sx={{ mb: '40px' }} />
        <Stack sx={{ mb: '20px' }} direction="row">
          <Box className="left-content">
            <div className="heading-info">กำหนดการ</div>
            <div className="sub-heading-info">รายละเอียดวันที่ เวลา</div>
          </Box>
          <Box className="right-content">
            <Card className="card-styled">
              <Box sx={{ width: '100%', mb: 3 }}>
                <RadioButton
                  disabled={!isEdit}
                  optionList={initialRadioOptionsForTypeDateActivity}
                  value={form.type_date_activity}
                  handleChange={(event) => onChangeForm('type_date_activity', event.target.value)}
                />
              </Box>

              {form.type_date_activity === 'จัดงานวันเดียว' ? (
                <Box sx={{ width: '100%', mb: 3 }}>
                  <Stack direction="row" spacing={2}>
                    <Box sx={{ width: '32%' }}>
                      <div className="input-heading">วันที่จัดกิจกรรม*</div>
                      <Date
                        className="w-100"
                        disabled={!isEdit}
                        value={form.date_event_oneday}
                        onChange={(value) => onChangeForm('date_event_oneday', value)}
                      />
                    </Box>
                    <Box sx={{ width: '32%' }}>
                      <div className="input-heading">เวลาที่เริ่มกิจกรรม*</div>
                      <Time
                        className="w-100"
                        disabled={!isEdit}
                        value={form.timestart_event_oneday}
                        onChange={(value) => onChangeForm('timestart_event_oneday', value)}
                      />
                    </Box>
                    <Box sx={{ width: '32%' }}>
                      <div className="input-heading">เวลาที่สิ้นสุดกิจกรรม*</div>
                      <Time
                        disabled={!isEdit}
                        className="w-100"
                        value={form.timeend_event_oneday}
                        onChange={(value) => onChangeForm('timeend_event_oneday', value)}
                      />
                    </Box>
                  </Stack>
                </Box>
              ) : (
                <>
                  <TableDataStyled>
                    <thead>
                      <tr>
                        <th></th>
                        <th>วันที่จัดกิจกรรม</th>
                        <th>เวลาที่เริ่มกิจกรรม</th>
                        <th>เวลาที่สิ้นสุดกิจกรรม</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {form?.event_date_list?.map((v, i) => (
                        <tr key={v.key}>
                          <td>{i + 1}</td>
                          <td>
                            <Date
                              className="w-100"
                              disabled={!isEdit}
                              value={v.date_event}
                              onChange={(value) => onChangeEventDateList(v.key, 'date_event', value)}
                            />
                          </td>
                          <td>
                            <Time
                              className="w-100"
                              disabled={!isEdit}
                              value={v.timestart_event}
                              onChange={(value) => onChangeEventDateList(v.key, 'timestart_event', value)}
                            />
                          </td>
                          <td>
                            <Time
                              className="w-100"
                              disabled={!isEdit}
                              value={v.timeend_event}
                              onChange={(value) => onChangeEventDateList(v.key, 'timeend_event', value)}
                            />
                          </td>
                          <td>
                            <Stack direction="row" alignItems="center" spacing={1}>
                              <HiOutlineDuplicate
                                className="icon"
                                size={18}
                                onClick={() => duplicateEventDate(v.key)}
                              />
                              <FiTrash2 className="icon" onClick={() => deleteEventDate(v.key)} />
                            </Stack>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </TableDataStyled>

                  <Box sx={{ mt: 3, mb: 3 }}>
                    <ButtonOutline className="add-btn" append={<FiPlus size={20} />} onClick={addNewEventDate}>
                      เพิ่มวันที่และเวลา
                    </ButtonOutline>
                  </Box>
                </>
              )}

              <Box sx={{ width: '100%', mb: 3 }}>
                <Stack direction="row" spacing={2}>
                  <Box sx={{ width: '32%' }}>
                    <div className="input-heading">วันที่เปิดรับสมัคร*</div>
                    <Date
                      className="w-100"
                      disabled={!isEdit}
                      value={form.registration_open_at}
                      onChange={(value) => onChangeForm('registration_open_at', value)}
                    />
                  </Box>
                  <Box sx={{ width: '32%' }}>
                    <div className="input-heading">วันที่ปิดรับสมัคร*</div>
                    <Date
                      className="w-100"
                      disabled={!isEdit}
                      value={form.registration_close_at}
                      onChange={(value) => onChangeForm('registration_close_at', value)}
                    />
                  </Box>
                </Stack>
              </Box>
              <Box sx={{ width: '100%', mb: 3 }}>
                <Stack direction="row" spacing={2}>
                  <Box sx={{ width: '32%' }}>
                    <div className="input-heading">จำนวนที่นั่งที่เปิดรับสมัคร*</div>
                    <TextField
                      className="w-100"
                      disabled={!isEdit}
                      value={form.open_attendee_count}
                      onChange={(event) => onChangeForm('open_attendee_count', event.target.value)}
                    />
                  </Box>
                  <Box sx={{ width: '32%' }}>
                    <div className="input-heading">จำนวนชั่วโมงรวมที่ทำกิจกรรม*</div>
                    <TextField
                      className="w-100"
                      disabled={!isEdit}
                      value={form.duration_in_hours}
                      onChange={(event) => onChangeForm('duration_in_hours', event.target.value)}
                    />
                  </Box>
                </Stack>
              </Box>
            </Card>
          </Box>
        </Stack>
        <Divider sx={{ mb: '40px' }} />
        <Stack sx={{ mb: '20px' }} direction="row">
          <Box className="left-content">
            <div className="heading-info">สถานที่จัดกิจกรรม</div>
            <div className="sub-heading-info">กรุณาระบุข้อมูลเพื่อการประสานงาน</div>
          </Box>
          <Box className="right-content">
            <Card className="card-styled">
              <Box sx={{ width: '100%', mb: 3 }}>
                <RadioButton
                  disabled={!isEdit}
                  optionList={initialRadioOptionsForTypeActivity}
                  value={form.location_type}
                  handleChange={(event) => onChangeForm('location_type', event.target.value)}
                />
              </Box>

              {(form.location_type === 'ออนไลน์' || form.location_type === 'ออนไลน์และออฟไลน์') && (
                <Box sx={{ width: '100%', mb: 3 }}>
                  <div className="input-heading">Link สำหรับประชุมออนไลน์</div>
                  <TextField
                    className="w-100"
                    disabled={!isEdit}
                    value={form.conference_link}
                    onChange={(event) => onChangeForm('conference_link', event.target.value)}
                  />
                </Box>
              )}

              {(form.location_type === 'ออฟไลน์' || form.location_type === 'ออนไลน์และออฟไลน์') && (
                <Box sx={{ width: '100%', mb: 3 }}>
                  <div className="input-heading">รายละเอียดสถานที่จัดกิจกรรม</div>
                  <TextArea
                    disabled={!isEdit}
                    value={form.address_extra_info}
                    onChange={(event) => onChangeForm('address_extra_info', event.target.value)}
                    placeholder="โปรดระบุ"
                  />
                </Box>
              )}

              {(form.location_type === 'ออนไลน์' || form.location_type === 'ออนไลน์และออฟไลน์') && (
                <Box sx={{ width: '100%', mb: 3 }}>
                  <Stack direction="row" spacing={2}>
                    <Box sx={{ width: '49%' }}>
                      <div className="input-heading">Username/ID</div>
                      <TextField
                        className="w-100"
                        disabled={!isEdit}
                        value={form.conference_id}
                        onChange={(event) => onChangeForm('conference_id', event.target.value)}
                      />
                    </Box>
                    <Box sx={{ width: '49%' }}>
                      <div className="input-heading">Password</div>
                      <TextField
                        // type="password"
                        className="w-100"
                        disabled={!isEdit}
                        value={form.conference_password}
                        onChange={(event) => onChangeForm('conference_password', event.target.value)}
                      />
                    </Box>
                  </Stack>
                </Box>
              )}
            </Card>
          </Box>
        </Stack>
        <Divider sx={{ mb: '40px' }} />
        <Stack sx={{ mb: '20px' }} direction="row">
          <Box className="left-content">
            <div className="heading-info">รายละเอียดผู้ติดต่อ</div>
            <div className="sub-heading-info">กรุณาระบุข้อมูลเพื่อการประสานงาน</div>
          </Box>
          <Box className="right-content">
            <Card className="card-styled">
              <Box sx={{ width: '100%', mb: 3 }}>
                <RadioButton
                  disabled={!isEdit}
                  optionList={initialRadioOptionsForTypeContact}
                  value={form.type_contact}
                  handleChange={(event) => onChangeForm('type_contact', event.target.value)}
                />
              </Box>

              <Box sx={{ width: '100%', mb: 3 }}>
                <div className="input-heading">ชื่อ - นามสกุล</div>
                <TextField
                  className="w-100"
                  value={form.contact_person_name}
                  onChange={(event) => onChangeForm('contact_person_name', event.target.value)}
                  disabled={form.type_contact === 'ผู้ทำรายการเป็นผู้ติดต่อ' || !isEdit}
                />
              </Box>
              <Box sx={{ width: '100%', mb: 3 }}>
                <Stack direction="row" spacing={2}>
                  <Box sx={{ width: '49%' }}>
                    <div className="input-heading">เบอร์โทรศัพท์ที่ติดต่อได้</div>
                    <TextField
                      className="w-100"
                      value={form.contact_person_phone_no}
                      onChange={(event) => onChangeForm('contact_person_phone_no', event.target.value)}
                      disabled={!isEdit}
                    />
                  </Box>
                  <Box sx={{ width: '49%' }}>
                    <div className="input-heading">Email ที่ติดต่อได้</div>
                    <TextField
                      className="w-100"
                      value={form.contact_person_email}
                      onChange={(event) => onChangeForm('contact_person_email', event.target.value)}
                      disabled={form.type_contact === 'ผู้ทำรายการเป็นผู้ติดต่อ' || !isEdit}
                    />
                  </Box>
                </Stack>
              </Box>
              <Box sx={{ width: '100%', mb: 3 }}>
                <div className="input-heading">สร้างโดย : {form.create_by_fullname ? form.create_by_fullname : '-'} วันที่สร้าง : {form.event_created_at ? DateTimeHHmmssDisplay(form.event_created_at) : '-'}</div>
              </Box>
            </Card>
          </Box>
        </Stack>
        <Divider sx={{ mb: '26px' }} />
        <Stack direction="row" justifyContent="flex-end">
          {isEdit ? (
            <Stack direction="row" spacing={2}>
              <ButtonOutline onClick={cancelEdit}>ยกเลิก</ButtonOutline>
              <Button onClick={openConfirmModal}>บันทึก</Button>
            </Stack>
          ) : (
            <Button onClick={openEditMode}>แก้ไข</Button>
          )}
        </Stack>
      </Box>

      {/* Modal */}
      <Modal open={isOpenConfirmModal} onClose={closeConfirmModal} disableBackdropClick width={'400px'}>
        <ConfirmEditActivityModalContent name={form.name} onSubmit={onSubmitEdit} onClose={closeConfirmModal} />
      </Modal>
      <Modal open={isOpenSuccessModal} onClose={closeSuccessModal} disableBackdropClick width={'400px'}>
        <SuccessModal
          heading="ทำรายการสำเร็จ"
          textYes={'ตกลง'}
          onSubmit={closeSuccessModal}
          onClose={closeSuccessModal}
        />
      </Modal>
      <Modal open={isOpenFailureModal} onClose={closeFailureModal} disableBackdropClick width={'400px'}>
        <FailureModal
          heading="ไม่สามารถแก้ไขกิจกรรมได้"
          textYes={'ตกลง'}
          onSubmit={closeFailureModal}
          onClose={closeFailureModal}
        />
      </Modal>
      <Modal
        open={isOpenConfirmReopenActivity}
        onClose={closeConfirmReopenActivity}
        disableBackdropClick
        width={'400px'}
      >
        <ConfirmReopenActivityModalContent onClose={closeConfirmReopenActivity} onSubmit={reOpenActivity} />
      </Modal>

      {/* Side panel */}
      <SidePanel isOpen={isOpenHistorySidePanel} setIsOpen={setIsOpenHistorySidePanel} width={400}>
        <HistorySidePanelContent onClose={closeHistorySidePanel} />
      </SidePanel>

      <DialogFail
        open={Boolean(failModal)}
        onClose={() => setFailModal(false)}
        onSubmit={() => {
          setFailModal(false)
        }}
        icon={failIcon}
        title={failMessageModal.headline}
        description={failMessageModal.message}
        textYes='ตกลง'
      />
      <Modal open={ModalAddtag} disableBackdropClick>
        <ModalKnowledge
          onClose={() => setModalAddtag(false)} 
          onSubmit={(e) => handleAddedTags(e)}
          isNewTag={true}
        />
      </Modal>
    </Div>
  )
}

export default EditActivityPart
