import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import {  getAdminUser,DateDisplayDDMMBBB } from '../utils/common'
import { GET_organization_profile,ApiGetvw_orgnization_request,ApiOrgExtend } from '../redux/actions/organizations'

import Button from '../components/form/button/Button'
import TableV2 from '../components/common/TableV2'
import DialogConfirmV2 from '../components/dialog/DialogConfirmV2'
import DialogSuccess from '../components/dialog/DialogSuccess'
import DialogFail from '../components/dialog/DialogFail'
import ButtonOutline from '../components/form/button/ButtonOutline'


import { FaBullseye } from 'react-icons/fa'
import { GrStatusGoodSmall } from 'react-icons/gr'
import warningIcon from '../assets/images/warning-icon.png'
import successIcon from '../assets/images/success-icon.png'
import failIcon from '../assets/images/fail-icon.png'

const Div = styled.div`

  .content-title {
    font-size: 36px;
    font-weight: 600;
    color: var(--Base-Font-Title-Color);
  }
  .content-title-text {
    color: var(--Base-Font-Title-SUB-Color);
    font-size: 18px;
  }
  .content-detail{
    margin-top: 15px;
    display: flex;
    gap: 10px;
  }
  .border_header {
    border-bottom: 1px solid rgb(234, 236, 240);
    margin-bottom: 21px;
    margin-top: 21px;
  }
  .content-detail-card{
    border-radius: 12px;
    border: 1px solid var(--Gray-200);
    background: var(--Base-White);
    box-shadow: rgba(16, 24, 40, 0.05) 0px 1px 2px 0px;
    display: inline-block;
    padding: 24px;
    max-width: none;
  }
  .box-width-70{
    width: 60%;
  }
  .box-width-30{
    width: 40%;
  }
  .title_box{
    font-weight: bold;
  }
  .header_box{
    margin-top: 15px;
    
  }
  .data_info_box{
    color: var(--Base-Font-Focus-Color);
    font-weight: 600;
  }
  .box_flex{
    display: grid;
    /*
    grid-template-columns: repeat(2, 45% [col-start] 45% [col-end]);
    gap: 20px;
    */
    gap: 2%;
    grid-template-columns: 32% 32% 32%;
  }
  .button_register{
    margin-top: 15px;
  }
  .status-badge {
    /*width: 150px;*/
    /*padding: 0.2rem;*/
    width: fit-content;
    padding: 2px 6px;
    text-align: center;
    font-weight: 500;
    border-radius: 16px;
    color: #475467;
    background: #f5f5f4;
    border: 1px #e7e5e4 solid;

    &.expire {
      color:#B42318;
      background: #FEF3F2;
      border: 1px #FECDCA solid;
    }
    &.active {
      color:#067647;
      background: #ECFDF3;
      border: 1px #ABEFC6 solid;
    }

    .status {
      font-size: 0.6rem;
      margin-right: 0.2rem;
      color: #475467;
      

      &.expire {
        color:#B42318;
      }
      &.active {
        color:#067647;
      }

    }
  }

  .table-wrapper {
    margin-top: 24px;
    border: 1px solid #eaecf0;
    box-shadow: var(--Shadow-xs);
    border-radius: 8px;
    background-color: #ffffff;

    .table-header {
      display: flex;
      justify-content: space-between;
      padding: 16px 14px;
      border-radius: 8px 8px 0 0;
      background: var(--Table-Bg-Header);

      .group {
        display: flex;
        align-items: center;
      }

      .table-title {
        font-size: 18px;
        font-weight: 600;
        color: var(--Gray-900);
      }

      .table-total {
        margin-left: 16px;
        font-size: 12px;
        font-weight: 500;
        padding: 2px 8px;
        color: var(--BADGE-Table-Font);
        background: var(--BADGE-Table-Bg);
        border-radius: 16px;
        border: 1px var(--BADGE-Table-Border) solid;
      }
    }

    .create-bt {
      text-decoration: none;
    }
  }
  .css_magin_top_10 {
    margin-top: 10px;
  }
  .button_extend{
    width: 100%;
    margin-top: 18px;
  }

  .text-bold {
    font-weight: bold;
  }
  
  .link {
    color: var(--Td-Font-Color-Link);
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
`



const Home = () => {
  const dispatch = useDispatch()
  const user = getAdminUser()
  const navigate = useNavigate()

  const [DataInfo, setDataInfo] = useState(null)

  useEffect(() => {
    dispatch(GET_organization_profile()).then(({ type,data_info }) => {
      if(type.endsWith('_SUCCESS')){
        //alert(JSON.stringify(data_info))
        if(data_info.data_info){
          setDataInfo(data_info.data_info);
        }
        
      }
    })
  }, [dispatch])

  const limit_data = 10;
  const [total, settotal] = useState(0)
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [TableCellData, setTableCellData] = useState([])
  const TableColumnData = [
    {
      Header: 'ลำดับ',
      accessor: 'no',
      disableSortBy: false,
    },
    {
      Header: 'เลขที่รับเรื่อง',
      accessor: 'request_no',
      disableSortBy: false,
    },
    {
      Header: 'รายการ',
      accessor: 'detail',
      disableSortBy: false,
    },
    {
      Header: 'วันที่ทำรายการ',
      accessor: 'submitted_at_display',
      disableSortBy: false,
    },
    {
      Header: 'สถานะ',
      accessor: 'statustext',
      disableSortBy: false,
    },
    {
      accessor: 'button',
      disableSortBy: true,
    },
  ]

  const fetchData = useCallback(
    async (filter) => {
      dispatch(ApiGetvw_orgnization_request(filter)).then(({ type,data_info }) => {
        if(type.endsWith('_SUCCESS')){
          if (data_info.total > 0) {
            const totalPage = Math.ceil(data_info.total / limit_data)
            settotal(data_info.total)
            setTotalPages(totalPage)

            const newTableCellData = data_info.data.map((v, index) => ({
              no: limit_data * page - (9 - index),
              request_no: (
                <div className="text-bold link" onClick={() => ViewData(v.id)}>
                  {v.request_no}
                </div>
              ),

              detail: v.detail ? v.detail : '-',
              submitted_at_display: v.submitted_at_display ? v.submitted_at_display : '-',
              statustext: v.statustext ? v.statustext : '-',
              button : v.applications_status == 101 || v.applications_status == 500 ? (
                <ButtonOutline onClick={() => ViewData(v.id)}>
                    ดูรายละเอียด
                </ButtonOutline>
              ) : (
                <div></div>
              )
            }))
            setTableCellData(newTableCellData)
          }else{
            settotal(0);
            setPage(0);
            setTotalPages(0);
            setTableCellData([]);
          }
         
        }
      })
    },
    [dispatch]
  )

  useEffect(() => {
    const filter = {
      skip: 0,
      limit: limit_data,
    }
    fetchData(filter)
  }, [fetchData, limit_data])

  const onChangePaginationAttendeeAttend = async (value) => {
    const filter = {
      skip: (value.page  - 1) * limit_data, 
      limit: limit_data,
    }
    fetchData(filter)
    setPage(value.page)
  }
  const initMessageModal = {
    headline: 'ยืนยันการทำรายการ',
    nameItem: 'ต่ออายุสมาชิกองค์กรแม่ข่าย',
    subTitle: user?.organizations_name
  }

  const [ModalConfirmDialog, setModalConfirmDialog] = useState(false)
  const [ConfirmMessageModal, setConfirmMessageModal] = useState(initMessageModal)
  const [successModal, setSuccessModal] = useState(false)
  const [failModal, setFailModal] = useState(false)
  const [successMessageModal, setSuccessMessageModal] = useState(initMessageModal)
  const [failMessageModal, setFailMessageModal] = useState(initMessageModal)

  const onExtend = () => {
    setModalConfirmDialog(true);
  }

  const ApiProcessExtend = async () => {

    
    const request = {}
    dispatch(ApiOrgExtend(request)).then(({ type,data_info }) => {
      
      if(type.endsWith('_SUCCESS')){
        if(data_info.code == 200){
          if(data_info.message == 'auto_extend'){
            setSuccessMessageModal({
              headline: 'ทำรายการต่ออายุสำเร็จ',
              nameItem: 'วันที่เริ่มสมาชิกภาพ : ' + DateDisplayDDMMBBB(data_info.new_effective_date),
              subTitle: 'วันหมดอายุ : ' + DateDisplayDDMMBBB(data_info.new_expiring_at),
            })
            setSuccessModal(true)
          }else{
            //alert('/ExtendOrgProfile/' + data_info.applications_id);
            navigate('/ExtendOrgProfile/' + data_info.applications_id)
          }

        }else{
          
            setFailMessageModal({
                headline: data_info.message,
                nameItem: '',
            })
            setFailModal(true)
          
        }
      }else{
        setFailMessageModal({
          headline: 'internal server error',
          nameItem: '',
        })
        setFailModal(true)
      }
      
    })  
    
  }

  const onSubmit = () => {
    dispatch(GET_organization_profile()).then(({ type,data_info }) => {
      if(type.endsWith('_SUCCESS')){
        if(data_info.data_info){
          setDataInfo(data_info.data_info);
        }
      }
    })
    const filter = {
      skip: 0,
      limit: limit_data,
    }
    fetchData(filter)
  }

  const ViewData = (id) => {
    navigate('/ApplicationProfile/' + id)
  }

  return (
    <Div>
      <header>
        <div className="content-title">
          ยินดีต้อนรับเข้าสู่ ระบบสำหรับองค์กรแม่ข่าย
        </div>
        <div className="content-title-text">
        {user?.organizations_name ? user?.organizations_name : '-'}
        </div>
        <div className="border_header"></div>
        <div className="content-detail">
          <div className="content-detail-card box-width-70">
            <div className="title_box">
              ข้อมูลเกี่ยวกับองค์กรแม่ข่าย
            </div>
            <div className="header_box">
              ชื่อองค์กรแม่ข่าย
            </div>
            <div className="data_info_box">
              { DataInfo?.name_th ? DataInfo?.name_th : '-' }
            </div>
            <div className="header_box">
              เลขประจำตัวผู้เสียภาษี 13 หลัก
            </div>
            <div className="data_info_box">
              { DataInfo?.tin ? DataInfo?.tin : '-' }
            </div>
          </div>
          <div className="content-detail-card box-width-30">
            <div className="title_box">
              สถานภาพสมาชิกแม่ข่าย
            </div>
            <div className="header_box box_flex">
              <div >
                รหัสองค์กรแม่ข่าย
              </div>
              <div >
                สถานภาพสมาชิก
              </div>
            </div>
            <div className="data_info_box box_flex">
              
              <div >
                { DataInfo?.code ? DataInfo?.code : '-' }
              </div>
              <div >
                <div>
                  {
                    /*
                      {DataInfo?.organizations_status != '-' ? (
                        <div className={`status-badge ${DataInfo?.organizations_status == 'หมดอายุ' ? 'expire' : DataInfo?.organizations_status == 'ปกติ' ? 'active' : ''}`}>
                          <GrStatusGoodSmall className={`status ${DataInfo?.organizations_status == 'หมดอายุ' ? 'expire' : DataInfo?.organizations_status == 'ปกติ' ? 'active' : ''}`} />
                          {DataInfo?.organizations_status}
                        </div>
                        ) : (
                          <div> {DataInfo?.organizations_status} </div>
                        )
                      }
                    */
                  }
                      {DataInfo?.organizations_status != '-' ? (
                        <div
                          className="status-badge"
                          style={{
                            color: DataInfo?.badge_color?.color,
                            borderColor: DataInfo?.badge_color?.border,
                            backgroundColor: DataInfo?.badge_color?.background,
                          }}
                        >
                          <GrStatusGoodSmall 
                            className="status"
                            style={{
                              color: DataInfo?.badge_color?.color,
                            }}
                          />
                          {DataInfo?.organizations_status}
                        </div>
                        ) : (
                          <div> {DataInfo?.organizations_status} </div>
                        )
                      }

                </div>
              </div>
            </div>
            <div className="header_box box_flex">
              <div >
                วันที่เริ่มเป็นสมาชิก
              </div>
              <div >
                วันที่หมดอายุ
              </div>
              <div >
                จำนวนวันคงเหลือ
              </div>
            </div>
            <div className="data_info_box box_flex">
              
              <div >
              { DataInfo?.effective_date_display ? DataInfo?.effective_date_display : '-' }
              </div>
              <div >
              { DataInfo?.expiring_at_display ? DataInfo?.expiring_at_display : '-' }
              </div>
              <div >
              { DataInfo?.remaining_day ? DataInfo?.remaining_day + ' วัน' : '-' }
              </div>
            </div>
            {DataInfo?.is_active_extend && (
              <div>
                <Button className="button_extend" onClick={onExtend}>
                    {'ต่ออายุองค์กรแม่ข่าย'}
                </Button>              
              </div>
            )}
          </div>
        </div>
        <div className="table-wrapper">
          <div className="table-header">
            <div className="group">
              <div className="table-title">ติดตามรายการ</div>
              <div className="table-total">
                {total} รายการ
              </div>
            </div>
          </div>
          <div className="css_magin_top_10">
            <TableV2
              columns={TableColumnData}
              data={TableCellData}
              onStateChange={onChangePaginationAttendeeAttend}
              pageCount={totalPages}
              page={page}
              loading={false}
            />
          </div>
        </div>
      </header>
      <DialogConfirmV2
        open={Boolean(ModalConfirmDialog)}
        onClose={() => setModalConfirmDialog(false)}
        onNo={() => setModalConfirmDialog(false)}
        onSubmit={() => {
          setModalConfirmDialog(false)
          ApiProcessExtend()
        }}
        icon={warningIcon}
        title={ConfirmMessageModal.headline}
        description={ConfirmMessageModal.subTitle}
        nameItem={ConfirmMessageModal.nameItem}
        textYes='ยืนยัน'
        textNo='กลับ'
      />

        {/* Dialog */}
        <DialogSuccess
          open={Boolean(successModal)}
          onClose={() => setSuccessModal(false)}
          onSubmit={() => {
            setSuccessModal(false)
            onSubmit()
          }}
          icon={successIcon}
          title={successMessageModal.headline}
          description={successMessageModal.nameItem}
          description2={successMessageModal.subTitle}
          textYes='ตกลง'
        />
        <DialogFail
          open={Boolean(failModal)}
          onClose={() => setFailModal(false)}
          onSubmit={() => {
            setFailModal(false)
          }}
          icon={failIcon}
          title={failMessageModal.headline}
          description={failMessageModal.nameItem}
          textYes='ตกลง'
        />
    </Div>
  )
}

export default Home
