import { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import styled from 'styled-components'
import Field from '../components/form/Field'
import TextField from '../components/form/TextField'
import Dropdown from '../components/form/Dropdown'
import Button from '../components/form/button/Button'
// import { validateEmail, validateNumber } from '../utils/validation'
import { OrgUserAuthen, getORGRefUser, resendOTP } from '../redux/actions/admin'

import logoIcon from '../assets/images/logo-icon.png'
import { VERSION } from '../configs/app'



import PasswordField from '../components/form/PasswordInput'
import Modal from '../components/common/Modal'
import ButtonOutline from '../components/form/button/ButtonOutline'
import OtpInputComponent from '../components/form/OtpInputComponent'
import DialogSuccess from '../components/dialog/DialogSuccess'
import DialogFail from '../components/dialog/DialogFail'

import { IoMdClose } from 'react-icons/io'
import successIcon from '../assets/images/success-icon.png'
import failIcon from '../assets/images/fail-icon.png'
import otpIcon from '../assets/images/opt-icon.png'

const Div = styled.div`
  width: 100%;
  max-width: 400px;
  margin: 0 auto;

  .img-container {
    padding-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    /*
    img {
      width: 48px;
      height: 48px;
    }
    */
  }

  .title {
    font-size: 30px;
    font-weight: 600;
    text-align: center;
    font-style: normal;
    color: var(--Gray-900);
  }

  .desc {
    text-align: center;
    margin-top: 12px;
  }

  .login-form {
    margin-top: 32px;

    .field {
      margin-top: 20px;
    }

    .link-wrapper {
      display: flex;
      justify-content: flex-end;
      margin-top: 24px;

      color: var(--Primary-700);
      font-size: 14px;
      font-style: normal;
      font-weight: 600;

      :hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }

    .button-login {
      display: block;
      width: 100%;
      margin-top: 24px;
    }
  }

  .hightlight {
    color: var(--Primary-700);
    font-weight: 800;
  }

  .copyright {
    color: var(--Gray-500);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    margin-top: 180px;
  }

  @media screen and (max-width: 440px) {
    max-width: 300px;
    .title {
      font-size: 20px;
    }

    .desc {
      font-size: 14px;
    }
  }
`
const OTPContainer = styled.div`
  padding: 24px;

  .mr-12-px {
    margin-right: 12px;
  }
  .mb-20-px {
    margin-bottom: 20px;
  }
  .mb-32-px {
    margin-bottom: 32px;
  }

  .heading {
    color: var(--Gray-900);
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
  }
  p {
    margin: 0;
    color: var(--Gray-600);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    text-align: center;
  }
  .highlight-text {
    text-decoration-line: underline;
    cursor: pointer;
    color: #0086c9;
  }
  .icon {
    cursor: pointer;
  }
`
const FlexContainer = styled.div`
  display: flex;

  &.justify-content-center {
    justify-content: center;
  }
  &.justify-content-end {
    justify-content: flex-end;
  }
  &.justify-content-space-between {
    justify-content: space-between;
  }

  .w-48 {
    width: 48%;
  }
`

const OrganizationsAuthen = () => {
  // external hook
  const { t } = useTranslation()
  const lang = localStorage.getItem('lang')
  const loglogin_id = localStorage.getItem('loglogin_id')
  const navigate = useNavigate()
  const dispatch = useDispatch()
  /*
  const organizations_option = [
    { text: 'บริษัท ครีเดนซ์ จำกัด', value: 1 },
    { text: 'สถาวิศวกร', value: 2 },
  ]
  */
  const [organizations_option, setorganizations_option] = useState([])

  useEffect(() => {
      const request = {
          loglogin_id : loglogin_id
      }
      dispatch(getORGRefUser(request)).then(({ type,data_info }) => {
          if(type.endsWith('_SUCCESS')){
              if(data_info.code == 200){
                const neworganizations_option = data_info.datalist?.map((v) => ({
                    text: v?.organizations_name,
                    value: v?.id,
                }))
                setorganizations_option([...neworganizations_option])
              }
              
          }
      })
  }, [dispatch])

  // initiate data
  /*
  const fetchCompanyInfo = useCallback(
    (id) => {
      dispatch(getCompanyInfoById(id))
    },
    [dispatch]
  )
  useEffect(() => {
    fetchCompanyInfo(1)
  }, [])
  */

  // state input about log in
  const [OrgUserId, setOrgUserId] = useState('')


  // success,fail modal
  const initMessageModal = {
    headline: '',
    message: '',
  }
  const [successModal, setSuccessModal] = useState(false)
  const [failModal, setFailModal] = useState(false)
  const [failModal2, setFailModal2] = useState(false)
  const [successMessageModal, setSuccessMessageModal] = useState(initMessageModal)
  const [failMessageModal, setFailMessageModal] = useState(initMessageModal)
  const [failMessageModal2, setFailMessageModal2] = useState(initMessageModal)

  const confirmSuccess = async () => {
    setSuccessModal(false)

    if (isRedirect) {
      window.location.href = isRedirect
    } else {
      window.location.href = '/'
    }
  }
  const confirmFailModal = async () => {
    setFailModal(false)
  }
  const confirmFailModal2 = async () => {
    setTargetTime(null)
    setFailModal2(false)
  }

  // submit function
  const handleLogin = (e) => {
    e.preventDefault()
    const request = {
        strategy: "org_user-local",
        organizations_user_account_id:OrgUserId,
        loglogin_id : loglogin_id    
    }
    dispatch(OrgUserAuthen(request)).then(({ type,data_info }) => {
      
      if(type.endsWith('_SUCCESS')){
        if(data_info.accessToken){
            //alert(JSON.stringify(data_info.organizations_user_authen))
            localStorage.setItem('accessToken', data_info.accessToken);
            localStorage.setItem('user_info', JSON.stringify(data_info.organizations_user_authen ));
            window.location.href = '/'
        }else{
          
            setFailMessageModal({
                headline: 'internal server error',
                message: '',
            })
            setFailModal(true)
          
        }
      }else{
        setFailMessageModal({
          headline: 'internal server error',
          message: '',
        })
        setFailModal(true)
      }
      
    })
  }


  // other function
  const gotoRegister = () => {
    //alert('gotoRegister');
    navigate('/OrganizationsRequest')
  }
  const formattedTime = (time) => {
    return `${String(time).padStart(2, '0')}`
  }

  return (
    <Div>
      <form>
        <div className="img-container">
          <img src={logoIcon} alt="loco icon" />
        </div>
        <div className="title">กรอกข้อมูลเพื่อเข้าสู่ระบบ</div>
        <div className="desc">
          สำหรับองค์กรแม่ข่าย
        </div>

        <div className="login-form">
          <Field className="field" label="องค์กรแม่ข่ายที่ท่านสังกัด">
            <Dropdown
                id={`dd_organizations_user`}
                className="dd_type_of_activity"
                value={OrgUserId}
                optionList={organizations_option}
                onChange={(v) => setOrgUserId(v)}
                placeHolder={'เลือกองค์กรแม่ข่าย'}
            />
          </Field>

          <Button
            className="button-login"
            type="submit"
            onClick={handleLogin}
            disabled={!OrgUserId}
          >
            เข้าสู่ระบบ
          </Button>
          <div className="desc">
          ท่านยังไม่มีสังกัดแม่ข่าย?
          </div>
          <ButtonOutline
            className="button-login"
            onClick={gotoRegister}
          >
            ส่งคำขอเข้าเป็นผู้ใช้ภายใต้แม่ข่าย
          </ButtonOutline>
        </div>

      </form>


      {/* Dialog */}
      <DialogSuccess
        open={Boolean(successModal)}
        onClose={() => setSuccessModal(false)}
        onSubmit={confirmSuccess}
        icon={successIcon}
        title={successMessageModal.headline}
        description={successMessageModal.message}
        textYes={t('done')}
      />
      <DialogFail
        open={Boolean(failModal)}
        onClose={() => setFailModal(false)}
        onSubmit={confirmFailModal}
        icon={failIcon}
        title={failMessageModal.headline}
        description={failMessageModal.message}
        textYes={t('ok')}
      />
    </Div>
  )
}

export default OrganizationsAuthen
