import { getAPI, postAPI,patchAPI } from '../../utils/api'
import { 
    GET_EVENTS,
    POST_EVENTS,
    UPDATE_EVENTS
} from '../actionTypes'
import { showLoading, hideLoading } from './loading'


export const ApiGetVWUserPointHistory  = (filter = { limit: 10, skip: 0 }) => async (dispatch) => {
    try {
      
      dispatch(showLoading())
      dispatch({ type: GET_EVENTS.REQUEST })
  
      const accessToken = localStorage.getItem('accessToken');
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }
      let params = {
        '$limit': filter.limit,
        '$skip': filter.skip,
      }
      if(filter.name){
        params = {...params,'$or[0][user_no][$like]': `%${filter.name}%`,'$or[1][full_name][$like]': `%${filter.name}%`,'$or[2][activity_name][$like]': `%${filter.name}%`}
      }
      if(filter.activity_name){
        params = {...params,'activity_name[$like]': `%${filter.activity_name}%`}
      }
      if(filter.ref_activity){
        params = {...params,'ref_activity': filter.ref_activity}
      }
      if(filter.effective_date){
        params = {...params,'effective_date': filter.effective_date}
      }
      if(filter.user_no){
        params = {...params,'user_no': filter.user_no}
      }
      if(filter.status_name){
        params = {...params,'status_name': filter.status_name}
      }

      if(filter.sort_data){
        if(filter.sort_data == 'effective_date_asc'){
          params = {...params,'$sort[effective_date]': 1}
        }else if(filter.sort_data == 'effective_date_desc'){
          params = {...params,'$sort[effective_date]': -1}
        }else if(filter.sort_data == 'createdAt_asc'){
          params = {...params,'$sort[createdAt]': 1}
        }else if(filter.sort_data == 'expire_date_asc'){
          params = {...params,'$sort[expire_date]': 1}
        }else if(filter.sort_data == 'expire_date_desc'){
          params = {...params,'$sort[expire_date]': -1}
        }else{
          params = {...params,'$sort[id]': -1}
        }
        
      }else{
        params = {...params,'$sort[id]': -1}
      }
  
      const data_result = await getAPI({
        url: '/api/events/org_vw_cms_user_point_history',
        headers: headers,
        params,
      })
  
      return { type: 'ApiGetVWUserPointHistory_SUCCESS', data_info: data_result }
  
    } catch (err) {
      return { type: 'ApiGetVWUserPointHistory_ERROR' }
  
    } finally {
      dispatch(hideLoading())
    }
}


export const ApiUpdateUserActivityPoint = (id, data) => async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: UPDATE_EVENTS.REQUEST })
      const accessToken = localStorage.getItem('accessToken');
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }
      await patchAPI({
        url: `/api/member/ORGUserActivityPoint/${id}`,
        data,
        headers: headers,
      })
  
      return { type: 'ApiUpdateUserActivityPoint_SUCCESS', data_info: { code : 200 } }
  
    } catch (err) {
  
      return { type: 'ApiUpdateUserActivityPoint_ERROR' }
  
    } finally {
      dispatch(hideLoading())
    }
  }


  export const ApiCMSMemberPointActive = (data) => async (dispatch) => {
    try {
      
      dispatch(showLoading())
      dispatch({ type: POST_EVENTS.REQUEST })
      const accessToken = localStorage.getItem('accessToken');
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }
      
      const data_result = await  postAPI({
        url: `/api/member/OrgMemberPointActive`,
        data,
        headers: headers,
      })
      
      return { type: 'ApiCMSMemberPointActive_SUCCESS', data_info: data_result }
  
    } catch (err) {
      return { type: 'ApiCMSMemberPointActive_ERROR' }
  
    } finally {
      dispatch(hideLoading())
    }
  }


  export const ApiMemberEventProfile = (data) => async (dispatch) => {
    try {
      
      dispatch(showLoading())
      dispatch({ type: POST_EVENTS.REQUEST })
      const accessToken = localStorage.getItem('accessToken');
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }
      
      const data_result = await  postAPI({
        url: `/api/member/OrgMemberEventProfile`,
        data,
        headers: headers,
      })
      
      return { type: 'ApiMemberEventProfile_SUCCESS', data_info: data_result }
  
    } catch (err) {
      return { type: 'ApiMemberEventProfile_ERROR' }
  
    } finally {
      dispatch(hideLoading())
    }
  }

  export const ApiGetVWUserHistoryPointLog = (filter = { limit: 10, skip: 0 }) => async (dispatch) => {
    try {
      
      dispatch(showLoading())
      dispatch({ type: GET_EVENTS.REQUEST })
  
      const accessToken = localStorage.getItem('accessToken');
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }
      let params = {
        '$limit': filter.limit,
        '$skip': filter.skip,
      }
  
  
      if(filter.user_activity_point_id){
        params = {...params,'user_activity_point_id': filter.user_activity_point_id}
      }
  
      const data_result = await getAPI({
        url: '/api/member/org_vw_cms_user_point_history_log?$sort[id]=-1',
        headers: headers,
        params,
      })
  
      return { type: 'ApiGetVWUserHistoryPointLog_SUCCESS', data_info: data_result }
  
    } catch (err) {
      return { type: 'ApiGetVWUserHistoryPointLog_ERROR' }
  
    } finally {
      dispatch(hideLoading())
    }
  }