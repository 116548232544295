import { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import InputMask from "react-input-mask";

import styled from 'styled-components'

import { UserRegister, verifyOtpForLogin, resendOTP,OrgUserAuthen } from '../redux/actions/admin'
import { getTitle } from '../redux/actions/master'

import { validateEmail } from '../utils/validation'
import Modal from '../components/common/Modal'
import OtpInputComponent from '../components/form/OtpInputComponent'
import Field from '../components/form/Field'
import TextField from '../components/form/TextField'
import Dropdown from '../components/form/Dropdown'
import Button from '../components/form/button/Button'
import ButtonSecondary from '../components/form/button/ButtonSecondary'
import DialogSuccess from '../components/dialog/DialogSuccess'
import DialogFail from '../components/dialog/DialogFail'
import DialogConfirmV2 from '../components/dialog/DialogConfirmV2'
import PasswordField from '../components/form/PasswordInput'

import { IoMdClose } from 'react-icons/io'
import otpIcon from '../assets/images/opt-icon.png'
import logoIcon from '../assets/images/logo-icon.png'
import successIcon from '../assets/images/success-icon.png'
import failIcon from '../assets/images/fail-icon.png'
import front_card from '../assets/images/front_card.png'
import back_card from '../assets/images/back_card.png'
import { FiEye, FiEyeOff, FiRefreshCw } from 'react-icons/fi'

const Div = styled.div`
  
  width: 100%;
  /*max-width: 400px;*/
  margin: 0 auto;
  .header{
    height:80px;
    width: 80%;
    margin: 0px auto;
  }
  .content{
    width: 60%;
    margin: 0 auto;
  }

  .box_header{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
  }
  .css_alink{
    cursor: pointer;
    color: var(--Base-Font-Title-Color);
    font-weight: 600;
  }

  .img-container {
    /*padding-top: 30px;*/
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      /*width: 48px;*/
      height: 48px;
    }
  }

  .title {
    font-size: 30px;
    font-weight: 600;
    text-align: center;
    font-style: normal;
    color: var(--Gray-900);
  }

  .desc {
    text-align: center;
    margin-top: 12px;
  }

  .login-form {
    margin-top: 32px;

    .field {
      margin-top: 20px;
    }

    .link-wrapper {
      display: flex;
      justify-content: flex-end;
      margin-top: 24px;

      color: var(--Primary-700);
      font-size: 14px;
      font-style: normal;
      font-weight: 600;

      :hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }

    .button-login {
      display: block;
      width: 100%;
      margin-top: 24px;
    }
  }

  .hightlight {
    color: var(--Primary-700);
    font-weight: 800;
  }

  .copyright {
    color: var(--Gray-500);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    margin-top: 180px;
  }

  @media screen and (max-width: 440px) {
    max-width: 300px;
    .title {
      font-size: 20px;
    }

    .desc {
      font-size: 14px;
    }
  }
  .box_input_1{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    gap: 20px;
    padding-top: 10px;
  }
  .box_img_card{
    
    text-align: center;
    img{
        height: 210px;
    }
  }
  .css_html_success{
    font-size: 14px;
    margin-top: 4px;
    color: var(--Gray-600);
  }
  .footer{
    height : 24px;
  }
`

const OTPContainer = styled.div`
  padding: 24px;

  .mr-12-px {
    margin-right: 12px;
  }
  .mb-20-px {
    margin-bottom: 20px;
  }
  .mb-32-px {
    margin-bottom: 32px;
  }

  .heading {
    color: var(--Gray-900);
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
  }
  p {
    margin: 0;
    color: var(--OTP-FONT);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    text-align: center;
  }
  .css_phone_no{
    color: var(--OTP-FONT-PHONE);
    font-weight: bold;
  }
  .highlight-text {
    text-decoration-line: underline;
    cursor: pointer;
    color: #0086c9;
  }
  .icon {
    cursor: pointer;
  }
  .text-left{
    text-align: left;
  }
`
const FlexContainer = styled.div`
  display: flex;

  &.justify-content-center {
    justify-content: center;
  }
  &.justify-content-end {
    justify-content: flex-end;
  }
  &.justify-content-space-between {
    justify-content: space-between;
  }

  .w-48 {
    width: 48%;
  }
`
const DivErrorMessage = styled.div`
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
`

const DivStyleInputMask = styled.div`
  position: relative;
  display: inline-flex;
  background: var(--Base-White);
  border: 1px solid var(--Gray-300);
  box-shadow: var(--Shadow-xs);
  border-radius: 8px;
  height: 40px;
  input {
    border-radius: 8px;
    padding: 10px;
    width: 100%;
    min-width: 0;
    border: none;
    /*font-family: 'Inter';*/
    font-family: inherit;
    font-size: 14px;
    line-height: 1.5;
    color: var(--Gray-500);
  }

`

const ToggleButton = styled.button`
  position: absolute;
  right: 10px;
  top: 10px;
  border: none;
  background: transparent;
  cursor: pointer;

  &.optional {
    right: 30px;
  }
`

const Register = () => {
  // external hook
  const { t } = useTranslation()
  const lang = localStorage.getItem('lang')
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [title_option, settitle_option] = useState([])
  const [day_birthday_option, setday_birthday_option] = useState([])
  const [month_birthday_option, setmonth_birthday_option] = useState([
    {
        value : "00",
        text : "ไม่ระบุ(-)",
      },
      {
        value : "01",
        text : "มกราคม",
      },
      {
        value : "02",
        text : "กุมภาพันธ์",
      },
      {
        value : "03",
        text : "มีนาคม",
      },
      {
        value : "04",
        text : "เมษายน",
      },
      {
        value : "05",
        text : "พฤษภาคม",
      },
      {
        value : "06",
        text : "มิถุนายน",
      },
      {
        value : "07",
        text : "กรกฎาคม",
      },
      {
        value : "08",
        text : "สิงหาคม",
      },
      {
        value : "09",
        text : "กันยายน",
      },
      {
        value : "10",
        text : "ตุลาคม",
      },
      {
        value : "11",
        text : "พฤศจิกายน",
      },
      {
        value : "12",
        text : "ธันวาคม",
      }
  ])
  const [year_birthday_option, setyear_birthday_option] = useState([])

  useEffect(() => {
    dispatch(getTitle()).then(({ type,data_info }) => {
        if(type.endsWith('_SUCCESS')){
            const newtitle_option = data_info.data.map((v) => ({
                text: v?.name_th_th,
                value: v?.id,
            }))
            settitle_option([...newtitle_option])
        }
        //---- day
        let StDay = 1;
        let daybirth_date = [];
        daybirth_date.push({ value: '00' , text :  'ไม่ระบุ(-)' });
        for (StDay; StDay <= 31; StDay++) {
            let StDay_ID = ("0" + StDay.toString()).slice(-2).toString();
            daybirth_date.push({ value: StDay_ID , text :  StDay });
        }
        setday_birthday_option(daybirth_date);
        //---- year
        let totalYears = 100;
        let years = [];
        let currentYear = new Date().getFullYear();

        for (totalYears; totalYears > 0; totalYears--) {
            let year = currentYear--;
            years.push({ value: year , text :  year + 543 });
        }
        setyear_birthday_option(years);
    })
  }, [dispatch])

  // state input about log in


  const [RegisterData, setRegisterData] = useState({
    titleNameId: '',
    firstName: null,
    lastName: null, 
    day_birthday: '',
    month_birthday: '',
    year_birthday: '',
    phone_no: null,
    email: null,
    idCardNo: null,
    laser: null,
  })

  const [RegisterDataError, setRegisterDataError] = useState({
    titleNameId: {
      show_errorMessage: false,
      errorMessage: 'กรุณาระบุคำนำหน้า',
    },
    firstName: {
      show_errorMessage: false,
      errorMessage: 'กรุณาระบุชื่อ',
    },
    lastName: {
      show_errorMessage: false,
      errorMessage: 'กรุณาระบุนามสกุล',
    },
    day_birthday: {
      show_errorMessage: false,
      errorMessage: 'กรุณาระบุวันเกิด',
    },
    month_birthday: {
      show_errorMessage: false,
      errorMessage: 'กรุณาระบุเดือนเกิด',
    },
    year_birthday: {
      show_errorMessage: false,
      errorMessage: 'กรุณาระบุปีเกิด',
    },
    phone_no: {
      show_errorMessage: false,
      errorMessage: 'กรุณาระบุเบอร์โทรศัพท์',
    },
    email: {
      show_errorMessage: false,
      errorMessage: 'กรุณาระบุEmail',
    },
    idCardNo: {
      show_errorMessage: false,
      errorMessage: 'กรุณาระบุเลขบัตรประจำตัวประชาชน',
    },
    laser: {
      show_errorMessage: false,
      errorMessage: 'กรุณาระบุรหัสหลังบัตร',
    },
  })


  const [IdCardNoDisplay, setIdCardNoDisplay] = useState(null);
  const [idCardNoShown, setidCardNoShown] = useState(false);
  const toggleidCardNoVisibility = () => {
    setidCardNoShown(!idCardNoShown)
  }
  const onChangeIdCardNo = (inp_value) => {
    console.log(inp_value);
    const arr_inp_value = inp_value.split('-');
    let text_value_1 = '';
    let text_value_2 = '';
    if(arr_inp_value.length > 2){

      const arr_text_value_1 = arr_inp_value[1];
      for(let i = 0; i < arr_text_value_1.length; i++) {
        if(arr_text_value_1[i] != '_'){
          text_value_1 = text_value_1 + 'x';
        }else{
          text_value_1 = text_value_1 + arr_text_value_1[i];
        }
      }

      const arr_text_value_2 = arr_inp_value[2];
      for(let i_2 = 0; i_2 < arr_text_value_2.length; i_2++) {
        if(arr_text_value_2[i_2] != '_'){
          text_value_2 = text_value_2 + 'x';
        }else{
          text_value_2 = text_value_2 + arr_text_value_2[i_2];
        }
      }
      const NewValue = arr_inp_value[0] + '-' + text_value_1 + '-' + text_value_2 + '-' + arr_inp_value[3] + '-' + arr_inp_value[4];
      setIdCardNoDisplay(NewValue);
      setRegisterData({ ...RegisterData, idCardNo: inp_value })
    }

  }


  // success,fail modal
  const initMessageModal = {
    headline: '',
    message: '',
  }
  const [ModalConfirmDialog, setModalConfirmDialog] = useState(false)
  const [ConfirmMessageModal, setConfirmMessageModal] = useState(initMessageModal)

  const [successModal, setSuccessModal] = useState(false)
  const [failModal, setFailModal] = useState(false)
  const [failModal2, setFailModal2] = useState(false)
  const [successMessageModal, setSuccessMessageModal] = useState(initMessageModal)
  const [failMessageModal, setFailMessageModal] = useState(initMessageModal)
  const [failMessageModal2, setFailMessageModal2] = useState(initMessageModal)

  const [loginResponse, setLoginResponse] = useState()
  const [fullOtp, setFullOtp] = useState([])
  const initialTimeCounterForResendOtp = 60
  const initialTimeCounterForOtpModal = {
    minutes: 5,
    seconds: 0,
  }
  const [otpModal, setOtpModal] = useState(false)
  const [minutes, setMinutes] = useState(initialTimeCounterForOtpModal.minutes)
  const [seconds, setSeconds] = useState(initialTimeCounterForOtpModal.seconds)

  const openOtpModal = () => {
    setOtpModal(true)
  }
  const closeOtpModal = () => {
    setOtpModal(false)
  }

  useEffect(() => {
    let countdown

    if (otpModal) {
      countdown = setInterval(() => {
        if (minutes === 0 && seconds === 0) {
          setOtpModal(false)
          clearInterval(countdown)
        } else {
          if (seconds === 0) {
            setMinutes(minutes - 1)
            setSeconds(59)
          } else {
            setSeconds(seconds - 1)
          }
        }
      }, 1000)
    } else {
      setTimeout(() => {
        setMinutes(initialTimeCounterForOtpModal.minutes)
        setSeconds(initialTimeCounterForOtpModal.seconds)

        setIsCountingDown(false)
        setTimeCounter(initialTimeCounterForResendOtp)
      }, 1000)
    }

    return () => {
      clearInterval(countdown)
    }
  }, [otpModal, minutes, seconds])

  // time counter for resending otp
  const [timeCounter, setTimeCounter] = useState(initialTimeCounterForResendOtp)
  const [isCountingDown, setIsCountingDown] = useState(false)

  useEffect(() => {
    let timerId
    if (isCountingDown && timeCounter > 0) {
      timerId = setTimeout(() => setTimeCounter((c) => c - 1), 1000)
    } else if (timeCounter <= 0) {
      setIsCountingDown(false)
    }

    return () => clearTimeout(timerId)
  }, [timeCounter, isCountingDown])

  const [targetTime, setTargetTime] = useState(null)
  const [timeDifference, setTimeDifference] = useState('')

  useEffect(() => {
    if (targetTime) {
      const updateTimeDifference = () => {
        const now = new Date()
        const target = new Date(targetTime)
        const difference = target - now
        const minutes = Math.floor((difference / 1000 / 60) % 60)
        const seconds = Math.floor((difference / 1000) % 60)
        const formattedMinutes = minutes.toString().padStart(2, '0')
        const formattedSeconds = seconds.toString().padStart(2, '0')
        setTimeDifference(`${formattedMinutes}:${formattedSeconds}`)
      }
      updateTimeDifference()
      const intervalId = setInterval(updateTimeDifference, 1000)
      return () => clearInterval(intervalId)
    }
  }, [targetTime])

  useEffect(() => {
    if (timeDifference === '00:00') {
      setTargetTime(null)
      setFailModal2(false)
    }
  }, [timeDifference])


  const resendOtp = () => {
    const request = {
      user_name: loginResponse.user_name,
      loglogin_id: loginResponse.loglogin_id,
    }

    dispatch(resendOTP(request)).then((res) => {
      const { response, type } = res
      if (type === 'RESEND_OTP_SUCCESS') {
        setLoginResponse((prev) => ({
          ...prev,
          otp_id: response.otp_id,
          refno: response.ref_code,
        }))
      }
    })
    setTimeCounter(initialTimeCounterForResendOtp)
    setIsCountingDown(true)
  }

  const confirmFailModal = async () => {
    setFailModal(false)
  }
  const confirmFailModal2 = async () => {
    setTargetTime(null)
    setFailModal2(false)
  }

  const isValid = () => {
    let data_valid = RegisterData
    let data_check_valid = RegisterDataError
    let errors = []
    Object.keys(RegisterDataError).forEach((k) => {
      let data = data_valid[k]
      if (data_check_valid[k].errorMessage != '') {
        if (!data || data < 0) {
          errors.push(data_check_valid[k].errorMessage)
          data_check_valid[k].show_errorMessage = true
          if (k == 'email') {
            data_check_valid[k].errorMessage = 'กรุณาระบุEmail'
          }else if(k == 'phone_no'){
            data_check_valid[k].errorMessage = 'กรุณากรอกเบอร์โทรศัพท์'
          }else if(k == 'idCardNo'){
            data_check_valid[k].errorMessage = 'กรุณากรอกเลขบัตรประจำตัวประชาชน'
          }else if(k == 'laser'){
            data_check_valid[k].errorMessage = 'กรุณาระบุรหัสหลังบัตร'
          }
        } else {
          if (k == 'email') {
            //--- valid email
            if (!validateEmail(data)) {
              errors.push(data_check_valid[k].errorMessage)
              data_check_valid[k].errorMessage = 'Email ไม่ถูกต้อง'
              data_check_valid[k].show_errorMessage = true
            } else {
              data_check_valid[k].show_errorMessage = false
            }
          }else if(k == 'phone_no'){
            const real_PhoneNo = data.replaceAll(')', '').replaceAll('(', '').replaceAll('-', '').replaceAll('_', '');
            if(real_PhoneNo.length != 10){
              errors.push(data_check_valid[k].errorMessage)
              data_check_valid[k].errorMessage = 'กรุณากรอกให้ครบ 10 หลัก'
              data_check_valid[k].show_errorMessage = true
            }else{
              data_check_valid[k].show_errorMessage = false
            }
          }else if(k == 'idCardNo'){
            const real_UserName = data.replaceAll('-', '').replaceAll('_', '');
            if(real_UserName.length != 13){
              errors.push(data_check_valid[k].errorMessage)
              data_check_valid[k].errorMessage = 'กรุณากรอกให้ครบ 13 หลัก'
              data_check_valid[k].show_errorMessage = true
            }else{
              data_check_valid[k].show_errorMessage = false
            }
          }else if(k == 'laser'){
            const real_Laser = data.replaceAll('-', '').replaceAll('_', '');
            if(real_Laser.length != 12){
              errors.push(data_check_valid[k].errorMessage)
              data_check_valid[k].errorMessage = 'กรุณากรอกให้ครบ 12 หลัก'
              data_check_valid[k].show_errorMessage = true
            }else{
              data_check_valid[k].show_errorMessage = false
            }
          } else {
            data_check_valid[k].show_errorMessage = false
          }
        }
      }
    })
    setRegisterDataError({ ...RegisterDataError })
    return errors
  }

  // submit function
  const onConfirmRegister = async (e) => {
    e.preventDefault()
    if (isValid().length == 0) {
      setConfirmMessageModal({
          headline: 'ยืนยันการสร้างบัญชีผู้ใช้ใหม่',
          message: RegisterData.firstName + ' ' + RegisterData.lastName,
          birthday: RegisterData.day_birthday + '/' + RegisterData.month_birthday + '/' + (parseInt(RegisterData.year_birthday) + 543),
          idCardNo: RegisterData.idCardNo,
          email: RegisterData.email,
          phone_no: RegisterData.phone_no,
      })
      setModalConfirmDialog(true);
    }
    
  };
  const handleRegister = (e) => {
    e.preventDefault()
    setModalConfirmDialog(false);
    const request = {
      titleNameId: RegisterData.titleNameId,
      firstName:RegisterData.firstName,
      lastName:RegisterData.lastName,
      idCardNo:RegisterData.idCardNo,
      birthday: RegisterData.year_birthday + '-' + RegisterData.month_birthday + '-' + RegisterData.day_birthday,
      laser:RegisterData.laser,
      phone_no : RegisterData.phone_no,
      email : RegisterData.email,
      system : "user_register",
      client_ip:"192.168.0.1",
      client_ip_country:"Thailand"
    }
    dispatch(UserRegister(request)).then(({ type,data_info }) => {
      
      if(type.endsWith('_SUCCESS')){
        if(data_info.code == 200){
            const data_login_user = {
              user_name : RegisterData.idCardNo,
              phone_no : data_info.phone_no ,
              refno : data_info.ref_code,
              code: data_info.code,
              message: data_info.message,
              loglogin_id: data_info.loglogin_id,
              otp_id: data_info.otp_id,
              organizations_user_account_id: data_info.organizations_user_account_id,
            }
            //alert(JSON.stringify(data_login_user))
            setLoginResponse(data_login_user);
            openOtpModal();
        }else{
          
            setFailMessageModal({
                headline: data_info.message,
                message: 'กรุณาลองใหม่อีกครั้ง',
            })
            setFailModal(true)
          
        }
      }else{
        setFailMessageModal({
          headline: 'internal server error',
          message: '',
        })
        setFailModal(true)
      }
      
    })
  }

  const verifyOtp = () => {
    if (fullOtp.length === 6) {
      const request = {
        strategy: "user-local",
        user_name:loginResponse.user_name,
        otpcode : fullOtp.join('') + "|" + loginResponse.otp_id + "|register|" + loginResponse.loglogin_id
      }
      dispatch(verifyOtpForLogin(request)).then(({ type,data_info }) => {
        if(type.endsWith('_SUCCESS')){
          if(data_info.accessToken){
            /*
            localStorage.setItem('UserToken', data_info.accessToken)
            localStorage.setItem('loglogin_id', loginResponse.loglogin_id)
            navigate('/OrganizationsAuthen')
            */
            localStorage.setItem('UserToken', data_info.accessToken)
            localStorage.setItem('loglogin_id', loginResponse.loglogin_id)
            if(loginResponse.organizations_user_account_id){
              const request = {
                strategy: "org_user-local",
                organizations_user_account_id:loginResponse.organizations_user_account_id,
                loglogin_id : loginResponse.loglogin_id    
              }
              dispatch(OrgUserAuthen(request)).then(({ type,data_info }) => {
                
                if(type.endsWith('_SUCCESS')){
                  if(data_info.accessToken){
                      //alert(JSON.stringify(data_info.organizations_user_authen))
                      localStorage.setItem('accessToken', data_info.accessToken);
                      localStorage.setItem('user_info', JSON.stringify(data_info.organizations_user_authen ));
                      window.location.href = '/'
                  }else{
                    
                      setFailMessageModal({
                          headline: 'internal server error',
                          message: '',
                      })
                      setFailModal(true)
                    
                  }
                }else{
                  setFailMessageModal({
                    headline: 'internal server error',
                    message: '',
                  })
                  setFailModal(true)
                }
                
              })
            }else{
              navigate('/OrganizationsRequest')
            }
          }else{
            setFailMessageModal({
              headline: 'รหัส OTP ไม่ถูกต้อง',
              message: 'กรุณาลองใหม่อีกครั้ง',
            })
            setFailModal(true)
          }
        }else{
          if(data_info.message == 'BlockMember'){
            setOtpModal(false);

            setTargetTime(data_info.errors.block_expire_date);

            setFailMessageModal2({
              headline: 'ทำรายการผิดเกิน 5 ครั้ง',
              message: '',
            })
            setFailModal2(true)

          }else{
            setFailMessageModal({
              headline: 'รหัส OTP ไม่ถูกต้อง',
              message: 'กรุณาลองใหม่อีกครั้ง',
            })
            setFailModal(true)
          }
        }
      })
    }
  }


  // other function
  const gotoRegister = () => {
    alert('gotoRegister');
    //navigate('/forget-password')
  }
  const gotoLogin = () => {
    navigate('/login')
  }
  const formattedTime = (time) => {
    return `${String(time).padStart(2, '0')}`
  }

  return (
    <Div>
      <div className="header">
        <div className="box_header">
          <div>
            <div className="img-container">
              <img src={logoIcon} alt="loco icon" />
            </div>
          </div>
          <div>
            สร้างบัญชีผู้ใช้แล้ว? <span className="css_alink" onClick={gotoLogin}>เข้าสู่ระบบ</span>
          </div>
        </div>
      </div>
      <div className="content">

      
        <form>
            <div className="title">ลงทะเบียนผู้ใช้ใหม่และยืนยันตัวตน</div>
            <div className="desc">
            กรุณากรอกข้อมูลผู้ประสานงานขององค์กรแม่ข่ายที่ประสงค์จะลงทะเบียนใช้งาน
            </div>

            <div className="login-form">
                <div className="box_input_1">
                    <div>
                        <Field className="field" label="คำนำหน้า*">
                            <Dropdown
                                id={`dd_title_option`}
                                value={RegisterData.titleNameId}
                                optionList={title_option}
                                onChange={(v) => setRegisterData({ ...RegisterData, titleNameId: v })}
                                placeHolder={'เลือกคำนำหน้า'}
                                error={Boolean(RegisterDataError.titleNameId.show_errorMessage)}
                            />
                            {RegisterDataError.titleNameId.show_errorMessage == true && (
                              <DivErrorMessage>{RegisterDataError.titleNameId.errorMessage}</DivErrorMessage>
                            )}
                        </Field>
                    </div>
                    <div>
                        <Field className="field" label="ชื่อ*">
                            <TextField
                            placeholder="ระบุชื่อ"
                            value={RegisterData.firstName}
                            onChange={(e) => setRegisterData({ ...RegisterData, firstName: e.target.value })}
                            error={Boolean(RegisterDataError.firstName.show_errorMessage)}
                            />
                            {RegisterDataError.firstName.show_errorMessage == true && (
                              <DivErrorMessage>{RegisterDataError.firstName.errorMessage}</DivErrorMessage>
                            )}
                        </Field>
                    </div>
                    <div>
                        <Field className="field" label="นามสกุล*">
                            <TextField
                            placeholder="ระบุนามสกุล"
                            value={RegisterData.lastName}
                            onChange={(e) => setRegisterData({ ...RegisterData, lastName: e.target.value })}
                            error={Boolean(RegisterDataError.lastName.show_errorMessage)}
                            />
                            {RegisterDataError.lastName.show_errorMessage == true && (
                              <DivErrorMessage>{RegisterDataError.lastName.errorMessage}</DivErrorMessage>
                            )}
                        </Field>
                    </div>
                </div>
                <div className="box_input_1">
                    <div>
                        <Field className="field" label="วันเกิด*">
                            <Dropdown
                                id={`dd_day_birthday_option`}
                                value={RegisterData.day_birthday}
                                optionList={day_birthday_option}
                                onChange={(v) => setRegisterData({ ...RegisterData, day_birthday: v })}
                                placeHolder={'เลือกวันเกิด'}
                                error={Boolean(RegisterDataError.day_birthday.show_errorMessage)}
                            />
                            {RegisterDataError.day_birthday.show_errorMessage == true && (
                              <DivErrorMessage>{RegisterDataError.day_birthday.errorMessage}</DivErrorMessage>
                            )}
                        </Field>
                    </div>
                    <div>
                        <Field className="field" label="เดือนเกิด*">
                            <Dropdown
                                id={`dd_month_birthday_option`}
                                value={RegisterData.month_birthday}
                                optionList={month_birthday_option}
                                onChange={(v) => setRegisterData({ ...RegisterData, month_birthday: v })}
                                placeHolder={'เลือกเดือนเกิด'}
                                error={Boolean(RegisterDataError.month_birthday.show_errorMessage)}
                            />
                            {RegisterDataError.month_birthday.show_errorMessage == true && (
                              <DivErrorMessage>{RegisterDataError.month_birthday.errorMessage}</DivErrorMessage>
                            )}
                        </Field>
                    </div>
                    <div>
                        <Field className="field" label="ปีเกิด*">
                            <Dropdown
                                id={`dd_year_birthday_option`}
                                value={RegisterData.year_birthday}
                                optionList={year_birthday_option}
                                onChange={(v) => setRegisterData({ ...RegisterData, year_birthday: v })}
                                placeHolder={'เลือกปีเกิด'}
                                error={Boolean(RegisterDataError.year_birthday.show_errorMessage)}
                            />
                            {RegisterDataError.year_birthday.show_errorMessage == true && (
                              <DivErrorMessage>{RegisterDataError.year_birthday.errorMessage}</DivErrorMessage>
                            )}
                        </Field>
                    </div>
                </div>
                <div className="box_input_1">
                    <div>
                        <Field className="field" label="เบอร์โทรศัพท์ (เพื่อติดต่อและรับ OTP)*">
                            <TextField
                            placeholder="ระบุเบอร์โทรศัพท์"
                            value={RegisterData.phone_no}
                            onChange={(e) => setRegisterData({ ...RegisterData, phone_no: e.target.value })}
                            error={Boolean(RegisterDataError.phone_no.show_errorMessage)}
                            />
                            {RegisterDataError.phone_no.show_errorMessage == true && (
                              <DivErrorMessage>{RegisterDataError.phone_no.errorMessage}</DivErrorMessage>
                            )}
                        </Field>
                    </div>
                    <div>
                        <Field className="field" label="Email (เพื่อติดต่อและรับ OTP)*">
                            <TextField
                            placeholder="ระบุEmail"
                            value={RegisterData.email}
                            onChange={(e) => setRegisterData({ ...RegisterData, email: e.target.value })}
                            error={Boolean(RegisterDataError.email.show_errorMessage)}
                            />
                            {RegisterDataError.email.show_errorMessage == true && (
                              <DivErrorMessage>{RegisterDataError.email.errorMessage}</DivErrorMessage>
                            )}
                        </Field>
                    </div>
                </div>
                <div className="box_input_1">
                    <div>
                        <Field className="field" label="เลขบัตรประจำตัวประชาชน*">
                            {
                              /*
                              <TextField
                                placeholder="ระบุเลขบัตรประจำตัวประชาชน"
                                value={RegisterData.idCardNo}
                                onChange={(e) => setRegisterData({ ...RegisterData, idCardNo: e.target.value })}
                                error={Boolean(RegisterDataError.idCardNo.show_errorMessage)}
                              />
                              */
                            }
                            {
                              /*
                              <DivStyleInputMask>
                                <InputMask 
                                  placeholder="ระบุเลขบัตรประจำตัวประชาชน 13 หลัก"
                                  mask="9-9999-99999-99-9" 
                                  onChange={(e) => onChangeIdCardNo(e.target.value)}
                                  value={RegisterData.idCardNo} 
                                />
                                <ToggleButton type="button" onClick={toggleidCardNoVisibility}>
                                  {idCardNoShown ? <FiEyeOff /> : <FiEye />}
                                </ToggleButton>
                              </DivStyleInputMask>
                              */
                            }
                            <PasswordField 
                              value={RegisterData.idCardNo} 
                              onChange={(e) => setRegisterData({ ...RegisterData, idCardNo: e.target.value })}
                              error={Boolean(RegisterDataError.idCardNo.show_errorMessage)}
                            />






                            {RegisterDataError.idCardNo.show_errorMessage == true && (
                              <DivErrorMessage>{RegisterDataError.idCardNo.errorMessage}</DivErrorMessage>
                            )}
                        </Field>
                    </div>
                    <div>
                        <Field className="field" label="รหัสหลังบัตร*">
                            {
                              /*
                              <TextField
                                placeholder="ระบุรหัสหลังบัตร"
                                value={RegisterData.laser}
                                onChange={(e) => setRegisterData({ ...RegisterData, laser: e.target.value })}
                                error={Boolean(RegisterDataError.laser.show_errorMessage)}
                              />
                              */
                            }
                            

                            <PasswordField 
                              value={RegisterData.laser} 
                              onChange={(e) => setRegisterData({ ...RegisterData, laser: e.target.value })} 
                              error={Boolean(RegisterDataError.laser.show_errorMessage)}
                            />
                            {RegisterDataError.laser.show_errorMessage == true && (
                              <DivErrorMessage>{RegisterDataError.laser.errorMessage}</DivErrorMessage>
                            )}
                        </Field>
                    </div>
                </div>
                <div className="box_input_1">
                  <div>
                  หมายเลขบัตรประจำตัวประชาชน และหมายเลขรหัสหลังบัตร ใช้สำหรับยืนยันตัวตนทางดิจิทัลกับกรมการปกครอง กระทรวงมหาดไทย เท่านั้น       
                  </div>               
                </div>
                <div className="box_input_1">
                    <div className="box_img_card" >
                        <img src={front_card} />
                    </div>
                    <div className="box_img_card" >
                        <img  src={back_card} />
                    </div>
                </div>
                

                <Button
                    className="button-login"
                    type="submit"
                    onClick={onConfirmRegister}
                >
                    ตรวจสอบข้อมูล
                </Button>
            </div>

        </form>
      </div>
      <div className="footer"></div>

      {/* Modal */}
      <Modal open={otpModal} disableBackdropClick>
        <OTPContainer>
          <FlexContainer className="justify-content-end">
            <IoMdClose size={20} className="icon" onClick={closeOtpModal} />
          </FlexContainer>
          <FlexContainer className="justify-content-center">
            <img src={otpIcon} alt="otp icon" />
          </FlexContainer>
          <h2 className="heading">กรุณากรอกรหัส OTP</h2>
          <p>
            รหัส OTP ได้ถูกส่งไปที่ <span className="css_phone_no">{loginResponse?.phone_no}</span>
          </p>
         
          <p className="mb-20-px">
            เหลือเวลาทำรายการ {formattedTime(minutes)}:{formattedTime(seconds)} นาที
          </p>

          <OtpInputComponent setFullOtp={setFullOtp} />

          <p className="text-left">(Ref: {loginResponse?.refno})</p>
          <p className="text-left">
           ไม่ได้รับรหัส?{' '}
            {isCountingDown ? (
              <span>ขออีกครั้งใน {timeCounter} วินาที</span>
            ) : (
              <span className="highlight-text" onClick={resendOtp}>
                ขอรหัส OTP อีกครั้ง
              </span>
            )}
          </p>
          <p className="text-left mb-32-px">(กรอกรหัสผิดติดต่อกัน 5 ครั้ง ระบบจะหยุดพัก 60นาที)</p>

          <FlexContainer className="justify-content-space-between">
            <ButtonSecondary className="w-48" onClick={closeOtpModal}>
              ยกเลิก
            </ButtonSecondary>
            <Button className="w-48" onClick={verifyOtp}>
              ตรวจสอบ
            </Button>
          </FlexContainer>
        </OTPContainer>
      </Modal>

      {/* Dialog */}
      <DialogConfirmV2
        open={Boolean(ModalConfirmDialog)}
        onClose={() => setModalConfirmDialog(false)}
        onNo={() => setModalConfirmDialog(false)}
        onSubmit={handleRegister}
        icon={successIcon}
        title={ConfirmMessageModal.headline}
        nameItem={ConfirmMessageModal.message}
        html={
          <div>
            <div>{ConfirmMessageModal.email}</div>
            <div>{ConfirmMessageModal.phone_no}</div>
            <div>{'โปรดตรวจสอบข้อมูลก่อนกดยืนยัน'}</div>
          </div>
        }
        textYes='ยืนยัน'
        textNo='ยกเลิก'
      />

      <DialogSuccess
        open={Boolean(successModal)}
        onClose={() => setSuccessModal(false)}
        onSubmit={handleRegister}
        icon={successIcon}
        title={successMessageModal.headline}
        description={successMessageModal.message}
        textYes={t('done')}
      />
      <DialogFail
        open={Boolean(failModal)}
        onClose={() => setFailModal(false)}
        onSubmit={confirmFailModal}
        icon={failIcon}
        title={failMessageModal.headline}
        description={failMessageModal.message}
        textYes={t('ok')}
      />

      <DialogFail
        open={Boolean(failModal2)}
        onClose={() => {
          setTargetTime(null)
          setFailModal2(false)
        }}
        onSubmit={confirmFailModal2}
        icon={failIcon}
        title={failMessageModal2.headline}
        html={
          <div>
            สามารถทำรายการใหม่ได้อีก <span className="css_text_focus">{timeDifference}</span> นาที
          </div>
        }
        textYes={'ตกลง'}
      />
    </Div>
  )
}

export default Register
