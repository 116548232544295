import { FiUsers, FiBarChart,FiActivity } from 'react-icons/fi'
import { AiOutlineApartment } from "react-icons/ai";
import { FaRegStar } from "react-icons/fa";
import { TbUserUp } from "react-icons/tb";

export const subMenuHome = [
  {
    text: 'Home',
    path: '/',
    subject: null,
  },
]

export const subMenuAccountManage = [
  {
    icon: <FiUsers />,
    text: 'จัดการบัญชีผู้ใช้',
    path: '/AccountManage/OrgUserAccount',
    subject: null, 
  },
  {
    icon: <AiOutlineApartment />,
    text: 'ข้อมูลแม่ข่าย',
    path: '/AccountManage/OrgProfile',
    subject: null, 
  },
]

export const subMenuActivity = [
  {
    class_css: <FiActivity />,
    name: 'จัดการกฏเกณฑ์การได้คะแนน',
    link_to: '/CpdManage/OrgCondition',
    subject: null,
  },
  {
    icon: <AiOutlineApartment />,
    text: 'กิจกรรม CPD',
    path: '/Activity/OrgActivity',
    subject: null, 
  },
  {
    icon: <FaRegStar />,
    text: 'บทความ E-learning',
    path: '/Activity/Elearning',
    subject: null, 
  },
  {
    class_css: <FaRegStar />,
    name: 'ตรวจสอบคะแนน',
    link_to: '/Activity/ActivityMember',
    subject: null,
  },
  /*
  {
    icon: <TbUserUp />,
    text: 'ต่ออายุองค์กรแม่ข่าย',
    path: '/Activity/Extend',
    subject: null, 
  },
  */
  {
    icon: <FiBarChart />,
    text: 'รายงานสถิติ',
    path: '/Activity/Report',
    subject: null, 
  },
]