import { getAPI, postAPI,patchAPI } from '../../utils/api'
import { 
    organization_profile,
    GET_organization,
    UPDATE_organization
} from '../actionTypes'
import { showLoading, hideLoading } from './loading'

export const GET_organization_profile = (data) => async (dispatch) => {
    try {
      
      dispatch(showLoading())
      dispatch({ type: organization_profile.REQUEST })
      const accessToken = localStorage.getItem('accessToken');
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }
      
      const data_result = await  postAPI({
        url: `/api/org/organization_profile`,
        data,
        headers: headers,
      })
      
      return { type: 'GET_organization_profile_SUCCESS', data_info: data_result }

    } catch (err) {
      return { type: 'GET_organization_profile_ERROR' }

    } finally {
      dispatch(hideLoading())
    }
}

  export const getOrganizations = (data) => async (dispatch) => {
    try {
      
      dispatch(showLoading())
      dispatch({ type: GET_organization.REQUEST })

      const UserToken = localStorage.getItem('UserToken');
      const headers = {
        Authorization: `Bearer ${UserToken}`,
        'Content-Type': 'application/json',
      }
      
      const data_result = await  postAPI({
        url: `/api/org/getOrganizations`,
        data,
        headers: headers,
      })

      return { type: 'getOrganizations_SUCCESS', data_info: data_result }

    } catch (err) {
      return { type: 'getOrganizations_ERROR' }

    } finally {
      dispatch(hideLoading())
    }
  }

  export const RequestApproveOrg = (data) => async (dispatch) => {
    try {
      
      dispatch(showLoading())
      dispatch({ type: organization_profile.REQUEST })
      const UserToken = localStorage.getItem('UserToken');
      const headers = {
        Authorization: `Bearer ${UserToken}`,
        'Content-Type': 'application/json',
      }
      
      const data_result = await  postAPI({
        url: `/api/org/RequestApprove`,
        data,
        headers: headers,
      })
      
      return { type: 'RequestApproveOrg_SUCCESS', data_info: data_result }

    } catch (err) {
      return { type: 'RequestApproveOrg_ERROR' }

    } finally {
      dispatch(hideLoading())
    }
  }

  export const OrgRegister = (data) => async (dispatch) => {
    try {
      
      dispatch(showLoading())
      dispatch({ type: organization_profile.REQUEST })
      const UserToken = localStorage.getItem('UserToken');
      const headers = {
        Authorization: `Bearer ${UserToken}`,
        'Content-Type': 'application/json',
      }
      
      const data_result = await  postAPI({
        url: `/api/org/Register`,
        data,
        headers: headers,
      })
      
      return { type: 'OrgRegister_SUCCESS', data_info: data_result }

    } catch (err) {
      return { type: 'OrgRegister_ERROR' }

    } finally {
      dispatch(hideLoading())
    }
  }

  export const getOrgUserAccount = (filter = { limit: 10, skip: 0 }) => async (dispatch) => {
    try {
      
      dispatch(showLoading())
      dispatch({ type: GET_organization.REQUEST })

      const accessToken = localStorage.getItem('accessToken');
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }
      let params = {
        '$limit': filter.limit,
        '$skip': filter.skip,
      }

      if(filter.name){
        params = {...params,'$or[0][full_name][$like]': `%${filter.name}%`,'$or[1][email][$like]': `%${filter.name}%`}
      }
      if(filter.status_id){
        params = {...params,'status_id': filter.status_id}
      }
  
      const data_result = await getAPI({
        url: '/api/org/getUserAccount?$sort[id]=1',
        headers: headers,
        params,
      })

      return { type: 'getOrgUserAccount_SUCCESS', data_info: data_result }

    } catch (err) {
      return { type: 'getOrgUserAccount_ERROR' }

    } finally {
      dispatch(hideLoading())
    }
  }

  export const ApiApproveRequest = (data) => async (dispatch) => {
    try {
      
      dispatch(showLoading())
      dispatch({ type: organization_profile.REQUEST })
      const accessToken = localStorage.getItem('accessToken');
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }
      
      const data_result = await  postAPI({
        url: `/api/org/ApproveRequest`,
        data,
        headers: headers,
      })
      
      return { type: 'ApiApproveRequest_SUCCESS', data_info: data_result }

    } catch (err) {
      return { type: 'ApiApproveRequest_ERROR' }

    } finally {
      dispatch(hideLoading())
    }
  }

  export const ApiEditUserAccount = (data) => async (dispatch) => {
    try {
      
      dispatch(showLoading())
      dispatch({ type: organization_profile.REQUEST })
      const accessToken = localStorage.getItem('accessToken');
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }
      
      const data_result = await  postAPI({
        url: `/api/org/EditUser`,
        data,
        headers: headers,
      })
      
      return { type: 'ApiEditUserAccount_SUCCESS', data_info: data_result }

    } catch (err) {
      return { type: 'ApiEditUserAccount_ERROR' }

    } finally {
      dispatch(hideLoading())
    }
  }
  export const ApiNewUserAccount = (data) => async (dispatch) => {
    try {
      
      dispatch(showLoading())
      dispatch({ type: organization_profile.REQUEST })
      const accessToken = localStorage.getItem('accessToken');
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }
      
      const data_result = await  postAPI({
        url: `/api/org/NewUser`,
        data,
        headers: headers,
      })
      
      return { type: 'ApiNewUserAccount_SUCCESS', data_info: data_result }

    } catch (err) {
      return { type: 'ApiNewUserAccount_ERROR' }

    } finally {
      dispatch(hideLoading())
    }
  }

  export const ApiUpdateOrg = (id, data) => async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: UPDATE_organization.REQUEST })
      const accessToken = localStorage.getItem('accessToken');
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }
      await patchAPI({
        url: `/api/org/organization_profile/${id}`,
        data,
        headers: headers,
      })
  
      return { type: 'ApiUpdateOrg_SUCCESS', data_info: { code : 200 } }

    } catch (err) {

      return { type: 'GApiUpdateOrg_ERROR' }

    } finally {
      dispatch(hideLoading())
    }
  }

  export const Apigenerate_cpd_pdf = (data) => async (dispatch) => {
    try {
      
      dispatch(showLoading())
      dispatch({ type: organization_profile.REQUEST })
      const accessToken = localStorage.getItem('accessToken');
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }
      
      const data_result = await  postAPI({
        url: `/api/org/generate_cpd_pdf`,
        data,
        headers: headers,
      })
      
      return { type: 'Apigenerate_cpd_pdf_SUCCESS', data_info: data_result }

    } catch (err) {
      return { type: 'Apigenerate_cpd_pdf_ERROR' }

    } finally {
      dispatch(hideLoading())
    }
  }

  export const ApiUserCanCleApproveRequest = (data) => async (dispatch) => {
    try {
      
      dispatch(showLoading())
      dispatch({ type: organization_profile.REQUEST })
      const UserToken = localStorage.getItem('UserToken');
      const headers = {
        Authorization: `Bearer ${UserToken}`,
        'Content-Type': 'application/json',
      }
      
      const data_result = await  postAPI({
        url: `/api/org/UserCancleRequest`,
        data,
        headers: headers,
      })
      
      return { type: 'ApiUserCanCleApproveRequest_SUCCESS', data_info: data_result }

    } catch (err) {
      return { type: 'ApiUserCanCleApproveRequest_ERROR' }

    } finally {
      dispatch(hideLoading())
    }
  }

  export const ApiGetRequestORG = (request_org_id) => async (dispatch) => {
    try {
      
      dispatch(showLoading())
      dispatch({ type: GET_organization.REQUEST })

      const accessToken = localStorage.getItem('UserToken');
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }

  
      const data_result = await getAPI({
        url: '/api/org/request_org_profile/' + request_org_id,
        headers: headers,
      })

      return { type: 'ApiGetRequestORG_SUCCESS', data_info: data_result }

    } catch (err) {
      return { type: 'ApiGetRequestORG_ERROR' }

    } finally {
      dispatch(hideLoading())
    }
  }

  export const ApiUpdateRequestORG = (id, data) => async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: UPDATE_organization.REQUEST })
      const accessToken = localStorage.getItem('UserToken');
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }
      const data_result = await patchAPI({
        url: `/api/org/request_org_profile/${id}`,
        data,
        headers: headers,
      })
  
      return { type: 'ApiUpdateRequestORG_SUCCESS', data_info: data_result }

    } catch (err) {

      return { type: 'ApiUpdateRequestORG_ERROR' }

    } finally {
      dispatch(hideLoading())
    }
  }

  export const ApiAddRequestORGActivity = (data) => async (dispatch) => {
    try {
      
      dispatch(showLoading())
      dispatch({ type: organization_profile.REQUEST })
      const UserToken = localStorage.getItem('UserToken');
      const headers = {
        Authorization: `Bearer ${UserToken}`,
        'Content-Type': 'application/json',
      }
      
      const data_result = await  postAPI({
        url: `/api/org/request_org_activity`,
        data,
        headers: headers,
      })
      
      return { type: 'ApiAddRequestORGActivity_SUCCESS', data_info: data_result }

    } catch (err) {
      return { type: 'ApiAddRequestORGActivity_ERROR' }

    } finally {
      dispatch(hideLoading())
    }
  }

  export const ApiApproveRegisterORG = (data) => async (dispatch) => {
    try {
      
      dispatch(showLoading())
      dispatch({ type: organization_profile.REQUEST })
      const UserToken = localStorage.getItem('UserToken');
      const headers = {
        Authorization: `Bearer ${UserToken}`,
        'Content-Type': 'application/json',
      }
      
      const data_result = await  postAPI({
        url: `/api/org/ApproveRegisterORG`,
        data,
        headers: headers,
      })
      
      return { type: 'ApiApproveRegisterORG_SUCCESS', data_info: data_result }

    } catch (err) {
      return { type: 'ApiApproveRegisterORG_ERROR' }

    } finally {
      dispatch(hideLoading())
    }
  }

  export const ApiGetvw_orgnization_request = (filter = { limit: 10, skip: 0 }) => async (dispatch) => {
    try {
      
      dispatch(showLoading())
      dispatch({ type: GET_organization.REQUEST })

      const accessToken = localStorage.getItem('accessToken');
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }
      let params = {
        '$limit': filter.limit,
        '$skip': filter.skip,
      }
      /*
      if(filter.name){
        params = {...params,'full_name': filter.name}
      }
      if(filter.status_id){
        params = {...params,'status_id': filter.status_id}
      }
      */
      const data_result = await getAPI({
        url: '/api/org/vw_orgnization_request?$sort[id]=-1',
        headers: headers,
        params,
      })

      return { type: 'ApiGetvw_orgnization_request_SUCCESS', data_info: data_result }

    } catch (err) {
      return { type: 'ApiGetvw_orgnization_request_ERROR' }

    } finally {
      dispatch(hideLoading())
    }
  }

  export const ApiApplicationsProfile = (data) => async (dispatch) => {
    try {
      
      dispatch(showLoading())
      dispatch({ type: organization_profile.REQUEST })
      const accessToken = localStorage.getItem('accessToken');
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }
      
      const data_result = await  postAPI({
        url: `/api/org/OrgApplicationsProfile`,
        data,
        headers: headers,
      })
      
      return { type: 'ApiApplicationsProfile_SUCCESS', data_info: data_result }
  
    } catch (err) {
      return { type: 'ApiApplicationsProfile_ERROR' }
  
    } finally {
      dispatch(hideLoading())
    }
  }

  export const ApiGetApplicationDocument = (filter = { limit: 10, skip: 0 }) => async (dispatch) => {
    try {
      
      dispatch(showLoading())
      dispatch({ type: GET_organization.REQUEST })
  
      const accessToken = localStorage.getItem('accessToken');
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }
      let params = {
        '$limit': filter.limit,
        '$skip': filter.skip,
      }
      if(filter.application_id){
        params = {...params,'application_id': filter.application_id}
      }
  
      const data_result = await getAPI({
        url: '/api/org/OrgApplicationDocument?$sort[id]=-1',
        headers: headers,
        params,
      })
  
      return { type: 'ApiGetApplicationDocument_SUCCESS', data_info: data_result }
  
    } catch (err) {
      return { type: 'ApiGetApplicationDocument_ERROR' }
  
    } finally {
      dispatch(hideLoading())
    }
  }

  export const ApiUpdateApplications = (id, data) => async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: UPDATE_organization.REQUEST })
      const accessToken = localStorage.getItem('accessToken');
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }
      /*
      await patchAPI({
        url: `/api/org/OrgApplications/${id}`,
        data,
        headers: headers,
      })
      */

      const data_result = await patchAPI({
        url: `/api/org/OrgApplications/${id}`,
        data,
        headers: headers,
      })
  
      return { type: 'ApiUpdateApplications_SUCCESS', data_info: { code : 200 } , data_result : data_result }
  
    } catch (err) {
  
      return { type: 'ApiUpdateApplications_ERROR' }
  
    } finally {
      dispatch(hideLoading())
    }
  }

  export const ApiOrgExtend = (data) => async (dispatch) => {
    try {
      
      dispatch(showLoading())
      dispatch({ type: organization_profile.REQUEST })
      const accessToken = localStorage.getItem('accessToken');
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }
      
      const data_result = await  postAPI({
        url: `/api/org/OrgExtend`,
        data,
        headers: headers,
      })
      
      return { type: 'ApiOrgExtend_SUCCESS', data_info: data_result }
  
    } catch (err) {
      return { type: 'ApiOrgExtend_ERROR' }
  
    } finally {
      dispatch(hideLoading())
    }
  }  

  export const ApiAddORGActivity = (data) => async (dispatch) => {
    try {
      
      dispatch(showLoading())
      dispatch({ type: organization_profile.REQUEST })
      const accessToken = localStorage.getItem('accessToken');
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }
      
      const data_result = await  postAPI({
        url: `/api/org/organizations_activity`,
        data,
        headers: headers,
      })
      
      return { type: 'ApiAddORGActivity_SUCCESS', data_info: data_result }

    } catch (err) {
      return { type: 'ApiAddORGActivity_ERROR' }

    } finally {
      dispatch(hideLoading())
    }
  }

  export const ApiGetOrgActivity = (filter = { limit: 10, skip: 0 }) => async (dispatch) => {
    try {
      
      dispatch(showLoading())
      dispatch({ type: GET_organization.REQUEST })
  
      const accessToken = localStorage.getItem('accessToken');
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }
      let params = {
        '$limit': filter.limit,
        '$skip': filter.skip,
      }
      if(filter.application_id){
        params = {...params,'application_id': filter.application_id}
      }
  
      const data_result = await getAPI({
        url: '/api/org/organizations_activity?$sort[id]=1',
        headers: headers,
        params,
      })
  
      return { type: 'ApiGetOrgActivity_SUCCESS', data_info: data_result }
  
    } catch (err) {
      return { type: 'ApiGetOrgActivity_ERROR' }
  
    } finally {
      dispatch(hideLoading())
    }
  }