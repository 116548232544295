import styled from 'styled-components'
import { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { getTitle } from '../../redux/actions/master'
import { ApiEventCompareUser } from 'redux/actions/activityCpd'

import TextField from '../../components/form/TextField'
import Dropdown from '../../components/form/Dropdown'
import InputTextArea from '../../components/widget/element/InputTextArea'
import Button from '../../components/form/button/Button'
import ButtonOutline from '../../components/form/button/ButtonOutline'
import question from '../../assets/images/question.png'
import DialogConfirmV2 from '../../components/dialog/DialogConfirmV2'
import DialogSuccess from '../../components/dialog/DialogSuccess'
import DialogFail from '../../components/dialog/DialogFail'
import RadioButton from '../../components/form/RadioButton'

import { GiCancel } from 'react-icons/gi'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import successIcon from '../../assets/images/success-icon.png'
import failIcon from '../../assets/images/fail-icon.png'
import { useTranslation } from 'react-i18next'

const Div = styled.div`
  .css_header_detail {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    font-size: 14px;
    .date_css{
      color: #7F56D9;
      font-weight: bold;
    }
  }
  .content_header {
    padding: 14px 14px;
    
    width : ${(props) => props.width}px;
    position: fixed;
    background: #FFFFFF;
    top: 0;
    right: 0;
    z-index: 1;
    .title {
      font-size: 18px;
      font-weight: 600;
      color: var(--Gray-900);
    }
  }
  .dialog-content {
    padding: 0px 14px;
    padding-top: 90px;
    padding-bottom: 106px;
    .title {
      font-size: 18px;
      font-weight: 600;
      color: var(--Gray-900);
    }
  }
  header {
    /*padding: 24px 14px;*/
    /*margin-top: 20px;*/
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }
  .icon {
    cursor: pointer;
  }
  .content-title {
    font-size: 20px;
    font-weight: 600;
    color: var(--Gray-900);
  }
  .wrp-tool-right {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
  }
  .topic_main {
    font-size: 14px;
    font-weight: 600;
    padding: 5px 0px;
    color: #344054;
  }
  .InputTextArea_css {
    width: 100%;
    font-size: 14px;
  }
  .dialog-footer {
    /*margin-bottom: 15px;*/
    text-align: end;
    position: fixed;
    right: 0;
    bottom: 0;
    width : ${(props) => props.width}px;
    background-color : #FFFFFF;
    padding: 14px;
    .button_margin {
      margin: 10px;
    }
  }

  .wrp-tool {
    display: flex;
    justify-content: space-between;
  }
  .wrp-tool-left {
    display: flex;
    align-items: center;
    /* background-color: red; */
  }
  .mb-1-rem {
    margin-bottom: 1rem;
  }
  .mr-1-rem {
    margin-right: 1rem;
  }
  .css_upload {
    margin-top: 10px;
  }
  .topic_fill{
    font-size: 14px;
    color: #344054;
    margin: 5px 0px;
  }
  .TextField_employee {
    width: 100%;
  }
  .content{
    margin:10px 0px;
  }
  .InputTextArea_css {
    width: 100%;
    font-size: 14px;
  }
  .prefix_icon{
    display: flex;
    align-items: center;
    margin-left: 5px;
  }
  .box_radio{
    padding: 0px 24px;
    display: flex;
    gap : 30px;
    .radio_css{
        display: flex;
        align-items: center;
    }
    .text_green{
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color : #085D3A;
    }
    .text_red{
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color : #912018;
    }
    .text_normal{
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color : #101828;
    }
  }
`

const acceptList = {
  images: {
    'image/jpeg': ['.jpeg', '.jpg'],
    'image/png': ['.png'],
  },
  files: {
    'text/csv': [],
    'application/pdf': [],
    'application/msword': [],
    'application/vnd.ms-excel': [],
    'application/zip': [],
  },
  videos: {
    'video/x-msvideo': [],
    'video/mp4': [],
    'video/mpeg': [],
    'video/webm': [],
  },
  audios: {
    'audio/mpeg': [],
    'audio/wav': [],
    'audio/webm': [],
  },
}

const DivErrorMessage = styled.div`
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
`

const NewUserEventCompare = ({ onClose, onSubmit,this_id,EventField,...props  }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [user_list, setuser_list] = useState([{
    first_name : '',
    first_name_error: true,
    last_name : '',
    last_name_error: true,
    is_attended : 'Y',
    is_exam : EventField == 102 ? 'Y' : '-'
  }])
  

  const [ModalConfirmDialog, setModalConfirmDialog] = useState(false)
  const ConfirmDialog = () => {
    const ObjEventDateError = user_list.filter((v) => v.first_name_error == true || v.last_name_error == true);
    if (ObjEventDateError.length == 0 ) {
      setConfirmMessageModal({
        headline : 'ยืนยันการเพิ่มรายชื่อผู้ลงทะเบียนกิจกรรม',
        message : '',
      })
      setModalConfirmDialog(true)
    }

  }
  const [Titlesuccess, setTitlesuccess] = useState('')
  const [SubTitlesuccess, setSubTitlesuccess] = useState('')
  const [Descriptionsuccess, setDescriptionsuccess] = useState('')
  const [successModal, setsuccessModal] = useState(false)
  const [TitleFail, setTitleFail] = useState('')
  const [nameItemFail, setnameItemFail] = useState('')
  const [DescriptionFail, setDescriptionFail] = useState('')
  const [failModal, setFailModal] = useState(false)

  const initMessageModal = {
    headline: '',
    message: '',
  }
  const [ConfirmMessageModal, setConfirmMessageModal] = useState(initMessageModal)

  const ProcessUpdate = () => {
    setModalConfirmDialog(false);

    const sent_data_to_api = {
        event_id : this_id,
        uuid : 'add_manual',
        user_list : user_list,
        is_add_manual : true
    }
    dispatch(ApiEventCompareUser(sent_data_to_api)).then(({ type,data_info }) => {
      if(type.endsWith('_SUCCESS')){
        if(data_info.code == 200){
            setTitlesuccess('ทำรายการสำเร็จ');
            setSubTitlesuccess('');
            setDescriptionsuccess(''); 
            setsuccessModal(true)
        }else{
            setTitleFail(data_info.message);
            setnameItemFail('');
            setDescriptionFail(''); 
            setFailModal(true)
        }
      }else{
        setTitleFail('internal server error');
        setnameItemFail('');
        setDescriptionFail(''); 
        setFailModal(true)
      }     
    })
    
    //onSubmit()
  }

  const onChangeDataContent = (index_item, e, type_data) => {
    setuser_list((prevList) => {
        const newList = [...prevList];
        newList[index_item][type_data] =e.target.value;
        if(type_data == 'first_name'){
          newList[index_item].first_name_error = false;
        }else if(type_data == 'last_name'){
          newList[index_item].last_name_error = false;
        }
        return newList;
    });
  }

  const onAddUserList = () => {
    const newStateData = [
        ...user_list, 
        {
            first_name : '',
            first_name_error: true,
            last_name : '',
            last_name_error: true,   
            is_attended : 'Y',
            is_exam : EventField == 102 ? 'Y' : '-'
        }
    ]

    
    setuser_list(newStateData)
  }


  return (
    <Div {...props}>
      <div className="content_header">
        <header>
          <div className="content-title">เพิ่มรายชื่อผู้ลงทะเบียนกิจกรรม</div>
          <div className="wrp-tool-right">
            <CloseOutlinedIcon size={30} onClick={onClose} className="icon" />
          </div>
        </header>
        <hr />
      </div>
      
      <div className="dialog-content">
        {user_list?.map((item, index) => (
            <div>
                <div className="content">
                    <div className="topic_fill">ชื่อ*</div>
                    <div >
                        <TextField
                            name="first_name"
                            className="TextField_employee"
                            placeholder={'ระบุชื่อ'}
                            value={item.first_name}
                            onChange={(v) => onChangeDataContent(index, v, 'first_name')}
                            error={Boolean(item.first_name_error)}
                        />
                        {item.first_name_error == true && (
                          <DivErrorMessage>{'กรุณาระบุชื่อ'}</DivErrorMessage>
                        )}
                    </div>
                </div>
                <div className="content">
                    <div className="topic_fill">นามสกุล*</div>
                    <div >
                        <TextField
                            name="last_name"
                            className="TextField_employee"
                            placeholder={'ระบุนามสกุล'}
                            value={item.last_name}
                            onChange={(v) => onChangeDataContent(index, v, 'last_name')}
                            error={Boolean(item.last_name_error)}
                        />
                        {item.last_name_error == true && (
                          <DivErrorMessage>{'กรุณาระบุนามสกุล'}</DivErrorMessage>
                        )}
                    </div>
                </div>
                {EventField == 102 && (
                  <div className="content">
                      <div className="topic_fill">ระบุผลสอบ</div>
                      <div className="box_radio">
                          <div className="radio_css">
                            <div>
                                <RadioButton 
                                    optionList={[{text : 'ผ่าน',value: 'Y'}]} 
                                    value={item.is_exam} 
                                    handleChange={(e) => onChangeDataContent(index, e, 'is_exam')}
                                /> 
                            </div>
                          </div>
                          <div className="radio_css">
                            <div>
                                <RadioButton 
                                    optionList={[{text : 'ไม่ผ่าน',value: 'N'}]} 
                                    value={item.is_exam} 
                                    handleChange={(e) => onChangeDataContent(index, e, 'is_exam')}
                                /> 
                            </div>
                          </div>
                      </div>
                  </div>
                )}
                <hr />
            </div>
        ))} 
        <div>
            <ButtonOutline className="button_margin" onClick={onAddUserList}>
                {'เพิ่มรายชื่อ'}
            </ButtonOutline>
        </div>
      </div>
      
      <div className="dialog-footer">
        <hr />
        <ButtonOutline className="button_margin" onClick={onClose}>
          {'ยกเลิก'}
        </ButtonOutline>
        <Button className="button_margin" onClick={ConfirmDialog}>
          {'บันทึก'}
        </Button>
      </div>

        <DialogConfirmV2
          open={Boolean(ModalConfirmDialog)}
          onClose={() => setModalConfirmDialog(false)}
          onNo={() => setModalConfirmDialog(false)}
          onSubmit={ProcessUpdate}
          icon={successIcon}
          title={ConfirmMessageModal.headline}
          nameItem={ConfirmMessageModal.message}
          textYes={'ยืนยัน'}
          textNo={'ยกเลิก'}
        />

      <DialogSuccess
        open={Boolean(successModal)}
        onClose={() => setsuccessModal(false)}
        onSubmit={() => onSubmit()}
        icon={successIcon}
        title={Titlesuccess}
        subtitle={SubTitlesuccess}
        description={Descriptionsuccess}
        textYes={'ตกลง'}
      />

        <DialogFail
          open={Boolean(failModal)}
          onClose={() => setFailModal(false)}
          onSubmit={() => setFailModal(false)}
          icon={failIcon}
          title={TitleFail}
          nameItem={nameItemFail}
          description={DescriptionFail}
          textYes={'ตกลง'}
        />
    </Div>
  )
}

export default NewUserEventCompare
