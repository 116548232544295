import styled from 'styled-components'
import { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import {  DateDisplayDDMMBBB } from '../../utils/common'
import { ApiElearning_Member_profile } from '../../redux/actions/elearning'

import Button from '../../components/form/button/Button'
import ButtonOutline from '../../components/form/button/ButtonOutline'
import ButtonSecondary from '../../components/form/button/ButtonSecondary'
import DialogSuccess from '../../components/dialog/DialogSuccess'
import DialogFail from '../../components/dialog/DialogFail'
import DialogConfirmV2 from '../../components/dialog/DialogConfirmV2'
import TextField from '../../components/form/TextField'
import Dropdown from '../../components/form/Dropdown'
import Field from '../../components/form/Field'
import InputTextArea from '../../components/widget/element/InputTextArea'

import successIcon from '../../assets/images/success-icon.png'
import failIcon from '../../assets/images/fail-icon.png'

import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';


const Div = styled.div`
font-size: 14px;
  .css_header_detail {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    font-size: 14px;
    .date_css{
      color: #7F56D9;
      font-weight: bold;
    }
  }
  .content_header {
    padding: 14px 14px;
    
    width : ${(props) => props.width}px;
    position: fixed;
    background: #FFFFFF;
    top: 0;
    right: 0;
    z-index: 1;
    .title {
      font-size: 18px;
      font-weight: 600;
      color: var(--Gray-900);
    }
  }
  .dialog-content {
    padding: 0px 14px;
    padding-top: 90px;
    padding-bottom: 40px;
    .title {
      font-size: 18px;
      font-weight: 600;
      color: var(--Gray-900);
    }
  }
  header {
    /*padding: 24px 14px;*/
    /*margin-top: 20px;*/
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }
  .icon {
    cursor: pointer;
  }
  .content-title {
    font-size: 20px;
    font-weight: 600;
    color: var(--Gray-900);
  }
  .wrp-tool-right {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
  }
  .dialog-footer {
    /*margin-bottom: 15px;*/
    text-align: end;
    position: fixed;
    right: 0;
    bottom: 0;
    width : ${(props) => props.width}px;
    background-color : #FFFFFF;
    padding: 14px;
    .button_margin {
      margin: 10px;
    }
  }

  .content{
    margin:10px 0px;
  }
  .InputTextArea_css {
    width: 100%;
    font-size: 14px;
  }
  .div_box_deatail{
    border: 1px solid #D0D5DD;
    border-radius: 8px;
    background-color: #F9FAFB;
    font-size: 14px;
    padding: 10px;
    white-space: pre-wrap;
    line-break: anywhere;
  }
  .div_box_deatail_text_area{
    min-height: 128px;
    border: 1px solid #D0D5DD;
    border-radius: 8px;
    background-color: #F9FAFB;
    font-size: 14px;
    padding: 10px;
    white-space: pre-wrap;
    line-break: anywhere;
  }
  .other_box{
    display: grid;
    grid-template-columns: repeat(2, 49% [ col-start ] 49% [ col-end ]);
    gap: 2%;
    align-items: start;
  }
`

const ViewElearning = ({ onClose, onSubmit,ThisId,...props  }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [isOpenData, setisOpenData] = useState(false)
  const [DataInfo, setDataInfo] = useState(null)


  useEffect(() => {
    const sent_data_to_api = {
        user_point_id : ThisId,
    };
    dispatch(ApiElearning_Member_profile(sent_data_to_api)).then(({ type,data_info }) => {
      if(type.endsWith('_SUCCESS')){
        if(data_info.data_info){
            setDataInfo(data_info.data_info);
            setisOpenData(true)
        }
      }
    })

}, [dispatch])
  //---- Modal Success And Fail
  const initMessageModal = {
    headline: '',
    message: '',
  }
  const [ModalConfirmDialog, setModalConfirmDialog] = useState(false)
  const [successModal, setSuccessModal] = useState(false)
  const [failModal, setFailModal] = useState(false)
  const [successMessageModal, setSuccessMessageModal] = useState(initMessageModal)
  const [failMessageModal, setFailMessageModal] = useState(initMessageModal)
  const [ConfirmMessageModal, setConfirmMessageModal] = useState(initMessageModal)

  return (
    <Div {...props}>
      <div className="content_header">
        <header>
          <div className="content-title">
            ดูรายละเอียดคะแนน
          </div>
          <div className="wrp-tool-right">
            <CloseOutlinedIcon size={30} onClick={onClose} className="icon" />
          </div>
        </header>
        <div className="css_header_detail">ตรวจสอบรายละเอียดของคะแนน CPD</div>
        <hr />
      </div>
      
      <div className="dialog-content">
        {isOpenData && (
            <div>
                <div className="content">
                    <Field className="field" label="ที่มา">
                        <div className="div_box_deatail">
                            {'E-learning'}
                        </div>
                    </Field>
                </div>
                <div className="content">
                    <Field className="field" label="รายละเอียด / ชื่อกิจกรรม">
                        <div className="div_box_deatail_text_area">
                            {DataInfo.topic_name}
                        </div>
                    </Field>
                </div>
                <div className="content">
                    <Field className="field" label="คะแนนที่ได้รับ">
                        <div className="div_box_deatail">
                            {DataInfo.user_point}
                        </div>
                    </Field>
                </div>
                <div className="content">
                    <div className="other_box">
                        <Field className="field" label="วันที่ได้คะแนน">
                            <div className="div_box_deatail">
                                { DateDisplayDDMMBBB(DataInfo.point_effective_date)}
                            </div>
                        </Field>
                        <Field className="field" label="วันที่คะแนนหมดอายุ">
                            <div className="div_box_deatail">
                                { DateDisplayDDMMBBB(DataInfo.point_expire_date) }
                            </div>
                        </Field>
                    </div>
                </div>
                <div className="content">
                    <Field className="field" label="แขนงความรู้">
                        <div className="div_box_deatail">
                          {DataInfo.field_study_data ? DataInfo.field_study_data.map((v) => v.knowledge_th + '-' + v.field_study_th).join(',') : '-'}   
                        </div>
                    </Field>
                </div>
                <div className="content">
                    <Field className="field" label="เกณฑ์การได้รับคะแนน">
                        <div className="div_box_deatail_text_area">
                            {DataInfo.activity_criteria}
                        </div>
                    </Field>
                </div>
            </div>

        )}


      </div>
      
      {/* Dialog */}
        <DialogConfirmV2
          open={Boolean(ModalConfirmDialog)}
          onClose={() => setModalConfirmDialog(false)}
          onNo={() => setModalConfirmDialog(false)}
          onSubmit={() => setModalConfirmDialog(false)}
          icon={successIcon}
          title={ConfirmMessageModal.headline}
          nameItem={ConfirmMessageModal.message}
          textYes={'ยืนยัน'}
          textNo={'ยกเลิก'}
        />
      <DialogSuccess
        open={Boolean(successModal)}
        onClose={() => setSuccessModal(false)}
        onSubmit={() => onSubmit()}
        icon={successIcon}
        title={successMessageModal.headline}
        description={successMessageModal.message}
        textYes='ตกลง'
      />
      <DialogFail
        open={Boolean(failModal)}
        onClose={() => setFailModal(false)}
        onSubmit={() => {
          setFailModal(false)
        }}
        icon={failIcon}
        title={failMessageModal.headline}
        description={failMessageModal.message}
        textYes='ตกลง'
      />
    </Div>
  )
}

export default ViewElearning
