import { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import InputMask from "react-input-mask";

import styled from 'styled-components'
import Field from '../components/form/Field'
import TextField from '../components/form/TextField'
import Button from '../components/form/button/Button'
import { loginUser, verifyOtpForLogin, resendOTP,OrgUserAuthen } from '../redux/actions/admin'

import logoIcon from '../assets/images/logo-icon.png'
import { VERSION } from '../configs/app'

//import { selectMaster } from '../redux/selectors'
//import { getCompanyInfoById, getServerTime } from '../redux/actions/master'

import PasswordField from '../components/form/PasswordInput'
import Modal from '../components/common/Modal'
import ButtonOutline from '../components/form/button/ButtonOutline'
import ButtonSecondary from '../components/form/button/ButtonSecondary'
import OtpInputComponent from '../components/form/OtpInputComponent'
import DialogSuccess from '../components/dialog/DialogSuccess'
import DialogFail from '../components/dialog/DialogFail'
import DialogConfirmV2 from '../components/dialog/DialogConfirmV2'

import { IoMdClose } from 'react-icons/io'
import successIcon from '../assets/images/success-icon.png'
import failIcon from '../assets/images/fail-icon.png'
import otpIcon from '../assets/images/opt-icon.png'
import warningIcon from '../assets/images/warning-icon.png'
import { PiWarningCircleBold, PiFloppyDiskBold } from 'react-icons/pi'

const Div = styled.div`
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  .advice_text{
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    text-decoration-line: underline;
    cursor: pointer;
    margin-top: 24px;
    text-align: center;
    color: #0497cc;
 }
  .img-container {
    padding-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    
    img {
      width: 220px;
      /*height: 48px;*/
    }
    
  }

  .title {
    font-size: 30px;
    font-weight: 600;
    text-align: center;
    font-style: normal;
    color: var(--Gray-900);
  }

  .desc {
    text-align: center;
    margin-top: 12px;
  }

  .login-form {
    margin-top: 32px;

    .field {
      margin-top: 20px;
    }

    .link-wrapper {
      display: flex;
      justify-content: flex-end;
      margin-top: 24px;

      color: var(--Primary-700);
      font-size: 14px;
      font-style: normal;
      font-weight: 600;

      :hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }

    .button-login {
      display: block;
      width: 100%;
      margin-top: 24px;
    }
  }

  .hightlight {
    color: var(--Primary-700);
    font-weight: 800;
  }

  .copyright {
    color: var(--Gray-500);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    margin-top: 180px;
  }

  @media screen and (max-width: 440px) {
    max-width: 300px;
    .title {
      font-size: 20px;
    }

    .desc {
      font-size: 14px;
    }
  }
  .warning-container {
    margin-top: 24px;
    color: #475467;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    border-radius: 0.75rem;
    border: 1px solid #98A2B3;
    background: #FFFCF5;
    padding: 1rem;

    .icon-warning-container {
    }

    .warning-message-container {
      .headline-warning {
        font-weight: 600;
      }
    }
  }
  .d-flex {
    display: flex;
  }
  .user_img_char_1 {
    border-radius: 50%;
    background-color: #FEF0C7;
    width: 48px;
    height: 48px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-right: 12px;
    color: #344054;
    cursor: pointer;
  }
`
const OTPContainer = styled.div`
  padding: 24px;

  .mr-12-px {
    margin-right: 12px;
  }
  .mb-20-px {
    margin-bottom: 20px;
  }
  .mb-32-px {
    margin-bottom: 32px;
  }

  .heading {
    color: var(--Gray-900);
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
  }
  p {
    margin: 0;
    color: var(--OTP-FONT);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    text-align: center;
  }
  .css_phone_no{
    color: var(--OTP-FONT-PHONE);
    font-weight: bold;
  }
  .highlight-text {
    text-decoration-line: underline;
    cursor: pointer;
    color: #0086c9;
  }
  .icon {
    cursor: pointer;
  }
  .text-left{
    text-align: left;
  }
`
const FlexContainer = styled.div`
  display: flex;

  &.justify-content-center {
    justify-content: center;
  }
  &.justify-content-end {
    justify-content: flex-end;
  }
  &.justify-content-space-between {
    justify-content: space-between;
  }

  .w-48 {
    width: 48%;
  }
`

const DivStyleInputMask = styled.div`
  display: inline-flex;
  background: var(--Base-White);
  border: 1px solid var(--Gray-300);
  box-shadow: var(--Shadow-xs);
  border-radius: 8px;
  height: 40px;
  input {
    border-radius: 8px;
    padding: 10px;
    width: 100%;
    min-width: 0;
    border: none;
    /*font-family: 'Inter';*/
    font-family: inherit;
    font-size: 14px;
    line-height: 1.5;
    color: var(--Gray-500);
  }

`

const DivErrorMessage = styled.div`
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
`

const Login = () => {
  // external hook
  const { t } = useTranslation()
  const lang = localStorage.getItem('lang')
  const navigate = useNavigate()
  const dispatch = useDispatch()

  // initiate data
  /*
  const fetchCompanyInfo = useCallback(
    (id) => {
      dispatch(getCompanyInfoById(id))
    },
    [dispatch]
  )
  useEffect(() => {
    fetchCompanyInfo(1)
  }, [])
  */

  // master store
  //const { isRedirect, companyInfo } = useSelector(selectMaster)
  // state input about log in
  const [UserName, setUserName] = useState('')
  const [PhoneNo, setPhoneNo] = useState('')

  const [LoginData, setLoginData] = useState({
    UserName: '',
    PhoneNo: '',
  })
  const [LoginDataError, setLoginDataError] = useState({
    UserName: {
      show_errorMessage: false,
      errorMessage: 'กรุณากรอกให้ครบ 13 หลัก',
    },
    PhoneNo: {
      show_errorMessage: false,
      errorMessage: 'กรุณากรอกให้ครบ 10 หลัก',
    },
  })

  const [loginResponse, setLoginResponse] = useState()
  const [fullOtp, setFullOtp] = useState([])

  // time counter otp modal
  const initialTimeCounterForResendOtp = 60
  const initialTimeCounterForOtpModal = {
    minutes: 5,
    seconds: 0,
  }
  const [otpModal, setOtpModal] = useState(false)
  const [minutes, setMinutes] = useState(initialTimeCounterForOtpModal.minutes)
  const [seconds, setSeconds] = useState(initialTimeCounterForOtpModal.seconds)

  const openOtpModal = () => {
    setOtpModal(true)
  }
  const closeOtpModal = () => {
    setOtpModal(false)
  }

  useEffect(() => {
    let countdown

    if (otpModal) {
      countdown = setInterval(() => {
        if (minutes === 0 && seconds === 0) {
          setOtpModal(false)
          clearInterval(countdown)
        } else {
          if (seconds === 0) {
            setMinutes(minutes - 1)
            setSeconds(59)
          } else {
            setSeconds(seconds - 1)
          }
        }
      }, 1000)
    } else {
      setTimeout(() => {
        setMinutes(initialTimeCounterForOtpModal.minutes)
        setSeconds(initialTimeCounterForOtpModal.seconds)

        setIsCountingDown(false)
        setTimeCounter(initialTimeCounterForResendOtp)
      }, 1000)
    }

    return () => {
      clearInterval(countdown)
    }
  }, [otpModal, minutes, seconds])

  // time counter for resending otp
  const [timeCounter, setTimeCounter] = useState(initialTimeCounterForResendOtp)
  const [isCountingDown, setIsCountingDown] = useState(false)

  useEffect(() => {
    let timerId
    if (isCountingDown && timeCounter > 0) {
      timerId = setTimeout(() => setTimeCounter((c) => c - 1), 1000)
    } else if (timeCounter <= 0) {
      setIsCountingDown(false)
    }

    return () => clearTimeout(timerId)
  }, [timeCounter, isCountingDown])

  const [targetTime, setTargetTime] = useState(null)
  const [timeDifference, setTimeDifference] = useState('')

  useEffect(() => {
    if (targetTime) {
      const updateTimeDifference = () => {
        const now = new Date()
        const target = new Date(targetTime)
        const difference = target - now
        const minutes = Math.floor((difference / 1000 / 60) % 60)
        const seconds = Math.floor((difference / 1000) % 60)
        const formattedMinutes = minutes.toString().padStart(2, '0')
        const formattedSeconds = seconds.toString().padStart(2, '0')
        setTimeDifference(`${formattedMinutes}:${formattedSeconds}`)
      }
      updateTimeDifference()
      const intervalId = setInterval(updateTimeDifference, 1000)
      return () => clearInterval(intervalId)
    }
  }, [targetTime])

  useEffect(() => {
    if (timeDifference === '00:00') {
      setTargetTime(null)
      setFailModal2(false)
    }
  }, [timeDifference])

  // success,fail modal
  const initMessageModal = {
    headline: '',
    message: '',
  }
  const [successModal, setSuccessModal] = useState(false)
  const [failModal, setFailModal] = useState(false)
  const [failModal2, setFailModal2] = useState(false)
  const [successMessageModal, setSuccessMessageModal] = useState(initMessageModal)
  const [failMessageModal, setFailMessageModal] = useState(initMessageModal)
  const [failMessageModal2, setFailMessageModal2] = useState(initMessageModal)
  const [ModalConfirmDialog, setModalConfirmDialog] = useState(false)
  const [ConfirmMessageModal, setConfirmMessageModal] = useState(initMessageModal)

  const confirmSuccess = async () => {
    setSuccessModal(false)

    window.location.href = '/'
  }
  const confirmFailModal = async () => {
    setFailModal(false)
  }
  const confirmFailModal2 = async () => {
    setTargetTime(null)
    setFailModal2(false)
  }

  const isValid = () => {
    let data_valid = LoginData;
    let data_check_valid = LoginDataError;
    let errors = []
    Object.keys(LoginDataError).forEach((k) => {
      let data = data_valid[k]
      if (data_check_valid[k].errorMessage != '') {
        if (!data || data < 0) {
          errors.push(data_check_valid[k].errorMessage)
          data_check_valid[k].show_errorMessage = true
          if (k == 'UserName') {
            data_check_valid[k].errorMessage = 'กรุณากรอกเลขบัตรประจำตัวประชาชน'
          }else if(k == 'PhoneNo'){
            data_check_valid[k].errorMessage = 'กรุณากรอกเบอร์โทรศัพท์'
          }
        } else {
          if (k == 'UserName') {
            const real_UserName = data.replaceAll('-', '').replaceAll('_', '');
            if(real_UserName.length != 13){
              errors.push(data_check_valid[k].errorMessage)
              data_check_valid[k].errorMessage = 'กรุณากรอกให้ครบ 13 หลัก'
              data_check_valid[k].show_errorMessage = true
            }else{
              data_check_valid[k].show_errorMessage = false
            }
          }else if(k == 'PhoneNo'){
            const real_PhoneNo = data.replaceAll(')', '').replaceAll('(', '').replaceAll('-', '').replaceAll('_', '');
            if(real_PhoneNo.length != 10){
              errors.push(data_check_valid[k].errorMessage)
              data_check_valid[k].errorMessage = 'กรุณากรอกให้ครบ 10 หลัก'
              data_check_valid[k].show_errorMessage = true
            }else{
              data_check_valid[k].show_errorMessage = false
            }
          } else {
            data_check_valid[k].show_errorMessage = false
          }
        }
      }
    })
    setLoginDataError({ ...LoginDataError })
    return errors
  }

  // submit function
  const handleLogin = (e) => {
    e.preventDefault()
    if (isValid().length == 0) {
      const real_UserName = LoginData.UserName.replaceAll('-', '');
      const real_PhoneNo = LoginData.PhoneNo.replaceAll(')', '').replaceAll('(', '').replaceAll('-', '');

      
      dispatch(loginUser(real_UserName, real_PhoneNo)).then(({ type,data_info }) => {
        
        if(type.endsWith('_SUCCESS')){
          if(data_info.code == 200){
            const data_login_user = {
              user_name : real_UserName,
              phone_no : data_info.phone_no ,
              refno : data_info.ref_code,
              code: data_info.code,
              message: data_info.message,
              user_id: data_info.user_id,
              loglogin_id: data_info.loglogin_id,
              otp_id: data_info.otp_id,
              organizations_user_account_id: data_info.organizations_user_account_id,
            }
            setLoginResponse(data_login_user)
            openOtpModal();
          }else if(data_info.code == 301){
            setConfirmMessageModal({
              headline: 'ไม่พบข้อมูลผู้ใช้ในระบบ',
              message: 'กรุณาลงทะเบียนผู้ใช้ใหม่',
            })
            setModalConfirmDialog(true)
          }else if(data_info.code == 302){ //--- Block OTP
            setTargetTime(data_info?.block_expire_date)

            setFailMessageModal2({
              headline: 'ทำรายการผิดเกิน 5 ครั้ง',
              message: '',
            })
            setFailModal2(true)
          }else{
            
            setFailMessageModal({
              headline: data_info.message,
              message: '',
            })
            setFailModal(true)

          }
        }else{
          setFailMessageModal({
            headline: 'internal server error',
            message: '',
          })
          setFailModal(true)
        }
      })
    }
  }

  // function about otp
  const verifyOtp = () => {
    if (fullOtp.length === 6) {
      const request = {
        strategy: "user-local",
        user_name:loginResponse.user_name,
        otpcode : fullOtp.join('') + "|" + loginResponse.otp_id + "|login|" + loginResponse.loglogin_id
      }
      dispatch(verifyOtpForLogin(request)).then(({ type,data_info }) => {
        if(type.endsWith('_SUCCESS')){
          if(data_info.accessToken){
            localStorage.setItem('UserToken', data_info.accessToken)
            localStorage.setItem('loglogin_id', loginResponse.loglogin_id)
            if(loginResponse.organizations_user_account_id){
              const request = {
                strategy: "org_user-local",
                organizations_user_account_id:loginResponse.organizations_user_account_id,
                loglogin_id : loginResponse.loglogin_id    
              }
              dispatch(OrgUserAuthen(request)).then(({ type,data_info }) => {
                
                if(type.endsWith('_SUCCESS')){
                  if(data_info.accessToken){
                      localStorage.setItem('accessToken', data_info.accessToken);
                      localStorage.setItem('user_info', JSON.stringify(data_info.organizations_user_authen ));
                      window.location.href = '/'
                  }else{
                    
                      setFailMessageModal({
                          headline: 'internal server error',
                          message: '',
                      })
                      setFailModal(true)
                    
                  }
                }else{
                  setFailMessageModal({
                    headline: 'internal server error',
                    message: '',
                  })
                  setFailModal(true)
                }
                
              })
            }else{
              navigate('/OrganizationsRequest')
            }
            
          }else{
            setFailMessageModal({
              headline: 'รหัส OTP ไม่ถูกต้อง',
              message: 'กรุณาลองใหม่อีกครั้ง',
            })
            setFailModal(true)
          }
        }else{
          if(data_info.message == 'BlockMember'){
            setOtpModal(false);

            setTargetTime(data_info.errors.block_expire_date);

            setFailMessageModal2({
              headline: 'ทำรายการผิดเกิน 5 ครั้ง',
              message: '',
            })
            setFailModal2(true)

          }else{
            setFailMessageModal({
              headline: 'รหัส OTP ไม่ถูกต้อง',
              message: 'กรุณาลองใหม่อีกครั้ง',
            })
            setFailModal(true)
          }

          /*
          setFailMessageModal({
            headline: 'internal server error',
            message: '',
          })
          setFailModal(true)
          */
        }
      })
    }
  }
  const resendOtp = () => {
    const request = {
      user_name: loginResponse.user_name,
      loglogin_id: loginResponse.loglogin_id,
    }

    dispatch(resendOTP(request)).then((res) => {
      const { response, type } = res
      if (type === 'RESEND_OTP_SUCCESS') {
        setLoginResponse((prev) => ({
          ...prev,
          otp_id: response.otp_id,
          refno: response.ref_code,
        }))
      }
    })
    setTimeCounter(initialTimeCounterForResendOtp)
    setIsCountingDown(true)
  }

  // other function
  const gotoRegister = () => {
    navigate('/Register')
  }
  const formattedTime = (time) => {
    return `${String(time).padStart(2, '0')}`
  }

  const OpenAdvice = () => {
    window.open('https://coe.or.th/documentform/?_form_category_s=3fb5736559bf4070ee87c5904ff2171f', '_blank')
  }
  return (
    <Div>
      <form>
        <div className="img-container">
          <img src={logoIcon} alt="loco icon" />
        </div>
        <div className="title">กรอกข้อมูลเพื่อเข้าสู่ระบบ</div>
        <div className="desc">
           สำหรับองค์กรแม่ข่าย
        </div>

        <div className="login-form">
          <Field className="field" label="เลขบัตรประจำตัวประชาชน">
            {
              /*
              <TextField
                placeholder="ระบุเลขบัตรประจำตัวประชาชน 13 หลัก"
                value={UserName}
                onChange={(e) => setUserName(e.target.value)}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                  }
                }}
              />
              */
            }
            <DivStyleInputMask>
              <InputMask 
                placeholder="ระบุเลขบัตรประจำตัวประชาชน 13 หลัก"
                mask="9-9999-99999-99-9" 
                onChange={(e) => setLoginData({ ...LoginData, UserName: e.target.value })}
                value={LoginData.UserName} 
              />
            </DivStyleInputMask>
            {LoginDataError.UserName.show_errorMessage == true && (
                <DivErrorMessage>{LoginDataError.UserName.errorMessage}</DivErrorMessage>
            )}


          </Field>
          <Field className="field" label="เบอร์โทรศัพท์ (เพื่อรับ OTP)">
            {
              /*
              <TextField
                placeholder="ระบุเบอร์โทรศัพท์"
                value={PhoneNo}
                onChange={(e) => setPhoneNo(e.target.value)}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                  }
                }}
              />
              */
            }
            <DivStyleInputMask>
              <InputMask 
                placeholder="ระบุเบอร์โทรศัพท์"
                mask="(999)-999-9999" 
                onChange={(e) => setLoginData({ ...LoginData, PhoneNo: e.target.value })}
                value={LoginData.PhoneNo} 
              />
            </DivStyleInputMask>
            {LoginDataError.PhoneNo.show_errorMessage == true && (
                <DivErrorMessage>{LoginDataError.PhoneNo.errorMessage}</DivErrorMessage>
            )}
          </Field>

          <Button
            className="button-login"
            type="submit"
            onClick={handleLogin}
          >
            เข้าสู่ระบบ สำหรับองค์กรแม่ข่าย
          </Button>
          <div className="desc">
            ไม่พบข้อมูลในระบบ?
          </div>
          <ButtonOutline
            className="button-login"
            onClick={gotoRegister}
          >
            สร้างบัญชีผู้ใช้
          </ButtonOutline>

          <div className="warning-container">
            <div className="d-flex">
              <div className="icon-warning-container">
                <div className="user_img_char_1" >
                <PiWarningCircleBold size={25} />
                </div>
                
              </div>
              <div className="warning-message-container">
                <div className="headline-warning">
                ตั้งแต่วันที่ 2 ก.ค. 2567 เป็นต้นไป
                </div>
                <div>องค์กรแม่ข่ายจะต้องสร้างบัญชีผู้ใช้ ก่อนเข้าสู่ระบบ</div>
                <div>เพื่อความปลอดภัยของบัญชี</div>
              </div>
            </div>
          </div>
          <div className="advice_text" onClick={() => OpenAdvice()}>
            ศึกษารายละเอียดการใช้งานเพิ่มเติม
          </div>
        </div>

        <div className="copyright">version {VERSION}</div>
      </form>

      {/* Modal */}
      <Modal open={otpModal} disableBackdropClick>
        <OTPContainer>
          <FlexContainer className="justify-content-end">
            <IoMdClose size={20} className="icon" onClick={closeOtpModal} />
          </FlexContainer>
          <FlexContainer className="justify-content-center">
            <img src={otpIcon} alt="otp icon" />
          </FlexContainer>
          <h2 className="heading">กรุณากรอกรหัส OTP</h2>
          <p>
            รหัส OTP ได้ถูกส่งไปที่ <span className="css_phone_no">{loginResponse?.phone_no}</span>
          </p>
         
          <p className="mb-20-px">
            เหลือเวลาทำรายการ {formattedTime(minutes)}:{formattedTime(seconds)} นาที
          </p>

          <OtpInputComponent setFullOtp={setFullOtp} />

          <p className="text-left">(Ref: {loginResponse?.refno})</p>
          <p className="text-left">
           ไม่ได้รับรหัส?{' '}
            {isCountingDown ? (
              <span>ขออีกครั้งใน {timeCounter} วินาที</span>
            ) : (
              <span className="highlight-text" onClick={resendOtp}>
                ขอรหัส OTP อีกครั้ง
              </span>
            )}
          </p>
          <p className="text-left mb-32-px">(กรอกรหัสผิดติดต่อกัน 5 ครั้ง ระบบจะหยุดพัก 60นาที)</p>

          <FlexContainer className="justify-content-space-between">
            <ButtonSecondary className="w-48" onClick={closeOtpModal}>
              ยกเลิก
            </ButtonSecondary>
            <Button className="w-48" onClick={verifyOtp}>
              ตรวจสอบ
            </Button>
          </FlexContainer>
        </OTPContainer>
      </Modal>

      {/* Dialog */}
      <DialogConfirmV2
        open={Boolean(ModalConfirmDialog)}
        onClose={() => setModalConfirmDialog(false)}
        onNo={() => setModalConfirmDialog(false)}
        onSubmit={gotoRegister}
        icon={warningIcon}
        title={ConfirmMessageModal.headline}
        nameItem={ConfirmMessageModal.message}
        textYes='ลงทะเบียนผู้ใช้ใหม่'
        textNo='ยกเลิก'
      />


      <DialogSuccess
        open={Boolean(successModal)}
        onClose={() => setSuccessModal(false)}
        onSubmit={confirmSuccess}
        icon={successIcon}
        title={successMessageModal.headline}
        description={successMessageModal.message}
        textYes={t('done')}
      />
      <DialogFail
        open={Boolean(failModal)}
        onClose={() => setFailModal(false)}
        onSubmit={confirmFailModal}
        icon={failIcon}
        title={failMessageModal.headline}
        description={failMessageModal.message}
        textYes={t('ok')}
      />
      <DialogFail
        open={Boolean(failModal2)}
        onClose={() => {
          setTargetTime(null)
          setFailModal2(false)
        }}
        onSubmit={confirmFailModal2}
        icon={failIcon}
        title={failMessageModal2.headline}
        html={
          <div>
            สามารถทำรายการใหม่ได้อีก <span className="css_text_focus">{timeDifference}</span> นาที
          </div>
        }
        textYes={t('ok')}
      />

    </Div>
  )
}

export default Login
