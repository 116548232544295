import styled from 'styled-components'
import ReactPaginate from 'react-paginate'

import Button from '../form/button/Button'
import ButtonOutline from '../form/button/ButtonOutline'
import ButtonSecondary from '../form/button/ButtonSecondary'

import iconNext from '../../assets/images/icon-next-white.svg'
import iconBack from '../../assets/images/icon-back.svg'

import PropTypes from 'prop-types'

const StyledReactPaginate = styled(ReactPaginate)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;

  li {
    list-style: none;

    &.disabled {
      opacity: 0.4;
      cursor: default;
    }

    &.previous {
      a {
        cursor: default;
      }

      button {
        position: absolute;
        left: 0;
      }
    }

    &.next {
      a {
        cursor: default;
      }

      button {
        right: 0;
        position: absolute;
      }
    }

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--Gray-600);
      font-size: 14px;
      font-weight: 500;
      width: 40px;
      height: 40px;
      cursor: pointer;
    }

    &.selected {
      a {
        color: var(--Primary-700);
        background: var(--Primary-50);
        border-radius: 50%;
      }
    }
  }

  a {
    text-decoration: none !important;
  }
  .button_previousLabel {
    color: var(--Button-Pagination-Previous);
  }
  .button_Next {
    color: var(--Button-Pagination-Next);
  }
`

const PaginationV2 = ({ pageCount, page = 1, onPageChange, ...props }) => {
  const nextLabel = (
    <Button prepend={<img src={iconNext} />} className="button_Next">
      Next
    </Button>
  )
  const previousLabel = (
    <ButtonSecondary append={<img src={iconBack} />} className="button_previousLabel">
      Previous
    </ButtonSecondary>
  )

  return (
    <StyledReactPaginate
      breakLabel="..."
      nextLabel={nextLabel}
      onPageChange={({ selected }) => onPageChange(selected + 1)}
      pageRangeDisplayed={3}
      pageCount={pageCount}
      previousLabel={previousLabel}
      forcePage={page - 1}
      renderOnZeroPageCount={null}
      {...props}
    />
  )
}

PaginationV2.propTypes = {
  pageCount: PropTypes.number,
  page: PropTypes.number,
  onPageChange: PropTypes.func,
}

export default PaginationV2
