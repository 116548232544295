import { CgFileDocument } from 'react-icons/cg'
import { FiSettings, FiBox, FiUsers, FiGitPullRequest } from 'react-icons/fi'
import { LuCalendarCheck } from 'react-icons/lu'
import { BsGraphUpArrow } from 'react-icons/bs'
import { TbTargetArrow } from 'react-icons/tb'
import { GoWorkflow } from 'react-icons/go'

export const headerAccountManage = {
  name: 'จัดการบัญชีผู้ใช้',
  name_th: 'จัดการบัญชีผู้ใช้',
}

export const headerActivity = {
  name: 'กิจกรรมขององค์กรแม่ข่าย',
  name_th: 'กิจกรรมขององค์กรแม่ข่าย',
}
