import { getAPI, postAPI } from '../../utils/api'
import { removeAdminData, setAdminData } from '../../utils/common'
import { LOGIN_ADMIN,  VERIFY_OTP, RESEND_OTP } from '../actionTypes'
import { showLoading, hideLoading } from '../../redux/actions/loading'
import { VERSION } from '../../configs/app'

export const loginUser = (UserName, PhoneNo) => async (dispatch) => {
  //--- ตัว login version ใหม่
  try {
    dispatch(showLoading())
    dispatch({ type: LOGIN_ADMIN.REQUEST })
    const data = await postAPI({
      url: '/api/user/Authen',
      data: {
        system : 'user_login_org',
        user_name: UserName,
        phone_no: PhoneNo,
        client_ip : '192.168.0.1',
        client_ip_country : 'Thailand',
      },
    })

    return { type: 'loginUser_SUCCESS', data_info: data }
  } catch (err) {

    return { type: 'loginUser_ERROR' }

  } finally {
    dispatch(hideLoading())
  }
}

export const verifyOtpForLogin = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: VERIFY_OTP.REQUEST })
    const headersForOtp = {
      Authorization: null,
      'Content-Type': 'application/json',
    }
    const data_result = await postAPI({
      url: '/api/user/verifyOTPMobile',
      data,
      headers: headersForOtp,
    })

    return { type: 'verifyOtpForLogin_SUCCESS', data_info: data_result }

  } catch (err) {
    return { type: 'verifyOtpForLogin_ERROR' , data_info: err.response?.data}
  } finally {
    dispatch(hideLoading())
  }
}

export const resendOTP = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: RESEND_OTP.REQUEST })
    const headersForOtp = {
      Authorization: null,
      'Content-Type': 'application/json',
    }
    const response_result = await postAPI({
      url: '/api/user/requestOTPMobile',
      data,
      headers: headersForOtp,
    })

    return {
      type: RESEND_OTP.SUCCESS,
      response: response_result,
    }
  } catch (err) {
    return dispatch({
      type: RESEND_OTP.FAILURE,
      error: err,
      showAlert: true,
      alertMessage: err?.message,
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const getORGRefUser = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: VERIFY_OTP.REQUEST })
    const UserToken = localStorage.getItem('UserToken');
    const headersForOtp = {
      Authorization: `Bearer ${UserToken}`,
      'Content-Type': 'application/json',
    }
    const data_result = await postAPI({
      url: '/api/org/getORGRefUser',
      data,
      headers: headersForOtp,
    })

    return { type: 'getORGRefUser_SUCCESS', data_info: data_result }

  } catch (err) {
    return { type: 'getORGRefUser_ERROR' }
  } finally {
    dispatch(hideLoading())
  }
}

export const OrgUserAuthen = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: LOGIN_ADMIN.REQUEST })
    const headersForOtp = {
      Authorization: null,
      'Content-Type': 'application/json',
    }
    const data_result = await postAPI({
      url: '/api/org/user_authen',
      data,
      headers: headersForOtp,
    })

    return { type: 'OrgUserAuthen_SUCCESS', data_info: data_result }

  } catch (err) {
    return { type: 'OrgUserAuthen_ERROR' }
  } finally {
    dispatch(hideLoading())
  }
}

export const logoutAdmin = () => () => {
  removeAdminData()
  window.location.href = '/login'
}

export const UserRegister = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: LOGIN_ADMIN.REQUEST })
    const headersForOtp = {
      Authorization: null,
      'Content-Type': 'application/json',
    }
    const data_result = await postAPI({
      url: '/api/user/Register',
      data,
      headers: headersForOtp,
    })

    return { type: 'UserRegister_SUCCESS', data_info: data_result }

  } catch (err) {
    return { type: 'UserRegister_ERROR' }
  } finally {
    dispatch(hideLoading())
  }
}

export const Apiverify_3rdToken = (tokenadmin) => async (dispatch) => {
  //--- ตัว login version ใหม่
  try {
    dispatch(showLoading())
    dispatch({ type: LOGIN_ADMIN.REQUEST })
    const data_result = await postAPI({
      url: '/api/org/verify_token_org',
      data: {
        tokenadmin: tokenadmin
      },
    })

    return { type: 'Apiverify_3rdToken_SUCCESS', data_info: data_result }
  } catch (err) {

    return { type: 'Apiverify_3rdToken_ERROR' }

  } finally {
    dispatch(hideLoading())
  }
}














