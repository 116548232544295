import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useParams,useNavigate } from 'react-router-dom'
import { Box, Divider, Stack } from '@mui/material'
import languageEncoding from "detect-file-encoding-and-language";
import { showLoading, hideLoading } from 'redux/actions/loading.js'
import { BASE_API } from '../../configs/app'
import { v4 as uuidv4 } from 'uuid'
import * as XLSX from 'xlsx';

import {  getAdminUser,DateDisplayDDMMBBBHHmm,getAdminToken } from '../../utils/common'
import { ApiGetVW_event_user_compare,ApiEventCompareUser,ApiAdjuctEventUser } from 'redux/actions/activityCpd'
import { selectActivityCPD } from 'redux/selectors'

import Button from '../../components/form/button/Button'
import ButtonOutline from 'components/form/button/ButtonOutline'
import TableV2 from '../../components/common/TableV2'
import DialogSuccess from 'components/dialog/DialogSuccess'
import DialogFail from 'components/dialog/DialogFail'
import SidePanel from 'components/common/SidePanel'
import NewUserEventCompare from 'pages/activity/NewUserEventCompare'
import Modal from '../../components/common/Modal'

import iconPlus from '../../assets/images/icon-plus.svg'
import { FaBullseye } from 'react-icons/fa'
import { GrStatusGoodSmall } from 'react-icons/gr'
import iconDelete from '../../assets/images/icon-bin-red.svg'
import { TbArrowBackUp } from 'react-icons/tb'
import { PiWarningCircleBold, PiFloppyDiskBold } from 'react-icons/pi'
import iconUpload from '../../assets/images/icon-upload-round.svg'
import iconFile from '../../assets/images/icon_file_excel.png'
import iconBin from '../../assets/images/icon-bin.svg'
import IconButton from '../../components/common/IconButton'
import successIcon from 'assets/images/success-icon.png'
import failIcon from 'assets/images/fail-icon.png'
import warningIcon from 'assets/images/warning-icon.png'

import axios from 'axios'
import { BASE_API_UPLOAD } from 'configs/app'
import { useDropzone } from 'react-dropzone'
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress'
import { styled as styled_mui } from '@mui/material/styles'
const BorderLinearProgress = styled_mui(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}))

const Div = styled.div`
  .box_header{
    display: flex;
    justify-content: space-between;
  }
  .content-title {
    font-size: 36px;
    font-weight: 600;
    color: var(--Base-Font-Title-Color);
  }
  .content-title-text {
    color: var(--Base-Font-Title-SUB-Color);
    font-size: 18px;
  }
  .css_button_add{
    display: inline-block;
    align-self: flex-end;
  }
  .border_header {
    border-bottom: 1px solid rgb(234, 236, 240);
    margin-bottom: 21px;
    margin-top: 21px;
  }
  .status-badge {
    width: fit-content;
    padding: 2px 6px;
    text-align: center;
    font-weight: 500;
    border-radius: 16px;
    color: #475467;
    background: #f5f5f4;
    border: 1px #e7e5e4 solid;

    &.expire {
      color:#B42318;
      background: #FEF3F2;
      border: 1px #FECDCA solid;
    }
    &.active {
      color:#067647;
      background: #ECFDF3;
      border: 1px #ABEFC6 solid;
    }

    .status {
      font-size: 0.6rem;
      margin-right: 0.2rem;
      color: #475467;
      

      &.expire {
        color:#B42318;
      }
      &.active {
        color:#067647;
      }

    }
  }

  .table-wrapper {
    margin-top: 24px;
    border: 1px solid #eaecf0;
    box-shadow: var(--Shadow-xs);
    border-radius: 8px;
    background-color: #ffffff;

    .table-header {
      display: flex;
      justify-content: space-between;
      padding: 16px 14px;
      border-radius: 8px 8px 0 0;
      background: var(--Table-Bg-Header);

      .group {
        display: flex;
        align-items: baseline;
      }

      .table-title {
        font-size: 18px;
        font-weight: 600;
        color: var(--Gray-900);
      }
      .sub-heading-table {
        color: #475467;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
      }

      .table-total {
        margin-left: 16px;
        font-size: 12px;
        font-weight: 500;
        padding: 2px 8px;
        color: var(--BADGE-Table-Font);
        background: var(--BADGE-Table-Bg);
        border-radius: 16px;
        border: 1px var(--BADGE-Table-Border) solid;
      }
    }

    .create-bt {
      text-decoration: none;
    }
  }
  .css_magin_top_10 {
    margin-top: 10px;
  }
  .css_magin_top_24{
    margin-top: 24px;
  }
  .border_header {
    border-bottom: 1px solid rgb(234, 236, 240);
  }
  .css_magin_top_16 {
    margin-top: 16px;
  }

  .cursor_pointer {
    cursor: pointer;
  }

`

const DivTemplate = styled.div`
font-size: 14px;
font-style: normal;
font-weight: 500;
color : #101828;
.flex_display{
    display: flex;
    gap : 10px;
}
.dowload_template{
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    color : #0BA5EC;
    cursor: pointer;
}
.box_header{
    display: flex;
    justify-content: space-between;
}
.box_warning{
    width: 50%;
}
`

const DivWarning = styled.div`
    color: #475467;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    border-radius: 0.75rem;
    border: 1px solid #475467;
    background: #FFFFFF;
    padding: 1rem;
    .d-flex {
        display: flex;
        align-items: flex-start;
    }
    .icon-warning-container {
    }

    .warning-message-container {
      .headline-warning {
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        color : #344054;
      }
      .detail-warning{
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        color : #667085;
        margin: 5px 0px;
      }
      .detail_click{
        color : #A91F23;
        cursor: pointer;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        margin-top : 12px;
      }
    }
    .box_icon {
        border-radius: 50%;
        background-color: #FEF0C7;
        width: 48px;
        height: 48px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin-right: 12px;
        color: #A91F23;
        cursor: pointer;
      }
`

const DivUpload = styled.div`
font-size: 14px;
font-style: normal;
font-weight: 500;
color : #101828;
.flex_display{
    display: flex;
    gap : 10px;
}
.sub-header{
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color : #475467;
}
.box_header{
    display: flex;
    justify-content: space-between;
}
.box_upload{
    width: 50%;
}

.upload-zone {
    padding: 14px;
    color: var(--Gray-600);
    font-size: 14px;
    background: var(--Base-White);
    border: 1px solid var(--Gray-300);
    box-shadow: var(--Shadow-xs);
    border-radius: 8px;
    text-align: center;
    cursor: pointer;

    img {
        width: 40px;
    }

    p {
        margin: 0;

        b {
            color: var(--Primary-700);
        }
    }
}

.upload-list {
    padding: 0px 0px;
    .file {
      width: 100%;
      display: inline-flex;
      padding: 14px;
      margin-top: 16px;
      background: var(--Base-White);
      border: 1px solid var(--Gray-300);
      box-shadow: var(--Shadow-xs);
      border-radius: 8px;
    }

    .icon-wrapper {
      width: 32px;
    }

    .detail-wrapper {
      flex: 1;
      margin: 0 14px;
      font-size: 14px;
      overflow: hidden;

      .file-name {
        margin-bottom: 2px;
        text-overflow: ellipsis;
        overflow: hidden;

        a {
          font-weight: 500;
          color: var(--Gray-700);
        }
      }
    }
}
`

const DivModalProgress = styled.div`
  min-width: 450px;
  padding: 16px 16px;
  font-size: 18px;
  font-weight: 600;
  color: var(--Gray-900);
  .warning_bro{
    color: #a81e23;
  }
  .progress_display{
    margin-top: 16px;
  }
`

const EventCompareUser = () => {
    const dispatch = useDispatch()
    const user = getAdminUser()
    const { id } = useParams()
    const navigate = useNavigate()
    const token = getAdminToken()

    const [EventCode, setEventCode] = useState(null)
    const [EventField, setEventField] = useState(null)
    const [link_template_file, setlink_template_file] = useState(null)
    const [isfetchData, setisfetchData] = useState(false);
    const [isfetchData_2, setisfetchData_2] = useState(false);
    const [isfetchData_3, setisfetchData_3] = useState(false);
    const [isfetchData_4, setisfetchData_4] = useState(false);

    const { activityInfo } = useSelector(selectActivityCPD)

    useEffect(() => {
      if (activityInfo.id) {
        setEventCode(activityInfo.code)
        setEventField(activityInfo.event_field)
        setlink_template_file(activityInfo.link_template_file)
      }
    }, [activityInfo])

    useEffect(() => {
      if (isfetchData && isfetchData_2 && isfetchData_3 && isfetchData_4) {
        dispatch(hideLoading());
      }
    }, [isfetchData,isfetchData_2,isfetchData_3,isfetchData_4])

    const limit_data = 10;
    //--- ST รายชื่อที่ Scan QR Code ตรงกับรายชื่อที่ลงทะเบียนกับแม่ข่าย
    const [total, settotal] = useState(0)
    const [page, setPage] = useState(1)
    const [totalPages, setTotalPages] = useState(0)
    const [TableCellData, setTableCellData] = useState([])
    const TableColumnData = [
        {
        Header: 'ลำดับ',
        accessor: 'no',
        disableSortBy: false,
        },
        {
            Header: 'เลขที่สมาชิก',
            accessor: 'user_no',
            disableSortBy: false,
        },
        {
            Header: 'ชื่อ นามสกุล',
            accessor: 'fullname',
            disableSortBy: false,
        },
        {
            Header: 'ผลการตรวจสอบ',
            accessor: 'status',
            disableSortBy: false,
        },
        {
            Header: 'นำเข้าโดย',
            accessor: 'create_by_fullname',
            disableSortBy: false,
        },
        {
            Header: 'เวลาที่นำเข้าข้อมูล',
            accessor: 'created_at',
            disableSortBy: false,
        },
        {
            accessor: 'buttonDelete',
            disableSortBy: false,
        },
    ]

    const fetchData = useCallback(
        async (filter) => {

        dispatch(ApiGetVW_event_user_compare(filter)).then(({ type,data_info }) => {
            setisfetchData(true);
            if(type.endsWith('_SUCCESS')){
            if (data_info.total > 0) {
                const totalPage = Math.ceil(data_info.total / limit_data)
                settotal(data_info.total)
                setTotalPages(totalPage)

                const newTableCellData = data_info.data.map((v, index) => ({
                    no: limit_data * page - (9 - index),
                    user_no: v.user_no ? v.user_no : '-',
                    fullname: v.first_name ? v.first_name + ' ' + v.last_name : v.full_name ? v.full_name : '-',
                    status: (
                        v.status_name ? 
                        <div
                        className="status-badge"
                        style={{
                            borderColor: v.status_badge_color?.border,
                            backgroundColor: v.status_badge_color?.background,
                        }}
                        >
                        <GrStatusGoodSmall 
                            className="status"
                            style={{
                            color: v.status_badge_color?.color,
                            }}
                        />
                        {v.status_name}
                        </div>
                        : 
                        <div >-</div>
                    ),
                    create_by_fullname: v.create_by_fullname ? v.create_by_fullname : '-',
                    created_at: v.createdAt ? DateDisplayDDMMBBBHHmm(v.createdAt) : '-',
                    buttonDelete: <img className="cursor_pointer" src={iconDelete} title="Delete" onClick={() => DelData(v.id)} />,
                }))
                setTableCellData(newTableCellData)
            }else{
                settotal(0);
                setPage(0);
                setTotalPages(0);
                setTableCellData([]);
            }
            
            }
        })

        },
        [dispatch]
    )

    useEffect(() => {
        const filter = {
        skip: 0,
        limit: limit_data,
        event_id : id,
        status_id : 1
        }
        fetchData(filter)
    }, [fetchData, limit_data])


    const onChangePaginationAttendeeAttend = async (value) => {
        setisfetchData(false);
        const filter = {
        skip: (value.page  - 1) * limit_data, 
        limit: limit_data,
        event_id : id,
        status_id : 1
        }
        fetchData(filter)
        setPage(value.page)
    }
    //--- ED รายชื่อที่ Scan QR Code ตรงกับรายชื่อที่ลงทะเบียนกับแม่ข่าย

    //--- ST รายชื่อที่ Scan QR Code แต่ไม่มีรายชื่อที่ลงทะเบียนกับแม่ข่าย
    const [total_2, settotal_2] = useState(0)
    const [page_2, setPage_2] = useState(1)
    const [totalPages_2, setTotalPages_2] = useState(0)
    const [TableCellData_2, setTableCellData_2] = useState([])
    const TableColumnData_2 = [
        {
        Header: 'ลำดับ',
        accessor: 'no',
        disableSortBy: false,
        },
        {
            Header: 'เลขที่สมาชิก',
            accessor: 'user_no',
            disableSortBy: false,
        },
        {
            Header: 'ชื่อ นามสกุล',
            accessor: 'fullname',
            disableSortBy: false,
        },
        {
            Header: 'ผลการตรวจสอบ',
            accessor: 'status',
            disableSortBy: false,
        },
        {
            Header: 'นำเข้าโดย',
            accessor: 'create_by_fullname',
            disableSortBy: false,
        },
        {
            Header: 'เวลาที่นำเข้าข้อมูล',
            accessor: 'created_at',
            disableSortBy: false,
        },
        {
            accessor: 'buttonDelete',
            disableSortBy: false,
        },
    ]

    const fetchData_2 = useCallback(
        async (filter) => {

        dispatch(ApiGetVW_event_user_compare(filter)).then(({ type,data_info }) => {
            setisfetchData_2(true);
            if(type.endsWith('_SUCCESS')){
            if (data_info.total > 0) {
                const totalPage = Math.ceil(data_info.total / limit_data)
                settotal_2(data_info.total)
                setTotalPages_2(totalPage)

                const newTableCellData = data_info.data.map((v, index) => ({
                    no: limit_data * page - (9 - index),
                    user_no: v.user_no ? v.user_no : '-',
                    fullname: v.first_name ? v.first_name + ' ' + v.last_name : v.full_name ? v.full_name : '-',
                    status: (
                        v.status_name ? 
                        <div
                        className="status-badge"
                        style={{
                            borderColor: v.status_badge_color?.border,
                            backgroundColor: v.status_badge_color?.background,
                        }}
                        >
                        <GrStatusGoodSmall 
                            className="status"
                            style={{
                            color: v.status_badge_color?.color,
                            }}
                        />
                        {v.status_name}
                        </div>
                        : 
                        <div >-</div>
                    ),
                    create_by_fullname: v.create_by_fullname ? v.create_by_fullname : '-',
                    created_at: v.createdAt ? DateDisplayDDMMBBBHHmm(v.createdAt) : '-',
                    buttonDelete: <img className="cursor_pointer" src={iconDelete} title="Delete" onClick={() => DelData(v.id)} />,
                }))
                setTableCellData_2(newTableCellData)
            }else{
                settotal_2(0);
                setPage_2(0);
                setTotalPages_2(0);
                setTableCellData_2([]);
            }
            
            }
        })

        },
        [dispatch]
    )

    useEffect(() => {
        const filter = {
        skip: 0,
        limit: limit_data,
        event_id : id,
        status_id : 2
        }
        fetchData_2(filter)
    }, [fetchData_2, limit_data])


    const onChangePaginationAttendeeAttend_2 = async (value) => {
        setisfetchData_2(false);
        const filter = {
        skip: (value.page  - 1) * limit_data, 
        limit: limit_data,
        event_id : id,
        status_id : 2
        }
        fetchData_2(filter)
        setPage_2(value.page)
    }
    //--- ED รายชื่อที่ Scan QR Code แต่ไม่มีรายชื่อที่ลงทะเบียนกับแม่ข่าย

    //--- ST รายชื่อที่ไม่ได้ Scan QR Code แต่มีรายชื่อที่ลงทะเบียนกับแม่ข่าย
    const [total_3, settotal_3] = useState(0)
    const [page_3, setPage_3] = useState(1)
    const [totalPages_3, setTotalPages_3] = useState(0)
    const [TableCellData_3, setTableCellData_3] = useState([])
    const TableColumnData_3 = [
        {
        Header: 'ลำดับ',
        accessor: 'no',
        disableSortBy: false,
        },
        {
            Header: 'เลขที่สมาชิก',
            accessor: 'user_no',
            disableSortBy: false,
        },
        {
            Header: 'ชื่อ นามสกุล',
            accessor: 'fullname',
            disableSortBy: false,
        },
        {
            Header: 'ผลการตรวจสอบ',
            accessor: 'status',
            disableSortBy: false,
        },
        {
            Header: 'นำเข้าโดย',
            accessor: 'create_by_fullname',
            disableSortBy: false,
        },
        {
            Header: 'เวลาที่นำเข้าข้อมูล',
            accessor: 'created_at',
            disableSortBy: false,
        },
        {
            accessor: 'buttonDelete',
            disableSortBy: false,
        },
    ]

    const fetchData_3 = useCallback(
        async (filter) => {

            dispatch(ApiGetVW_event_user_compare(filter)).then(({ type,data_info }) => {
                setisfetchData_3(true);
                if(type.endsWith('_SUCCESS')){
                    if (data_info.total > 0) {
                        const totalPage = Math.ceil(data_info.total / limit_data)
                        settotal_3(data_info.total)
                        setTotalPages_3(totalPage)

                        const newTableCellData = data_info.data.map((v, index) => ({
                            no: limit_data * page - (9 - index),
                            user_no: v.user_no ? v.user_no : '-',
                            fullname: v.first_name ? v.first_name + ' ' + v.last_name : v.full_name ? v.full_name : '-',
                            status: (
                                v.status_name ? 
                                <div
                                className="status-badge"
                                style={{
                                    borderColor: v.status_badge_color?.border,
                                    backgroundColor: v.status_badge_color?.background,
                                }}
                                >
                                <GrStatusGoodSmall 
                                    className="status"
                                    style={{
                                    color: v.status_badge_color?.color,
                                    }}
                                />
                                {v.status_name}
                                </div>
                                : 
                                <div >-</div>
                            ),
                            create_by_fullname: v.create_by_fullname ? v.create_by_fullname : '-',
                            created_at: v.createdAt ? DateDisplayDDMMBBBHHmm(v.createdAt) : '-',
                            buttonDelete: <img className="cursor_pointer" src={iconDelete} title="Delete" onClick={() => DelData(v.id)} />,
                        }))
                        setTableCellData_3(newTableCellData)
                    }else{
                        settotal_3(0);
                        setPage_3(0);
                        setTotalPages_3(0);
                        setTableCellData_3([]);
                    }
                
                }
            })

        },
        [dispatch]
    )

    useEffect(() => {
        const filter = {
            skip: 0,
            limit: limit_data,
            event_id : id,
            status_id : 3
        }
        fetchData_3(filter)
    }, [fetchData_3, limit_data])


    const onChangePaginationAttendeeAttend_3 = async (value) => {
        setisfetchData_3(false);
        const filter = {
        skip: (value.page  - 1) * limit_data, 
        limit: limit_data,
        event_id : id,
        status_id : 3
        }
        fetchData_3(filter)
        setPage_3(value.page)
    }
    //--- ED รายชื่อที่ไม่ได้ Scan QR Code แต่มีรายชื่อที่ลงทะเบียนกับแม่ข่าย   

    //--- ST คนที่มีรายชื่อซ้ำจากในไฟล์
    const [total_4, settotal_4] = useState(0)
    const [page_4, setPage_4] = useState(1)
    const [totalPages_4, setTotalPages_4] = useState(0)
    const [TableCellData_4, setTableCellData_4] = useState([])
    const TableColumnData_4 = [
        {
        Header: 'ลำดับ',
        accessor: 'no',
        disableSortBy: false,
        },
        {
            Header: 'เลขที่สมาชิก',
            accessor: 'user_no',
            disableSortBy: false,
        },
        {
            Header: 'ชื่อ นามสกุล',
            accessor: 'fullname',
            disableSortBy: false,
        },
        {
            Header: 'ผลการตรวจสอบ',
            accessor: 'status',
            disableSortBy: false,
        },
        {
            Header: 'นำเข้าโดย',
            accessor: 'create_by_fullname',
            disableSortBy: false,
        },
        {
            Header: 'เวลาที่นำเข้าข้อมูล',
            accessor: 'created_at',
            disableSortBy: false,
        },
        {
            accessor: 'buttonDelete',
            disableSortBy: false,
        },
    ]

    const fetchData_4 = useCallback(
        async (filter) => {

            dispatch(ApiGetVW_event_user_compare(filter)).then(({ type,data_info }) => {
                setisfetchData_4(true);
                if(type.endsWith('_SUCCESS')){
                    if (data_info.total > 0) {
                        const totalPage = Math.ceil(data_info.total / limit_data)
                        settotal_4(data_info.total)
                        setTotalPages_4(totalPage)

                        const newTableCellData = data_info.data.map((v, index) => ({
                            no: limit_data * page - (9 - index),
                            user_no: v.user_no ? v.user_no : '-',
                            fullname: v.first_name ? v.first_name + ' ' + v.last_name : v.full_name ? v.full_name : '-',
                            status: '-',
                            create_by_fullname: v.create_by_fullname ? v.create_by_fullname : '-',
                            created_at: v.createdAt ? DateDisplayDDMMBBBHHmm(v.createdAt) : '-',
                            buttonDelete: <img className="cursor_pointer" src={iconDelete} title="Delete" onClick={() => DelData(v.id)} />,
                        }))
                        setTableCellData_4(newTableCellData)
                    }else{
                        settotal_4(0);
                        setPage_4(0);
                        setTotalPages_4(0);
                        setTableCellData_4([]);
                    }
                
                }
            })

        },
        [dispatch]
    )

    useEffect(() => {
        const filter = {
            skip: 0,
            limit: limit_data,
            event_id : id,
            status_id : 4
        }
        fetchData_4(filter)
    }, [fetchData_4, limit_data])


    const onChangePaginationAttendeeAttend_4 = async (value) => {
        setisfetchData_4(false);
        const filter = {
        skip: (value.page  - 1) * limit_data, 
        limit: limit_data,
        event_id : id,
        status_id : 4
        }
        fetchData_4(filter)
        setPage_4(value.page)
    }
    //--- ED คนที่มีรายชื่อซ้ำจากในไฟล์        

    const onReloadData = async () => {
        setisfetchData(false);
        setisfetchData_2(false);
        setisfetchData_3(false);
        setisfetchData_4(false);
        const filter_1 = {
            skip: 0,
            limit: limit_data,
            event_id : id,
            status_id : 1
        };
        fetchData(filter_1);
        const filter_2 = {
            skip: 0,
            limit: limit_data,
            event_id : id,
            status_id : 2
        };
        fetchData_2(filter_2);
        const filter_3 = {
            skip: 0,
            limit: limit_data,
            event_id : id,
            status_id : 3
        };
        fetchData_3(filter_3);
        const filter_4 = {
            skip: 0,
            limit: limit_data,
            event_id : id,
            status_id : 4
        };
        fetchData_4(filter_4);
    }

  const goBack = () => {
    navigate(`/Activity/OrgActivity/${id}`)
  }

  const DelData = (data) => {
    const sent_data_to_api = {
      event_user_compar_id : data,
    }
    dispatch(ApiAdjuctEventUser(sent_data_to_api)).then(({ type,data_info }) => {
      dispatch(showLoading());
      onReloadData();
    })
  }

  //--- ST Upload
  const initMessageModal = {
    headline: '',
    message: '',
  }
  const [failMessageModal, setFailMessageModal] = useState(initMessageModal)
  const [failModal, setFailModal] = useState(false);
  const [successModal, setsuccessModal] = useState(false)

  const [FileListData, setFileListData] = useState([])

  const [ProgressModal, setProgressModal] = useState(false)
  const [Progresscent, setProgresscent] = useState(0)

  const ProcessUploadFile = useCallback(
    async (data_to_api,file_data) => {
      setProgressModal(true);
      setProgresscent(0)
      const uuid = uuidv4();

      const num_loop = data_to_api.length / 20;
      let count_item = 0;
      let iIndex = 0;
      let iCheckSuccessApi = 0;
      
      for (iIndex; iIndex <= num_loop; iIndex++) {
        let user_list_sub = [];
        let sub_index = 0;
        if(count_item == data_to_api.length){
          break;
        }
        for (sub_index; sub_index < 20; sub_index++) {
          user_list_sub.push({
            ...data_to_api[count_item]
          })
          count_item = count_item + 1;
          if(count_item == data_to_api.length){
            break;
          }
        }
        if(user_list_sub.length > 0){
          try {
            const data = {
                uuid : uuid,
                event_id : id,
                user_list : user_list_sub,
                file_data : iIndex == 0 ? file_data : null
            }
            const result_data = await ApiUploadFile(data);
            if(result_data){
              iCheckSuccessApi = iCheckSuccessApi + 1;
              if(iCheckSuccessApi >= num_loop){
                setProgresscent(100);
                setProgressModal(false);
                const sent_data_to_api = {
                  event_id : id,
                  is_check_user : true
                }
                dispatch(ApiAdjuctEventUser(sent_data_to_api)).then(({ type,data_info }) => {
                  dispatch(showLoading());
                  onReloadData();
                })
              }else{
                setProgresscent(Math.floor((iCheckSuccessApi * 100) / num_loop));
              }
            }else{
              const result_data_2 = await ApiUploadFile(data);
              if(result_data_2){
                iCheckSuccessApi = iCheckSuccessApi + 1;
                if(iCheckSuccessApi >= num_loop){
                  setProgresscent(100);
                  setProgressModal(false);
                  const sent_data_to_api = {
                    event_id : id,
                    is_check_user : true
                  }
                  dispatch(ApiAdjuctEventUser(sent_data_to_api)).then(({ type,data_info }) => {
                    dispatch(showLoading());
                    onReloadData();
                  })
                }else{
                  setProgresscent(Math.floor((iCheckSuccessApi * 100) / num_loop));
                }
              }else{
                const result_data_3 = await ApiUploadFile(data);
                if(result_data_3){
                  iCheckSuccessApi = iCheckSuccessApi + 1;
                  if(iCheckSuccessApi >= num_loop){
                    setProgresscent(100);
                    setProgressModal(false);
                    const sent_data_to_api = {
                      event_id : id,
                      is_check_user : true
                    }
                    dispatch(ApiAdjuctEventUser(sent_data_to_api)).then(({ type,data_info }) => {
                      dispatch(showLoading());
                      onReloadData();
                    })
                  }else{
                    setProgresscent(Math.floor((iCheckSuccessApi * 100) / num_loop));
                  }
                }else{
                  setProgressModal(false);
                  dispatch(hideLoading());
                  setFailMessageModal({
                    headline: 'ไม่สามารถ Upload File ได้',
                    message: 'เนื่องจากข้อมูลไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง'
                  })
                  setFailModal(true);
                  break;
                }
              }
            }
          } catch (error) {
            setProgressModal(false);
            dispatch(hideLoading());
            setFailMessageModal({
              headline: 'ไม่สามารถ Upload File ได้',
              message: 'เนื่องจากข้อมูลไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง (catch)'
            })
            setFailModal(true);
            break;
          }

        }

      }

    },
    [dispatch]
  );

  const ApiUploadFile = async (api_data) => {
    try {
      const options = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "*",
          "Access-Control-Allow-Headers": "*",
        },
        credentials: "same-origin",
      };
      const urlAuthen = BASE_API + "/api/events/OrgEventCompareUser";
      const data = api_data;
      const result = await axios.post(urlAuthen, data, options);
      if (result.status === 201 || result.status === 200) {
        if(result.data.code == 200){
          return true;
        }else{
          return false;
        }
      }else{
        return false;
      }
    } catch (error) {
      return false;
    }

  }

  const ValidFileData = (file_obj,file_data) => {
    let reader = new FileReader();
    reader.readAsText(file_obj, "UTF-8");
    
    reader.onload = (evt) => {
        let text = evt.target.result;
        let AllTextArray = text.split(/\r?\n/);
        const first_data = AllTextArray[0];
        const first_arrdata_detail = first_data.split(",");
        if(first_arrdata_detail.length >= 2){
          if(first_arrdata_detail[1].toString().trim() == EventCode){
            let last_index = AllTextArray.length - 1;
            let iIndex = 3;
            let data_user = [];
            for (iIndex; iIndex <= last_index; iIndex++) {
                let Data_detail = AllTextArray[iIndex];
                let arrData_detail = Data_detail.split(",");
                try {
                  data_user.push({
                      first_name : arrData_detail[1].toString().trim(),
                      last_name : arrData_detail[2].toString().trim(),
                      is_attended : arrData_detail[3].toString().trim(),
                      is_exam : arrData_detail.length == 5 ? arrData_detail[4].toString().trim() : '-'
                  })
                } catch (error) {
                  break;
                }
            }
            ProcessUploadFile(data_user,file_data);
          }else{
            setFailMessageModal({
              headline: 'ไม่สามารถ Upload File ได้',
              message: 'รหัสกิจรรมไม่ตรงกับกิจกรรม'
            })
            setFailModal(true);
            return;
          }

        }else{
          setFailMessageModal({
            headline: 'ไม่สามารถ Upload File ได้',
            message: 'ไม่พบรหัสกิจกรรม'
          })
          setFailModal(true);
          return;
        }

    };
  }

  const handleFileReadXLSX = (file_obj,file_data) => {
    const file = file_obj;
    const reader = new FileReader();

    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
     
      if(jsonData.length > 0){
        const first_data = jsonData[0];
        if(first_data.length >= 2){
          if(first_data[1].toString().trim() == EventCode){
            let last_index = jsonData.length - 1;
            let iIndex = 3;
            let data_user = [];
            for (iIndex; iIndex <= last_index; iIndex++) {
              let Data_detail = jsonData[iIndex];
              try {
                data_user.push({
                    first_name : Data_detail[1].toString().trim(),
                    last_name : Data_detail[2].toString().trim(),
                    is_attended : Data_detail[3].toString().trim(),
                    is_exam : Data_detail.length == 5 ? Data_detail[4].toString().trim() : '-'
                })
              } catch (error) {
                break;
              }
            }
            if(data_user.length > 0){
              ProcessUploadFile(data_user,file_data);
            }
          }else{
            setFailMessageModal({
              headline: 'ไม่สามารถ Upload File ได้',
              message: 'รหัสกิจรรมไม่ตรงกับกิจกรรม'
            })
            setFailModal(true);
            return;
          }
        }else{
          setFailMessageModal({
            headline: 'ไม่สามารถ Upload File ได้',
            message: 'ไม่พบรหัสกิจกรรม'
          })
          setFailModal(true);
          return;
        }
      }else{
        setFailMessageModal({
          headline: 'ไม่สามารถ Upload File ได้',
          message: 'ไม่พบข้อมูลในไฟล์'
        })
        setFailModal(true);
        return;
      }
    };

    reader.readAsArrayBuffer(file);
  };

  //const accept = { "text/csv": [".csv"] };
  const accept = {};
  const disabled = false
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    accept,
    onDropAccepted: (value) => {
      value.forEach((v, index_data) => {
        const extension = v.name.replace(/^.*\./, "");
        if(extension == 'csv' || extension == 'xlsx'){
          languageEncoding(v).then((fileInfo) => {
            const check_encoding = extension == 'xlsx' ? true : fileInfo.encoding == 'UTF-8' ? true : false;
            if(check_encoding){
              let file_data = {
                key_file_id : null,
                bucketName : null,
                objectName : null,
                file_size : null,
                mime_type : null,
                file_extension : null,
                url: null,
                name: v.name,
                size: v.size,
                progress_percent: 0,
                progress_display: `-`,
                progress_is_error: false,
              }

              const formData = new FormData()
              formData.append('file', v)
              formData.append(
                'fileInfo',
                JSON.stringify({
                  name: v.name,
                  caption: v.name,
                  alternativeText: v.name,
                  folder: null,
                })
              )
              const options = {
                onUploadProgress: (progressEvent) => {
                  const { loaded, total } = progressEvent
                  let percent = Math.floor((loaded * 100) / total)

                  if (percent < 100) {
                    file_data = {
                      key_file_id : null,
                      bucketName : null,
                      objectName : null,
                      file_size : null,
                      mime_type : null,
                      file_extension : null,
                      url: null,
                      name: v.name,
                      size: v.size,
                      progress_percent: percent,
                      progress_display: `${convertByteToKB(loaded)}KB of ${convertByteToKB(total)}KB | ${percent}%`,
                      progress_is_error: false,
                    }
                    setFileListData([...FileListData, file_data])
                  } else {
                    file_data = {
                      key_file_id : null,
                      bucketName : null,
                      objectName : null,
                      file_size : null,
                      mime_type : null,
                      file_extension : null,
                      url: null,
                      name: v.name,
                      size: v.size,
                      progress_percent: percent,
                      progress_display: `กำลังรวบรวมไฟล์`,
                      progress_is_error: false,
                    }
                    setFileListData([...FileListData, file_data])
                  }
                },
              };

              const data = formData
              axios
                .post(BASE_API_UPLOAD + '/api/upload', data, options)
                .then((res) => {
                  file_data = {
                    key_file_id : res.data[0].id,
                    bucketName : null,
                    objectName : null,
                    file_size : v.size,
                    mime_type : null,
                    file_extension : null,
                    url: res.data[0].url,
                    name: v.name,
                    size: convertByteToKB(v.size),
                    progress_percent: 100,
                    progress_display: `OK`,
                    progress_is_error: false,
                  }
                  setFileListData([...FileListData, file_data]);
                  if(extension == 'csv'){
                    ValidFileData(v,file_data);
                  }else{
                    handleFileReadXLSX(v,file_data)
                  }                
                })
                .catch((error) => {
                  file_data = {
                    key_file_id : null,
                    bucketName : null,
                    objectName : null,
                    file_size : null,
                    mime_type : null,
                    file_extension : null,
                    url: null,
                    name: v.name,
                    size: convertByteToKB(v.size),
                    progress_percent: 100,
                    progress_display: `ไม่สามารถอัพโหลดไฟล์ได้`,
                    progress_is_error: true,
                  }
                  setFileListData([...FileListData, file_data])
                })
            }else{
              setFailMessageModal({
                headline: 'ไม่สามารถ Upload File ได้',
                message: 'เนื่องจากชนิดไฟล์ไม่ถูกต้อง'
              })
              setFailModal(true);
              return;
            }
          })
          //---- ED UploadFile

        }else{
          setFailMessageModal({
            headline: 'ไม่สามารถ Upload File ได้',
            message: 'กรุณานำเข้าไฟล์เฉพาะ .csv หรือ .xlsx เท่านั้น xlsx'
          })
          setFailModal(true);
        }

        

        

      })
    },
    disabled,
  })
  const convertByteToKB = (byte_value) => {
    return (byte_value / 1024).toFixed(2)
  }
  const onDeleteFile = () => {
    setFileListData([])
  }
  //--- ED Upload

  const ProcessAdjuctEventUser = () => {
    const sent_data_to_api = {
        event_id : id,
    }

    dispatch(ApiAdjuctEventUser(sent_data_to_api)).then(({ type,data_info }) => {
        if(type.endsWith('_SUCCESS')){
          setsuccessModal(true);
        }else{
          setFailMessageModal({
            headline: 'ไม่สามารถบันทึกได้',
            message: ''
          })
          setFailModal(true);
        }
    })
  }

  const handleClickOpenFile = () => {
    window.open(link_template_file, '_blank')
  }

  const [ModalNewUser, setModalNewUser] = useState(false)

  const OpenCreateModal = () => {
    setModalNewUser(true)
  }

  return (
    <Div>
      <header>
        <div className="box_header">
            <div>
                <div className="content-title">
                    แม่ข่ายตรวจสอบรายชื่อ
                </div>
                <div className="content-title-text">
                    Control รายชื่อผู้ลงทะเบียน
                </div>
                <div>{EventCode}</div>
            </div>
            <div className="css_button_add">
              <ButtonOutline append={<TbArrowBackUp size={20} />} onClick={goBack} >
                Back
              </ButtonOutline>
            </div>
        </div>
        <div className="css_magin_top_16"></div>
        <div className="border_header"></div>
        <div className="css_magin_top_16"></div>
        <DivTemplate>
            <div className="box_header">
                <div className="flex_display">
                    <div>
                        1.กรุณาเตรียมข้อมูลรายชื่อผู้เข้าร่วมกิจกรรมตามไฟล์ที่กำหนด
                    </div>
                    <div className="dowload_template"  onClick={handleClickOpenFile}>
                        Download Template
                    </div>
                </div>
                <div>
                    <Button append={<img src={iconPlus} />}  onClick={OpenCreateModal} >
                        เพิ่มรายชื่อ
                    </Button>
                </div>
            </div>

            <div className="css_magin_top_16"></div>   
            <div className="box_warning">
                <DivWarning>
                    <div className="d-flex">
                        <div className="icon-warning-container">
                            <div className="box_icon" >
                                <PiWarningCircleBold size={25} />
                            </div>                    
                        </div>
                        <div className="warning-message-container">
                            <div className="headline-warning">
                                คำแนะนำในการเตรียมข้อมูล
                            </div>
                            <div className="detail-warning">
                                1.ห้ามมีเว้นวรรค ด้านหน้า และด้านหลัง ของช่องชื่อ นามสกุล และไม่ต้องระบุคำนำหน้าชื่อ
                            </div>
                            <div className="detail-warning">
                                2.ห้ามคัดลอกชื่อ นามสกุลจากไฟล์ PDF
                            </div>
                            {EventField == 102 ? (
                                <div>
                                  <div className="detail-warning">
                                      3.ระบุการเข้าร่วมกิจกรรม กรณีเข้าร่วม ใส่ Y, กรณีไม่เข้าร่วมใส่ N
                                  </div>
                                  <div className="detail-warning">
                                      4.กรณีกิจกรรมในหมวด 102 สามารถบันทึกผลสอบของสมาชิก (กรณีผ่าน ใส่ Y, กรณีไม่ผ่านใส่ N)
                                  </div>
                                </div>
                              ) : (
                                <div className="detail-warning">
                                      3.ระบุการเข้าร่วมกิจกรรม กรณีเข้าร่วม ใส่ Y, กรณีไม่เข้าร่วมใส่ N
                                </div>
                              )
                            }
                        </div>
                    </div>                                    
                </DivWarning> 
            </div>

        </DivTemplate>
        <div className="css_magin_top_16"></div>
        <DivUpload>
            <div>
                2.กรุณาเตรียมข้อมูลรายชื่อผู้เข้าร่วมกิจกรรมตามไฟล์ที่กำหนด
            </div>
            <div className="sub-header">
              (สามารถนำ upload file ได้หลายครั้ง ในแต่ละครั้งจะเป็นการ upload ใหม่ทั้งหมด เฉพาะ csv หรือ xlsx เท่านั้น)
            </div>
            <div className="css_magin_top_16"></div>   
            <div className="box_upload">
                {(FileListData.length == 0) && (
                    <div {...getRootProps({ className: 'upload-zone' })} >
                        <input {...getInputProps()} />
                        <img src={iconUpload} />
                        <p>
                            <b>{'คลิกเพื่ออัพโหลด'}</b> {'หรือลากและวาง'}
                        </p>
                    </div>
                )}
                {(FileListData.length > 0) && (
                    <div className="upload-list">
                        {FileListData.map((v_file_list) => (
                            <div>
                                <div className="file">
                                    <div className="icon-wrapper">
                                        <img src={iconFile} />
                                    </div>
                                    <div className="detail-wrapper">
                                        <div className="file-name">{v_file_list.name}</div>
                                        <div className="file-progress">
                                            <BorderLinearProgress variant="determinate" value={v_file_list.progress_percent} />
                                        </div>
                                        <div className={v_file_list.progress_is_error ? 'file-size text_css_error' : 'file-size '}>
                                            {v_file_list.progress_display}
                                        </div>
                                    </div>
                                    {v_file_list.progress_percent == 100 && (
                                        <div className="action-wrapper">
                                         <IconButton src={iconBin} title="Delete"  onClick={() => onDeleteFile()} />
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </DivUpload>
        {/* รายชื่อที่ Scan QR Code ตรงกับรายชื่อที่ลงทะเบียนกับแม่ข่าย */}
        <div className="css_magin_top_16"></div>    
        <div className="table-wrapper">
          <div className="table-header">
            <div className="group">
              <div>
                <div className="table-title">รายชื่อที่ Scan QR Code ตรงกับรายชื่อที่ลงทะเบียนกับแม่ข่าย</div>
                <div className="sub-heading-table">รายชื่อที่ตรงกันจะได้รับคะแนน CPD</div>      
              </div>
              
              <div className="table-total">
                {total} คน
              </div>
            </div>
            
          </div>
          <div className="css_magin_top_10">
            <TableV2
              columns={TableColumnData}
              data={TableCellData}
              onStateChange={onChangePaginationAttendeeAttend}
              pageCount={totalPages}
              page={page}
              loading={false}
            />
          </div>
        </div>
        {/* รายชื่อที่ Scan QR Code แต่ไม่มีรายชื่อที่ลงทะเบียนกับแม่ข่าย */}
        <div className="css_magin_top_16"></div>    
        <div className="table-wrapper">
          <div className="table-header">
            <div className="group">
              <div>
                <div className="table-title">รายชื่อที่ Scan QR Code แต่ไม่มีรายชื่อที่ลงทะเบียนกับแม่ข่าย</div>
                <div className="sub-heading-table">รายชื่อสมาชิกในกลุ่มนี้จะโดนปรับคะแนน CPD</div>      
              </div>
              
              <div className="table-total">
                {total_2} คน
              </div>
            </div>
            
          </div>
          <div className="css_magin_top_10">
            <TableV2
              columns={TableColumnData_2}
              data={TableCellData_2}
              onStateChange={onChangePaginationAttendeeAttend_2}
              pageCount={totalPages_2}
              page={page_2}
              loading={false}
            />
          </div>
        </div>
        {/* รายชื่อที่ไม่ได้ Scan QR Code แต่มีรายชื่อที่ลงทะเบียนกับแม่ข่าย */}
        <div className="css_magin_top_16"></div>    
        <div className="table-wrapper">
          <div className="table-header">
            <div className="group">
              <div>
                <div className="table-title">รายชื่อที่ไม่ได้ Scan QR Code แต่มีรายชื่อที่ลงทะเบียนกับแม่ข่าย</div>
                <div className="sub-heading-table">รายชื่อสมาชิกในกลุ่มนี้จะได้รับคะแนน CPD</div>      
              </div>
              
              <div className="table-total">
                {total_3} คน
              </div>
            </div>
            
          </div>
          <div className="css_magin_top_10">
            <TableV2
              columns={TableColumnData_3}
              data={TableCellData_3}
              onStateChange={onChangePaginationAttendeeAttend_3}
              pageCount={totalPages_3}
              page={page_3}
              loading={false}
            />
          </div>
        </div>
        {/* คนที่มีรายชื่อซ้ำจากในไฟล์ */}
        <div className="css_magin_top_16"></div>    
        <div className="table-wrapper">
          <div className="table-header">
            <div className="group">
              <div>
                <div className="table-title">คนที่มีรายชื่อซ้ำจากในไฟล์</div>
                <div className="sub-heading-table">ไม่ถูกนำเข้าในตารางรายชื่อหลัก</div>      
              </div>
              
              <div className="table-total">
                {total_4} คน
              </div>
            </div>
            
          </div>
          <div className="css_magin_top_10">
            <TableV2
              columns={TableColumnData_4}
              data={TableCellData_4}
              onStateChange={onChangePaginationAttendeeAttend_4}
              pageCount={totalPages_4}
              page={page_4}
              loading={false}
            />
          </div>
        </div>

        <div className="css_magin_top_16"></div>  
        <Stack direction="row" justifyContent="flex-end">
            <Stack direction="row" spacing={2}>
              <ButtonOutline onClick={goBack}>ยกเลิก</ButtonOutline>
              <Button onClick={ProcessAdjuctEventUser}>บันทึก</Button>
            </Stack>
        </Stack>        
      </header>

      <SidePanel isOpen={ModalNewUser} setIsOpen={setModalNewUser} width={400}>
            <NewUserEventCompare 
              width={400} 
              onClose={() => setModalNewUser(false)} 
              onSubmit={() => {
                setModalNewUser(false)
                onReloadData()
              }}
              this_id={id}
              EventField={EventField}
            />
      </SidePanel>

      <DialogSuccess
        open={Boolean(successModal)}
        onClose={() => setsuccessModal(false)}
        onSubmit={() => setsuccessModal(false)}
        icon={successIcon}
        title={'บันทึกสำเร็จ'}
        subtitle={''}
        description={''}
        textYes={'ตกลง'}
      />

      <DialogFail
        open={Boolean(failModal)}
        onClose={() => setFailModal(false)}
        onSubmit={() => setFailModal(false)}
        icon={failIcon}
        title={failMessageModal.headline}
        nameItem={failMessageModal.message}
        description={''}
        textYes={'ตกลง'}
      />
      <Modal open={ProgressModal} disableBackdropClick>
        <DivModalProgress>
          <div>ระบบกำลังตรวจสอบรายชื่อ <span className="warning_bro">กรุณาอย่าปิดเบราว์เซอร์</span></div>
          <div className="progress_display">
            <BorderLinearProgress variant="determinate" value={Progresscent} />
          </div>
        </DivModalProgress>
      </Modal>

    </Div>
  )
}

export default EventCompareUser
