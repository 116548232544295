import styled from 'styled-components'
import { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { getTitle } from '../../redux/actions/master'
import { ApiAddORGActivity } from '../../redux/actions/organizations'

import TextField from '../../components/form/TextField'
import Dropdown from '../../components/form/Dropdown'
import InputTextArea from '../../components/widget/element/InputTextArea'
import Button from '../../components/form/button/Button'
import ButtonOutline from '../../components/form/button/ButtonOutline'
import question from '../../assets/images/question.png'
import DialogConfirmV2 from '../../components/dialog/DialogConfirmV2'
import DialogSuccess from '../../components/dialog/DialogSuccess'
import DialogFail from '../../components/dialog/DialogFail'
import Date from '../../components/form/Date'

import { GiCancel } from 'react-icons/gi'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import successIcon from '../../assets/images/success-icon.png'
import failIcon from '../../assets/images/fail-icon.png'
import { useTranslation } from 'react-i18next'

const Div = styled.div`
  .css_header_detail {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    font-size: 14px;
    .date_css{
      color: #7F56D9;
      font-weight: bold;
    }
  }
  .content_header {
    padding: 14px 14px;
    
    width : ${(props) => props.width}px;
    position: fixed;
    background: #FFFFFF;
    top: 0;
    right: 0;
    z-index: 1;
    .title {
      font-size: 18px;
      font-weight: 600;
      color: var(--Gray-900);
    }
  }
  .dialog-content {
    padding: 0px 14px;
    padding-top: 90px;
    padding-bottom: 106px;
    .title {
      font-size: 18px;
      font-weight: 600;
      color: var(--Gray-900);
    }
  }
  header {
    /*padding: 24px 14px;*/
    /*margin-top: 20px;*/
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }
  .icon {
    cursor: pointer;
  }
  .content-title {
    font-size: 20px;
    font-weight: 600;
    color: var(--Gray-900);
  }
  .wrp-tool-right {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
  }
  .topic_main {
    font-size: 14px;
    font-weight: 600;
    padding: 5px 0px;
    color: #344054;
  }
  .InputTextArea_css {
    width: 100%;
    font-size: 14px;
  }
  .dialog-footer {
    /*margin-bottom: 15px;*/
    text-align: end;
    position: fixed;
    right: 0;
    bottom: 0;
    width : ${(props) => props.width}px;
    background-color : #FFFFFF;
    padding: 14px;
    .button_margin {
      margin: 10px;
    }
  }

  .wrp-tool {
    display: flex;
    justify-content: space-between;
  }
  .wrp-tool-left {
    display: flex;
    align-items: center;
    /* background-color: red; */
  }
  .mb-1-rem {
    margin-bottom: 1rem;
  }
  .mr-1-rem {
    margin-right: 1rem;
  }
  .css_upload {
    margin-top: 10px;
  }
  .topic_fill{
    font-size: 14px;
    color: #344054;
    margin: 5px 0px;
  }
  .TextField_employee {
    width: 100%;
  }
  .content{
    margin:10px 0px;
  }
  .InputTextArea_css {
    width: 100%;
    font-size: 14px;
  }
  .prefix_icon{
    display: flex;
    align-items: center;
    margin-left: 5px;
  }
  .text_noti{
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
  .input-TextField{
    width: 100%;
  }
`

const DivErrorMessage = styled.div`
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
`

const acceptList = {
  images: {
    'image/jpeg': ['.jpeg', '.jpg'],
    'image/png': ['.png'],
  },
  files: {
    'text/csv': [],
    'application/pdf': [],
    'application/msword': [],
    'application/vnd.ms-excel': [],
    'application/zip': [],
  },
  videos: {
    'video/x-msvideo': [],
    'video/mp4': [],
    'video/mpeg': [],
    'video/webm': [],
  },
  audios: {
    'audio/mpeg': [],
    'audio/wav': [],
    'audio/webm': [],
  },
}

const NewActivity = ({ onClose, onSubmit,OrganizationId,ApplicationsId,...props  }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  
  const [EditData, setEditData] = useState({
    organizations_id : OrganizationId,
    application_id: ApplicationsId,
    activity_date: null,
    activity_name: null,
    activity_detail: null,
    activity_hours: null,
    activity_attendee_count: null,
  })
  
  const [EditDataError, setEditDataError] = useState({
    activity_date: {
      show_errorMessage: false,
      errorMessage: 'กรุณาระบุวันที่จัดกิจกรรม',
    },
    activity_name: {
        show_errorMessage: false,
        errorMessage: 'กรุณาระบุชื่อกิจกรรม',
    },
    activity_detail: {
        show_errorMessage: false,
        errorMessage: 'กรุณาระบุรายละเอียดการบรรยาย',
    },
    activity_hours: {
        show_errorMessage: false,
        errorMessage: 'กรุณาระบุจำนวนชั่วโมงอบรม',
    },
    activity_attendee_count: {
        show_errorMessage: false,
        errorMessage: 'กรุณาระบุจำนวนผู้เข้าร่วมกิจกรรม',
    },
  })
  


  const isValid = () => {
    let data_valid = EditData;
    let data_check_valid = EditDataError;
    let errors = []
    Object.keys(EditDataError).forEach((k) => {
      let data = data_valid[k]
      if (data_check_valid[k].errorMessage != '') {
        if (!data || data < 0) {
          errors.push(data_check_valid[k].errorMessage)
          data_check_valid[k].show_errorMessage = true
        } else {
            data_check_valid[k].show_errorMessage = false
        }
      }
    })
    setEditDataError({ ...EditDataError })
    return errors
  }

  const [ModalConfirmDialog, setModalConfirmDialog] = useState(false)
  const ConfirmDialog = () => {
    if (isValid().length == 0) {
        setConfirmMessageModal({
            headline : 'ยืนยันการทำรายการเพิ่มกิจกรรม',
            message : '',
        })
        setModalConfirmDialog(true)
    }
  }
  const [Titlesuccess, setTitlesuccess] = useState('')
  const [SubTitlesuccess, setSubTitlesuccess] = useState('')
  const [Descriptionsuccess, setDescriptionsuccess] = useState('')
  const [successModal, setsuccessModal] = useState(false)
  const [TitleFail, setTitleFail] = useState('')
  const [nameItemFail, setnameItemFail] = useState('')
  const [DescriptionFail, setDescriptionFail] = useState('')
  const [failModal, setFailModal] = useState(false)

  const [title_option, settitle_option] = useState([])
  const status_option = [
    { text: 'request', value: 3 },
    { text: 'active', value: 1 },
    { text: 'deny', value: 2 },
  ]
  const initMessageModal = {
    headline: '',
    message: '',
  }
  const [ConfirmMessageModal, setConfirmMessageModal] = useState(initMessageModal)

  const ProcessUpdate = () => {
    setModalConfirmDialog(false);

    
    const sent_data_api = [EditData];
    dispatch(ApiAddORGActivity(sent_data_api)).then(({ type,data_info }) => {
      if(type.endsWith('_SUCCESS')){
        setTitlesuccess('ทำรายการสำเร็จ');
        setSubTitlesuccess('');
        setDescriptionsuccess(''); 
        setsuccessModal(true)
      }else{
        setTitleFail('internal server error');
        setnameItemFail('');
        setDescriptionFail(''); 
        setFailModal(true)
      }     
    })
    
    //onSubmit()
  }
  const onTitle = (v) => {
    if(v){
        setEditData({ ...EditData, title_id: v })
    }
  }
  const onStatus = (v) => {
    if(v){
        setEditData({ ...EditData, status_id: v })
    }
  }


  return (
    <Div {...props}>
      <div className="content_header">
        <header>
          <div className="content-title">เพิ่มกิจกรรม</div>
          <div className="wrp-tool-right">
            <CloseOutlinedIcon size={30} onClick={onClose} className="icon" />
          </div>
        </header>
        <div className="css_header_detail">ปรับปรุงข้อมูลกิจกิจกรรม</div>
        <hr />
      </div>
      
      <div className="dialog-content">
        <div className="content">
            <div className="topic_fill">วันที่จัดกิจกรรม*</div>
            <div>
                <Date
                    className={'input-calendar'}
                    value={EditData.activity_date}
                    onChange={(v) => setEditData({ ...EditData, activity_date: v })}
                    error={Boolean(EditDataError.activity_date.show_errorMessage)}
                />
                {EditDataError.activity_date.show_errorMessage == true && (
                    <DivErrorMessage>{EditDataError.activity_date.errorMessage}</DivErrorMessage>
                )}
            </div>
        </div>
        <div className="content">
            <div className="topic_fill">ชื่อกิจกรรม*</div>
            <div>
                <TextField
                    className={'input-TextField'}
                    placeholder="ระบุ"
                    value={EditData.activity_name}
                    onChange={(e) => setEditData({ ...EditData, activity_name: e.target.value })}
                    error={Boolean(EditDataError.activity_name.show_errorMessage)}
                />
                {EditDataError.activity_name.show_errorMessage == true && (
                    <DivErrorMessage>{EditDataError.activity_name.errorMessage}</DivErrorMessage>
                )}
            </div>
            <div className="text_noti" >เฉพาะกิจกรรมที่เกี่ยวข้องทางด้านวิศวกรรม</div>
        </div>
        <div className="content">
            <div className="topic_fill">รายละเอียดการบรรยาย*</div>
            <div>
                <InputTextArea
                    className="InputTextArea_css"
                    initValue={EditData.activity_detail}
                    onChange={(e) => setEditData({ ...EditData, activity_detail: e.target.value })}
                    placeHolder="กรุณาระบุ รายละเอียดเกี่ยวกับกิจกรรม / การบรรยาย รายละเอียดของวิชา (Course Description) เนื้อหาที่บรรยายให้ความรู้กับผู้เข้าร่วมกิจกรรมตามประเภทหลักสูตรอบรม"
                    borderColor={'var(--Gray-300)'}
                    borderWidth="1px"
                    error={Boolean(EditDataError.activity_detail.show_errorMessage)}
                />
                {EditDataError.activity_detail.show_errorMessage == true && (
                    <DivErrorMessage>{EditDataError.activity_detail.errorMessage}</DivErrorMessage>
                )}
            </div>
        </div>
        <div className="content">
            <div className="topic_fill">จำนวนชั่วโมงอบรม*</div>
            <div>
                <TextField
                    className={'input-TextField'}
                    placeholder="ระบุ"
                    value={EditData.activity_hours}
                    onChange={(e) => setEditData({ ...EditData, activity_hours: e.target.value })}
                    onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                        }
                    }}
                    error={Boolean(EditDataError.activity_hours.show_errorMessage)}
                />
                {EditDataError.activity_hours.show_errorMessage == true && (
                    <DivErrorMessage>{EditDataError.activity_hours.errorMessage}</DivErrorMessage>
                )}
            </div>
        </div>
        <div className="content">
            <div className="topic_fill">จำนวนผู้เข้าร่วมกิจกรรม (คน)*</div>
            <div>
                <TextField
                    className={'input-TextField'}
                    placeholder="ระบุ"
                    value={EditData.activity_attendee_count}
                    onChange={(e) => setEditData({ ...EditData, activity_attendee_count: e.target.value })}
                    onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                        }
                    }}
                    error={Boolean(EditDataError.activity_attendee_count.show_errorMessage)}
                />
                {EditDataError.activity_attendee_count.show_errorMessage == true && (
                    <DivErrorMessage>{EditDataError.activity_attendee_count.errorMessage}</DivErrorMessage>
                )}
            </div>
        </div>

      </div>
      
      <div className="dialog-footer">
        <hr />
        <ButtonOutline className="button_margin" onClick={onClose}>
          {'ยกเลิก'}
        </ButtonOutline>
        <Button className="button_margin" onClick={ConfirmDialog}>
          {'สร้างกิจกรรม'}
        </Button>
      </div>

        <DialogConfirmV2
          open={Boolean(ModalConfirmDialog)}
          onClose={() => setModalConfirmDialog(false)}
          onNo={() => setModalConfirmDialog(false)}
          onSubmit={ProcessUpdate}
          icon={successIcon}
          title={ConfirmMessageModal.headline}
          nameItem={ConfirmMessageModal.message}
          textYes={'ยืนยัน'}
          textNo={'ยกเลิก'}
        />

      <DialogSuccess
        open={Boolean(successModal)}
        onClose={() => setsuccessModal(false)}
        onSubmit={() => onSubmit()}
        icon={successIcon}
        title={Titlesuccess}
        subtitle={SubTitlesuccess}
        description={Descriptionsuccess}
        textYes={'ตกลง'}
      />

        <DialogFail
          open={Boolean(failModal)}
          onClose={() => setFailModal(false)}
          onSubmit={() => setFailModal(false)}
          icon={failIcon}
          title={TitleFail}
          nameItem={nameItemFail}
          description={DescriptionFail}
          textYes={'ตกลง'}
        />
    </Div>
  )
}

export default NewActivity
