import { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { validateIDCARD } from '../utils/validation'
import { getOrganizations,RequestApproveOrg,ApiUserCanCleApproveRequest,OrgRegister } from '../redux/actions/organizations'
import { ApiGetOrgType } from '../redux/actions/master'


import Field from '../components/form/Field'
import TextField from '../components/form/TextField'
import Dropdown from '../components/form/Dropdown'
import Button from '../components/form/button/Button'
import PasswordField from '../components/form/PasswordInput'
import Modal from '../components/common/Modal'
import ButtonOutline from '../components/form/button/ButtonOutline'
import OtpInputComponent from '../components/form/OtpInputComponent'
import DialogSuccess from '../components/dialog/DialogSuccess'
import DialogFail from '../components/dialog/DialogFail'
import DialogConfirmV2 from '../components/dialog/DialogConfirmV2'
import ButtonSecondary from '../components/form/button/ButtonSecondary'
import AutoComplete from '../components/form/AutoComplete'

import logoIcon from '../assets/images/logo-icon.png'
import warningIcon from '../assets/images/warning-icon.png'
import { IoMdClose } from 'react-icons/io'
import successIcon from '../assets/images/success-icon.png'
import failIcon from '../assets/images/fail-icon.png'
import otpIcon from '../assets/images/opt-icon.png'
import { PiWarningCircleBold, PiFloppyDiskBold } from 'react-icons/pi'
import flagIcon from '../assets/images/flag-icon.png'

const Div = styled.div`
  width: 100%;
  /*max-width: 400px;*/
  margin: 0 auto;
  .header{
    height:80px;
    width: 80%;
    margin: 0px auto;
  }
  .content{
    width: 35%;
    margin: 0 auto;
  }
  .box_header{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
  }
  .css_alink{
    cursor: pointer;
    color: var(--Base-Font-Title-Color);
    font-weight: 600;
  }

  .img-container {
    /*padding-top: 30px;*/
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      /*width: 48px;*/
      height: 48px;
    }
  }

  .title {
    font-size: 30px;
    font-weight: 600;
    text-align: center;
    font-style: normal;
    color: var(--Gray-900);
  }

  .desc {
    text-align: center;
    margin-top: 12px;
  }

  .login-form {
    margin-top: 32px;

    .field {
      margin-top: 20px;
    }

    .link-wrapper {
      display: flex;
      justify-content: flex-end;
      margin-top: 24px;

      color: var(--Primary-700);
      font-size: 14px;
      font-style: normal;
      font-weight: 600;

      :hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }

    .button-login {
      display: block;
      width: 100%;
      margin-top: 24px;
    }
  }

  .hightlight {
    color: var(--Primary-700);
    font-weight: 800;
  }

  .copyright {
    color: var(--Gray-500);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    margin-top: 180px;
  }

  @media screen and (max-width: 440px) {
    max-width: 300px;
    .title {
      font-size: 20px;
    }

    .desc {
      font-size: 14px;
    }
  }
  .warning-container {
    margin-top: 24px;
    color: #475467;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    border-radius: 0.75rem;
    border: 1px solid #98A2B3;
    background: #FFFCF5;
    padding: 1rem;

    .icon-warning-container {
    }

    .warning-message-container {
      .headline-warning {
        font-weight: 600;
        .deny_message{
          color : #A81E23;
        }
      }
    }
  }
  .d-flex {
    display: flex;
  }
  .user_img_char_1 {
    border-radius: 50%;
    background-color: #FEF0C7;
    width: 48px;
    height: 48px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-right: 12px;
    color: #344054;
    cursor: pointer;
  }
  .user_img_char_red {
    border-radius: 50%;
    background-color: #FEF0C7;
    width: 48px;
    height: 48px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-right: 12px;
    color: #D92D20;
    cursor: pointer;
  }
`
const ModalRegister = styled.div`
  padding: 24px;
  min-width: 400px;
  .icon {
    cursor: pointer;
  }
  .top-table{
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    /*margin-bottom: 24px;*/
  }
  .dialog-content {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    .content {
      flex: 1;
      min-width: 270px;
    }

    .title {
      font-size: 18px;
      font-weight: 600;
      color: var(--Gray-900);
    }

    .description {
      font-size: 14px;
      margin-top: 4px;
      color: var(--Gray-600);
    }
  }
  .mg-top-space{
    margin-top: 5px;
  }
  .dialog-button {
    margin-top: 24px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;

    button {
      justify-content: center;
    }
  }
`
const FlexContainer = styled.div`
  display: flex;

  &.justify-content-center {
    justify-content: center;
  }
  &.justify-content-end {
    justify-content: flex-end;
  }
  &.justify-content-space-between {
    justify-content: space-between;
  }

  .w-48 {
    width: 48%;
  }
`

const DivErrorMessage = styled.div`
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
`

const OrganizationsRequest = () => {
  // external hook
  const { t } = useTranslation()
  const lang = localStorage.getItem('lang')
  const loglogin_id = localStorage.getItem('loglogin_id')
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [organizations_option, setorganizations_option] = useState([])

  const [is_request_approve, setis_request_approve] = useState(0)
  const [org_request, setorg_request] = useState(true)
  useEffect(() => {
      const request = {
          loglogin_id : loglogin_id
      }
      dispatch(getOrganizations(request)).then(({ type,data_info }) => {
          if(type.endsWith('_SUCCESS')){
              if(data_info.code == 200){
                setis_request_approve(0);
                const neworganizations_option = data_info.datalist.map((v) => ({
                    text: v?.name_th,
                    value: v?.id,
                }))
                setorganizations_option([...neworganizations_option])
                if(data_info.last_organizations_name_deny){
                  setorg_request(data_info.last_organizations_name_deny)
                  setis_request_approve(300);
                }
              }else if(data_info.code == 305){
                setorg_request(data_info.message)
                setis_request_approve(400);
              }

          }
      })
  }, [dispatch])


  const [organizations_id, setorganizations_id] = useState('')


  // success,fail modal
  const initMessageModal = {
    headline: '',
    message: '',
  }
  const [successModal, setSuccessModal] = useState(false)
  const [failModal, setFailModal] = useState(false)
  const [failModal2, setFailModal2] = useState(false)
  const [successMessageModal, setSuccessMessageModal] = useState(initMessageModal)
  const [failMessageModal, setFailMessageModal] = useState(initMessageModal)
  const [failMessageModal2, setFailMessageModal2] = useState(initMessageModal)
  const [ModalConfirmDialogDel, setModalConfirmDialogDel] = useState(false)
  const [ConfirmMessageModal, setConfirmMessageModal] = useState(initMessageModal)

  const [otpModalRegister, setotpModalRegister] = useState(false)
  const [RegisterData, setRegisterData] = useState({
    organizations_name: '',
    organizations_tin: '',
    org_type: '',
  })
  const [RegisterDataError, setRegisterDataError] = useState({
    organizations_name: {
      show_errorMessage: false,
      errorMessage: 'กรุณาระบุชื่อองค์กรแม่ข่าย',
    },
    organizations_tin: {
      show_errorMessage: false,
      errorMessage: 'กรุณาระบุเลขประจำตัวผู้เสียภาษี 13 หลัก',
    },
    org_type: {
      show_errorMessage: false,
      errorMessage: 'กรุณาระบุประเภทองค์กร',
    },
  })
  const [org_type_option, setorg_type_option] = useState([])

  useEffect(() => {
    dispatch(ApiGetOrgType()).then(({ type,data_info }) => {
        if(type.endsWith('_SUCCESS')){
            const neworg_type_option= data_info?.data?.map((v) => ({
                text: v?.name_th_th,
                value: v?.id,
            }))
            setorg_type_option([...neworg_type_option])
        }
    })
  }, [dispatch])

  const confirmSuccess = async () => {
    setSuccessModal(false)

    //navigate('/OrganizationsRequest')
    
    const request = {
        loglogin_id : loglogin_id
    }
    dispatch(getOrganizations(request)).then(({ type,data_info }) => {
        if(type.endsWith('_SUCCESS')){
            if(data_info.code == 200){
              setis_request_approve(0)
              const neworganizations_option = data_info.datalist.map((v) => ({
                  text: v?.name_th,
                  value: v?.id,
              }))
              setorganizations_option([...neworganizations_option])
            }else if(data_info.code == 305){
              setorg_request(data_info.message)
              setis_request_approve(400)
            }

        }
    })
  }
  const confirmFailModal = async () => {
    setFailModal(false)
  }
  const confirmFailModal2 = async () => {
    setTargetTime(null)
    setFailModal2(false)
  }

  // submit function
  const handleLogin = (e) => {
    e.preventDefault()
    const request = {
        organizations_id: organizations_id ,
        loglogin_id : loglogin_id
    }
    dispatch(RequestApproveOrg(request)).then(({ type,data_info }) => {
      
      if(type.endsWith('_SUCCESS')){
        if(data_info.code == 200){
            setSuccessMessageModal({
                headline: 'ส่งคำขอเข้าเป็นผู้ใช้ภายใต้สังกัดแม่ข่ายสำเร็จ',
                message: 'กรุณารอแจ้งผลการเข้าใช้งานผ่านทาง Email',
            })
            setSuccessModal(true)
        }else{
          
            setFailMessageModal({
                headline: data_info.message,
                message: '',
            })
            setFailModal(true)
          
        }
      }else{
        setFailMessageModal({
          headline: 'internal server error',
          message: '',
        })
        setFailModal(true)
      }
      
    })
  }


  // other function
  const gotoRegister = () => {
    //alert('gotoRegister');
    //navigate('/OrganizationsRegister')
    setotpModalRegister(true);
  }
  const closeOtpModalRegister = () => {
    setotpModalRegister(false)
  }
  const onSubmitRegister = () => {
    if (isValid().length == 0) {
      setotpModalRegister(false)
      const request = {
          "organizations_name":RegisterData.organizations_name,
          "organizations_tin": RegisterData.organizations_tin,
          "org_type": RegisterData.org_type,
          "loglogin_id" : loglogin_id
      }
      dispatch(OrgRegister(request)).then(({ type,data_info }) => {
      
        if(type.endsWith('_SUCCESS')){
          if(data_info.code == 200){
              //localStorage.setItem('request_org_id', data_info.request_org_id)
              navigate('/OrganizationsRegister/' + data_info.request_org_id)
          }else if(data_info.code == 310){
            
              setFailMessageModal({
                headline: 'มีองค์แม่ข่ายในระบบแล้ว',
                message: 'กรุณาส่งคำขอเป็นผู้ใช้ภายใต้แม่ข่าย',
                nameItem: data_info.full_name,
                nameItem2: data_info.phonse_no,   
              })
              setFailModal(true)
          }else if(data_info.code == 311){
              setFailMessageModal({
                headline: 'เลขประจำตัวผู้เสียภาษีไม่ตรงกับฐานข้อมูลกรมพัฒนาธุรกิจการค้า',
                message: 'กรุณาทำรายการใหม่',  
              })
              setFailModal(true)
          }else{
            setFailMessageModal({
                headline: data_info.message,
                message: '',
            })
            setFailModal(true)
          }
        }else{
          setFailMessageModal({
            headline: 'internal server error',
            message: '',
          })
          setFailModal(true)
        }
      
      })
    }
    
  }

  const isValid = () => {
    let data_valid = RegisterData
    let data_check_valid = RegisterDataError
    let errors = []
    Object.keys(RegisterDataError).forEach((k) => {
      let data = data_valid[k]
      if (data_check_valid[k].errorMessage != '') {
        if (!data || data < 0) {
          errors.push(data_check_valid[k].errorMessage)
          data_check_valid[k].show_errorMessage = true
          if (k == 'organizations_tin') {
            data_check_valid[k].errorMessage = 'กรุณาระบุเลขประจำตัวผู้เสียภาษี 13 หลัก'
          }
        } else {
          if (k == 'organizations_tin') {
            //--- valid organizations_tin
            if (!validateIDCARD(data)) {
              errors.push(data_check_valid[k].errorMessage)
              data_check_valid[k].errorMessage = 'กรุณาระบุเลขประจำตัวผู้เสียภาษีให้ครบ 13 หลัก'
              data_check_valid[k].show_errorMessage = true
            } else {
              data_check_valid[k].show_errorMessage = false
            }
          } else {
            data_check_valid[k].show_errorMessage = false
          }
        }
      }
    })
    setRegisterDataError({ ...RegisterDataError })
    return errors
  }
  const gotoLogin = () => {
    navigate('/login')
  }
  const formattedTime = (time) => {
    return `${String(time).padStart(2, '0')}`
  }

  const confirmCancleRequest = () => {
    setConfirmMessageModal({
      headline: 'ยืนยันการยกเลิกคำขอ',
      message: '',
    })
    setModalConfirmDialogDel(true);
  }

  const handleCancleRequest = (type_approve_id) => {
    setModalConfirmDialogDel(false)

    
    const request = {
      loglogin_id : loglogin_id
    }

    dispatch(ApiUserCanCleApproveRequest(request)).then(({ type,data_info }) => {
      
      if(type.endsWith('_SUCCESS')){
        if(data_info.code == 200){
          setSuccessMessageModal({
              headline: 'ทำรายการสำเร็จ',
              message: '',
          })
          setSuccessModal(true)
          

        }else{
          
            setFailMessageModal({
                headline: data_info.message,
                message: '',
            })
            setFailModal(true)
          
        }
      }else{
        setFailMessageModal({
          headline: 'internal server error',
          message: '',
        })
        setFailModal(true)
      }
      
    })
    
    
  }

  return (
    <Div>
      <div className="header">
        <div className="box_header">
          <div>
            <div className="img-container">
              <img src={logoIcon} alt="loco icon" />
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <form>
          <div className="title">ส่งคำขอเป็นผู้ใช้ภายใต้องค์กรแม่ข่าย</div>
          <div className="desc">
          ท่านยังไม่มีสังกัดแม่ข่าย กรุณาเลือกรายชื่อแม่ข่าย
          </div>
          {is_request_approve === 400 ? (
            <div className="login-form">
              <div className="warning-container">
                  <div className="d-flex">
                    <div className="icon-warning-container">
                      <div className="user_img_char_red" >
                      <PiWarningCircleBold size={25} />
                      </div>
                      
                    </div>
                    <div className="warning-message-container">
                      <div className="headline-warning">
                        อยู่ระหว่างรออนุมัติเข้าเป็นบัญชีผู้ใช้ขององค์กรแม่ข่าย
                      </div>
                      <div className="headline-warning">
                        {org_request}
                      </div>
                      <div>กรุณารอแจ้งผลการอนุมัติทาง email</div>
                      <div>เมื่อถูกปฏิเสธ ท่านจะไม่สามารถส่งคำขอเข้าเป็นผู้ใช้ของแม่ข่ายเดิมซ้ำได้อีก</div>
                    </div>
                  </div>
              </div>
              <div className="desc">
                ไม่ได้รับการตอบกลับ?
              </div>
              <ButtonOutline
                  className="button-login"
                  onClick={confirmCancleRequest}
                >
                  ยกเลิกการยื่นคำขอเข้าเป็นบัญชีองค์กรแม่ข่าย
              </ButtonOutline>
            </div>
            ) : (
              <div className="login-form">
                <Field className="field" label="ชื่อองค์กรแม่ข่ายที่ลงทะเบียนแล้ว*">
                    {
                      /*
                      <Dropdown
                        id={`dd_organizations_user`}
                        className="dd_type_of_activity"
                        value={organizations_id}
                        optionList={organizations_option}
                        onChange={(v) => setorganizations_id(v)}
                        placeHolder={'เลือกองค์กรแม่ข่าย'}
                      />
                      */
                    }

                    <AutoComplete
                      placeHolder={'เลือกองค์กรแม่ข่าย'}
                      optionList={organizations_option}
                      value={organizations_id}
                      onChange={(v) => setorganizations_id(v)}
                    />
                </Field>
    
                <Button
                  className="button-login"
                  type="submit"
                  onClick={handleLogin}
                  disabled={!organizations_id}
                >
                  ส่งคำขอ
                </Button>
                

                {is_request_approve === 300 ? (
                  <div className="login-form">
                    <div className="warning-container">
                        <div className="d-flex">
                          <div className="icon-warning-container">
                            <div className="user_img_char_red" >
                            <PiWarningCircleBold size={25} />
                            </div>
                            
                          </div>
                          <div className="warning-message-container">
                            <div className="headline-warning">
                              คำขอเข้าเป็นผู้ใช้ภายใต้สังกัดแม่ข่าย {org_request} <span className="deny_message"> ไม่ได้รับการอนุมัติ </span>
                            </div>
                            <div>ท่านไม่สามารถส่งคำขอเข้าเป็นผู้ใช้ของแม่ข่ายนี้ซ้ำได้อีก</div>
                          </div>
                        </div>
                    </div>
                  </div>
                ) : (
                  <div className="warning-container">
                    <div className="d-flex">
                      <div className="icon-warning-container">
                        <div className="user_img_char_1" >
                        <PiWarningCircleBold size={25} />
                        </div>
                        
                      </div>
                      <div className="warning-message-container">
                        <div className="headline-warning">
                        ส่งคำขอได้ 1 ครั้ง
                        </div>
                        <div>เมื่อส่งคำขอแล้ว รอการอนุมัติการเข้าเป็นผู้ใช้ของแม่ข่าย </div>
                        <div>เข้าเป็นผู้ใช้ของแม่ข่ายซ้ำได้อีก</div>
                      </div>
                    </div>
                  </div>
                )
                }
                <div className="desc">
                กรณีขอสมัครเป็นองค์กรแม่ข่าย
                </div>
                <ButtonOutline
                  className="button-login"
                  onClick={gotoRegister}
                >
                  ขอสมัครเป็นองค์กรแม่ข่าย
                </ButtonOutline>
              </div>
            )
          }

        </form>
      </div>

      {/* Dialog */}
      <DialogSuccess
        open={Boolean(successModal)}
        onClose={() => setSuccessModal(false)}
        onSubmit={confirmSuccess}
        icon={successIcon}
        title={successMessageModal.headline}
        description={successMessageModal.message}
        textYes={'ตกลง'}
      />
      <DialogFail
        open={Boolean(failModal)}
        onClose={() => setFailModal(false)}
        onSubmit={confirmFailModal}
        icon={failIcon}
        title={failMessageModal.headline}
        description={failMessageModal.message}
        html={
          <div>
            <div>{failMessageModal.nameItem}</div>
            <div>{failMessageModal.nameItem2}</div>
          </div>
        }
        textYes={'ตกลง'}
      />

      <DialogConfirmV2
        open={Boolean(ModalConfirmDialogDel)}
        onClose={() => setModalConfirmDialogDel(false)}
        onNo={() => setModalConfirmDialogDel(false)}
        onSubmit={() => {
          setModalConfirmDialogDel(false)
          handleCancleRequest(0)
        }}
        icon={warningIcon}
        title={ConfirmMessageModal.headline}
        nameItem={ConfirmMessageModal.message}
        textYes='ยืนยัน'
        textNo='ยกเลิก'
      />

      {/* Modal */}
      <Modal open={otpModalRegister} disableBackdropClick>
        <ModalRegister>
          <div className="top-table">
            <div className="icon-content">
              <img src={flagIcon} />
            </div>
            <div className="close-modal-content">
              <IoMdClose size={20} className="icon" onClick={closeOtpModalRegister} />
            </div>
          </div>
          <div className="dialog-content">
            <div className="content">
              <div className="title">ขอสมัครองค์กรแม่ข่าย</div>
              <div className="description">กรุณาเลือกประเภทแม่ข่าย และระบุข้อมูลให้ครบถ้วน</div>
              <div className="mg-top-space">
                <Field className="field" label="ชื่อองค์กรแม่ข่าย*">
                  <TextField
                    placeholder="ระบุชื่อองค์แม่ข่าย"
                    value={RegisterData.organizations_name}
                    onChange={(e) => setRegisterData({ ...RegisterData, organizations_name: e.target.value })}
                    error={Boolean(RegisterDataError.organizations_name.show_errorMessage)}
                  />
                  {RegisterDataError.organizations_name.show_errorMessage == true && (
                    <DivErrorMessage>{RegisterDataError.organizations_name.errorMessage}</DivErrorMessage>
                  )}
                </Field>
              </div>
              <div className="mg-top-space">
                <Field className="field" label="ประเภทองค์กร*">
                  <Dropdown
                      id={`dd_org_type`}
                      className="dd_type_of_activity"
                      value={RegisterData.org_type}
                      optionList={org_type_option}
                      onChange={(v) => setRegisterData({ ...RegisterData, org_type: v })}
                      placeHolder={'เลือกประเภทองค์กร'}
                      error={Boolean(RegisterDataError.org_type.show_errorMessage)}
                  />
                  {RegisterDataError.org_type.show_errorMessage == true && (
                      <DivErrorMessage>{RegisterDataError.org_type.errorMessage}</DivErrorMessage>
                  )}
                </Field>
              </div>
              <div className="mg-top-space">
                <Field className="field" label="เลขประจำตัวผู้เสียภาษี 13 หลัก*">
                  <TextField
                    placeholder="ระบุเลขประจำตัวผู้เสียภาษี 13 หลัก"
                    value={RegisterData.organizations_tin}
                    onChange={(e) => setRegisterData({ ...RegisterData, organizations_tin: e.target.value })}
                    error={Boolean(RegisterDataError.organizations_tin.show_errorMessage)}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                      }
                    }}
                  />
                  {RegisterDataError.organizations_tin.show_errorMessage == true && (
                    <DivErrorMessage>{RegisterDataError.organizations_tin.errorMessage}</DivErrorMessage>
                  )}
                </Field>
              </div>
            </div>
          </div>
          <div className="dialog-button">
            <ButtonSecondary onClick={closeOtpModalRegister}>ยกเลิก</ButtonSecondary>
            <Button onClick={onSubmitRegister}>ยืนยัน</Button>
          </div>
        </ModalRegister>
      </Modal>
    </Div>
  )
}

export default OrganizationsRequest
